import { render, staticRenderFns } from "./email-summary.vue?vue&type=template&id=aa5a9352&scoped=true"
import script from "./email-summary.vue?vue&type=script&lang=js"
export * from "./email-summary.vue?vue&type=script&lang=js"
import style0 from "./email-summary.vue?vue&type=style&index=0&id=aa5a9352&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa5a9352",
  null
  
)

export default component.exports