<template>
  <div style="padding-left: 15px" id="cards" class="tab-pane col-12 active" role="tabpanel">
    <div class="row no-gutter">
      <div class="col-12 col-md-3">
        <div class="card">
          <div class="card-header bg-navyblue">Files - Standard</div>
          <div class="card-block bg-white">
            <div class="form-group row no-gutter">
              <label for="card_prefix"
                     class="col-lg-5 col-form-label">File
                Prefix</label>
              <div class="col-lg-7">
                <input v-model="value.cards.card_prefix"
                       type="text"
                       :readonly="!isStatusActive"
                       class="form-control" id="card_prefix"
                       placeholder="File Prefix">
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label for="card_number"
                     class="col-lg-5 col-form-label">File Number Start</label>
              <div class="col-lg-7">
                <input v-model="value.cards.card_number"
                       type="text"
                       :readonly="!isStatusActive"
                       class="form-control" id="card_number"
                       placeholder="File Number Start">
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label for="card_number_length"
                     class="col-lg-5 col-form-label">File Number
                Length</label>
              <div class="col-lg-7">
                <multiselect
                    v-model="value.cards.card_number_length"
                    :options="['1','2','3','4','5','6','7','8','9','10']"
                    :showLabels="false"
                    :option-height="29"
                    :disabled="!isStatusActive"
                    style="opacity: 1"
                    :max-height="203"
                    :close-on-select="true"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9">
        <div class="card">
          <div class="card-header bg-navyblue">Files - Status</div>

          <div class="card-block bg-white p-0">
            <settings-triggers ref="triggers" :triggers="value.triggers"></settings-triggers>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import NumberFormatter from '../utility/number-formatter'
import {mapGetters} from "vuex";
import SettingsTriggers from '../settings/Triggers'
export default {
  name: "TheCards",
  props: {
    value: {}
  },
  components: {
    Multiselect,
    NumberFormatter,
    SettingsTriggers,
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    })
  },
  methods: {
    saveData() {
      if (this.$refs['triggers']) {
        this.$refs['triggers'].saveData()
      }
    },
  }
}
</script>

<style scoped>

</style>
