
export const Getters = {
    lastTimestamp: state => {
      if (!state.lastTimestamp) {
        return 0;
      }
      return (state.lastTimestamp - 1);
    },
    isNeedUpdatePromos: state => {
        return !(!state.isNeedUpdatePromos || state.isNeedUpdatePromos === null);
    },
    isNeedUpdateActivity: state => {
      return !(!state.isNeedUpdateActivity || state.isNeedUpdateActivity === null);
    },
    getTimestampForUpdateActivity: state => {
      return state.isNeedUpdateActivity;
    },
    isNeedUpdateFiles: state => {
      return !(!state.isNeedUpdateFiles || state.isNeedUpdateFiles === null);
    },
    isNeedUpdateImages: state => {
      return !(!state.isNeedUpdateImages || state.isNeedUpdateImages === null);
    },
}
