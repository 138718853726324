<template>
    <vue-scroll>
        <div class="vue-form-wizard xs">
            <div class="wizard-header">
                <h4 class="text-left mb-0">{{ title }}</h4>
            </div>
            <div class="wizard-navigation wizard-navigation-activity mt-0">
                <div class="wizard-progress-with-circle">
                    <div
                        class="wizard-progress-bar"
                        style="
                            background-color: rgb(94, 121, 255);
                            color: rgb(20, 39, 66);
                        "
                        :style="progress"
                    ></div>
                </div>
                <ul
                    role="tablist"
                    class="
                        wizard-nav wizard-nav-pills wizard-nav-pills-activity
                    "
                >
                    <li
                        :class="{ active: cardStatus.active }"
                        v-for="(cardStatus, index) in statuses"
                    >
                        <a
                            @click="onChangeCardProgress(cardStatus.id)"
                            v-if="currentStatus === cardStatus.id"
                        >
                            <div
                                role="tab"
                                tabindex="0"
                                aria-controls="File Open"
                                class="wizard-icon-circle md checked"
                                aria-disabled="true"
                                aria-selected="true"
                                style="border-color: rgb(20, 39, 66)"
                            >
                                <div class="wizard-icon-container">
                                    <i class="wizard-icon ti-check"></i>
                                </div>
                            </div>
                            <span
                                class="stepTitle active"
                                style="color: rgb(20, 39, 66)"
                                >{{ cardStatus.title }}</span
                            >
                        </a>
                        <a @click="onChangeCardProgress(cardStatus.id)" v-else>
                            <div
                                role="tab"
                                tabindex=""
                                class="wizard-icon-circle md"
                            ></div>
                            <span class="stepTitle">{{
                                cardStatus.title
                            }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="wizard-card-footer clearfix pb-1 pr-1">
                <span></span>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'File Progress'
        },
        statuses: {
            type: Array,
            default: () => []
        },
        currentStatus: {
            type: String | Object
        },
        progress: {
            type: String,
            default: 'width: 0'
        }
    },
    methods: {
        onChangeCardProgress(statusId) {
            this.$emit('change', statusId)
        }
    }
}
</script>

<style scoped>
.vue-form-wizard.xs {
    padding-bottom: 0px !important;
    overflow: auto;
}

.vue-form-wizard.rs-scroll {
    max-height: auto;
    height: 176px;
    overflow: auto;
}

.wizard-nav {
    overflow: hidden;
}

.vue-form-wizard .wizard-nav-pills a,
.vue-form-wizard .wizard-nav-pills li {
    max-width: 89px;
}

.vue-form-wizard.xs .wizard-icon-circle {
    width: 30px !important;
    height: 30px !important;
    background: transparent !important;
    border: none !important;
}

#activity .wizard-progress-with-circle {
    background-color: rgba(27, 30, 56, 0.12);
    border-radius: 5px;
    /* to keep border radius */
    overflow: hidden;
}

#activity .wizard-icon-circle {
    border: 2px solid #dcdcdc;
}

#activity .active .wizard-icon-circle {
    background: #142742;
}

#activity .wizard-icon-circle ~ .stepTitle {
    color: #b9b9b9;
}

#activity .vue-form-wizard .wizard-tab-content {
    min-height: 1px;
    padding-bottom: 0;
    border-top: 1px solid transparent;
    margin-top: 0px;
    padding: 5px;
}

.wizard-icon.ti-check {
    font-weight: bold;
    color: rgb(94, 121, 255);
}

.vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
    height: 10px !important;
    top: 19px !important;
}

.vue-form-wizard.xs .wizard-nav-pills > li.active > a .wizard-icon {
    font-size: 12px !important;
    color: rgb(94, 121, 255);
}

.wizard-navigation {
    overflow-x: auto;
}

#activity .wizard-navigation {
    float: left;
    margin-top: 18px;
    margin-left: 30px;
}

.vue-form-wizard .wizard-icon-circle .wizard-icon-container {
    border-radius: 50%;
    background-color: white;
    border: 1px solid rgb(94, 121, 255);
    flex: unset;
    width: 24px;
    height: 24px;
}
#activity .vue-form-wizard .wizard-header {
    padding: 15px 30px;
    padding-bottom: 0px;
}

#activity .wizard-icon-circle ~ .stepTitle {
    font-size: 12px;
    font-weight: 600;
    padding: 5px 38px;
}

.wizard-bottom .wizard-progress-bar {
    background-color: #5e79ff !important;
    color: #5e79ff !important;
    border-radius: 10px 0 0 10px;
    left: 40px;
    display: block;
    position: relative;
}

.wizard-bottom .wizard-progress-bar-not-active {
    width: 100%;
    background-color: rgba(27, 30, 56, 0.12);
    height: 10px;
    position: absolute;
    left: 50px;
    top: 9px;
}

.wizard-bottom .wizard-navigation {
    overflow: hidden !important;
    min-width: 320px !important;
    max-width: 481px !important;
}

.vue-form-wizard .wizard-nav-pills {
    flex-wrap: nowrap;
}
</style>

<style>
/**
 * vue-scroll styling
 * used for activity tab in cards and estimates
 */

/* remove scroll background */
.__rail-is-vertical,
.__rail-is-horizontal {
    opacity: 0;
}
</style>
