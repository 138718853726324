<template>
  <div class="mb-0-75 border pt-0-5 pb-0-5 dashboard-row-element clickable" @click="goToTask(task)">
    <div class="row">
      <div class="col-8 col-sm-10 pl-0">
        <strong :class="{ 'text-muted': task.isViewed || isCompleted }">
          {{ task.name }}
        </strong>
      </div>
      <div class="col-4 col-sm-2 pl-0">
        <div class="d-flex align-items-center justify-content-end wrap-text">
          <span v-if="task.isViewed || isCompleted" class="pull-right d-flex align-items-center justify-content-end ml-1">
            <i class='bx bx-task font-20 text-muted'></i>
          </span>
          <span v-else class="pull-right d-flex align-items-center justify-content-end ml-1">
            <i class='bx bx-task font-20 theme-color'></i>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <strong :class="{ 'text-muted': task.isViewed || isCompleted }">
        <template v-if="task.cardNumber">{{ task.cardNumber }}</template>
        <template v-if="task.createdById == userInfo.user_id"> - <span>You</span></template>
        <template v-else-if="task.createdById > 0"> - {{ task.createdBy }}</template>
      </strong>
    </div>
    <div class="row" :class="{ 'text-muted': task.isViewed || isCompleted }">
      <div class="col overflow-hidden">
        {{ task.notesText }}
      </div>
    </div>
  </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
      name: "task-summary",
      components: {},
      props: {
        task: {
          type: [Object, null],
          default() {
            return {
              "id": 0,
              "name": "",
              "dueDate": null,
              "dueTime": null,
              "notes": "",
              "notesText": "",
              "status": "none",
              "isViewed": false,
              "priority": "none",
              "createdAt": "",
              "createdBy": "",
              "createdById": 0,
              "assignedTo": 0,
              "cardNumber": null,
              "estimateNumber": null,
              "cardId": 0,
              "estimateId": 0,
            };
          }
        },
      },
      computed: {
        ...mapGetters({
          userInfo: "userInfo",
        }),
        isCompleted() {
          return this.task.status == 'completed';
        },
      },
      methods: {
        goToTask(task) {
          console.log('task', task);
          this.$router.push(
            {name: 'CardsEdit', params: {action: 'goToTask', card_id: task.cardId, taskId: task.id}}
          );
        }
      }
    };
</script>

<style scoped>
    .task-priority,
    .bx {
      text-align: left;
    }

    .task-priority {
      width: 50px;
    }

    .bx {
      width: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1327px) {
      .task-priority,
      .bx {
        text-align: right;
      }
    }

    .font-20 {
      font-size: 20px;
    }

    .theme-color {
      color: #979595;
    }

    .dashboard-row-element {
      background-color: white;
      padding: 10px 15px !important;
    }

    .wrap-text {
      flex-wrap: wrap;
    }
</style>
