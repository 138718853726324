<template>
  <div class="planner-page" v-if="!isMobileOnly()">
    <div @click="hideDropdown" class="page-header d-flex">
      <div>
        <h4 v-if="isCalendarDisplaying">Bookings Calendar</h4>
        <h4 v-else-if="isPlannerDisplaying">Bookings Planner</h4>
        <h4 v-else-if="isBookingsSnapshot">Bookings Tracker</h4>
        <ol class="breadcrumbs">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">View Boards</li>
          <li v-if="isCalendarDisplaying" class="breadcrumb-item active">Bookings Calendar</li>
          <li v-else-if="isPlannerDisplaying" class="breadcrumb-item active">Bookings Planner</li>
          <li v-else-if="isBookingsSnapshot" class="breadcrumb-item active">Bookings Tracker</li>
        </ol>
      </div>
      <template>
        <div class="btn-group cards-view-button cards-view-button-center" role="group">
          <button type="button" @click="showSnapshot(0)" :class="{'btn-primary': isCalendarDisplaying}" class="btn">Calendar</button>
          <button type="button" @click="showSnapshot(1)" :class="{'btn-primary': isPlannerDisplaying}" class="btn">Planner</button>
          <button type="button" @click="showSnapshot(2)" :class="{'btn-primary': isBookingsSnapshot}" class="btn">Tracker</button>
        </div>
      </template>
      <div class="tabs-nav-right-search">
        <search-filter
            type="estimates"
            v-model="searchFilter"
            :isIgnoreSiteAndPageHeader="true"
            class="search-filter"
            :filters="[]"
            :filterValue="filter"
            :limit-length="50"
            :isShowSearIcon="true"
            @onEnter="onEnter"
            @selectAdditional="redirectToEstimatesEdit"
            :debug="false"
            height="41px"
            aria-expanded="false"
            data-toggle="dropdown"
            :isMyltipleOptions="true"
            :additionalMenu="additionalMenu"
            @change="onSearchFilterChange"
        />
      </div>
    </div>
    <booking-snapshot
        v-show="isBookingsSnapshot"
    ></booking-snapshot>
    <planner
        v-show="isPlannerDisplaying"
        class="planner-tab"
        @changeDate="checkDate"
      ></planner>
    <booking-calendar
        ref="bookingCalendar"
        v-show="isCalendarDisplaying"
        @checkDate="checkDate"
        @loadEvents="loadEvents"
        :isShow="isCalendarDisplaying"
    >
    </booking-calendar>
  </div>
</template>

<script>
import BookingCalendar from './planner/booking-calendar'
import BookingSnapshot from "./booking-snapshot/booking-snapshot";
import Planner from "@/components/boards/planner/planner.vue";
import dayjs from "dayjs";
import _ from "lodash";
import {mapGetters} from "vuex";
import {isMobileOnly, isMobile} from "@/deviceDetect/index";
import SearchFilter from "@/components/search-filter/search-filter-2.vue";

export default {
  name: "board",
  components: {
    SearchFilter,
    BookingCalendar,
    BookingSnapshot,
    Planner,
  },
  data() {
    return {
      isCalendarDisplaying: false,
      isPlannerDisplaying: false,
      isBookingsSnapshot: false,
      searchFilter: '',
      filter: '',
      isMobile,
    }
  },
  mounted() {
    this.loadEvents(_.isEmpty(this.getBookingItems));
    if (localStorage.getItem('bookingBoardType') == 'snapshot') {
      this.isBookingsSnapshot = true
    } else if (localStorage.getItem('bookingBoardType') == 'planner') {
      this.isPlannerDisplaying = true;
    } else {
      this.$set(this, 'isCalendarDisplaying', true)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (document.body.classList.contains('compact-sidebar') && !this.isMobile) {
      document.body.classList.remove('compact-sidebar');
    }
    next();
  },
  computed: {
    ...mapGetters({
      getBookingItems: 'board/getBookingItems',
      getterPlannerBoardType: 'getPlannerBoardType',
      isLoadedPrev: 'board/isLoadedPrev',
      getterAllEstimates: 'estimate/getAllEstimates',
    }),
    computedOrderedEstimatesAll() {
      return _.orderBy(this.getterAllEstimates, (itm) => {
        let label = itm.estimate_number
        if (itm.rego_number) {
          label +=  ' - '
        }

        if (itm.rego_number) {
          label += itm.rego_number + ' '
        }
        return label
      }, ['asc'])
    },
    additionalMenu() {
      if (_.isEmpty(this.searchFilter)) {
        return []
      }

      let result =  []
      try {
        _.forEach(this.computedOrderedEstimatesAll, (itm) => {
          if (result.length == 10) {
            throw new Error();
          }
          let b = _.startsWith(_.trim(_.toLower(itm.estimate_number)), _.trim(_.toLower(this.searchFilter))) ||
              _.startsWith(_.trim(_.toLower(itm.rego_number)), _.trim(_.toLower(this.searchFilter))) ||
              _.includes(_.trim(_.toLower(itm.customer_name)), _.trim(_.toLower(this.searchFilter)))

          if (b) {
            let label = itm.estimate_number
            if (itm.rego_number) {
              label +=  ' - '
            }
            if (itm.rego_number) {
              label += itm.rego_number + ' '
            }
            if(itm.dom) {
              if(itm.dom.length == 6){
                let stg = ''
                stg = itm.dom.at(-2) + itm.dom[itm.dom.length - 1] + '/' + itm.dom.slice(2, 4)
                itm.dom = stg
              }
              if(itm.dom.length == 7 && itm.dom[2] == '/'){
                let stg = ''
                stg = itm.dom.slice(0, 3) + itm.dom.slice(5, 7)
                itm.dom = stg
              }
            }

            result.push({
              value: itm.estimate_id,
              label: label,
              estimateNumber: itm.estimate_number,
              regoNumber: itm.rego_number,
              customerName: itm.customer_name,
              insurerName: itm.insurerName,
              make: itm.make,
              model: itm.model,
              buildDate: itm.dom,
            })
          }
        })
      } catch (error) {
      }

      return result
    },
  },
  methods: {
    onSearchFilterChange(filters) {
      if (filters && filters.search) {
        this.filter = filters.search;
      } else {
        this.filter = '';
      }
    },
    redirectToEstimatesEdit(itm) {
      this.$router.push('/estimates/view/' + itm.value)
    },
    onEnter() {
      if (!_.isEmpty(this.additionalMenu) && !_.isEmpty(this.searchFilter)) {
        if (this.additionalMenu.length == 1) {
          this.redirectToEstimatesEdit(this.additionalMenu[0])
        } else {
          this.$store.commit('setEstimateFilter', this.searchFilter);
          this.$router.push('/estimates')
        }
      }
    },
    hideDropdown() {
      if (this.$refs.bookingCalendar) {
        this.$refs.bookingCalendar.hideDropdown()
      }
    },
    isMobileOnly() {
      return isMobileOnly
    },
    loadEvents(isShowNProgress = false) {

      if (isShowNProgress) {
        NProgress.start()
      }

      if (_.isEmpty(this.getBookingItems)) {
        this.loadEventsFunction(isShowNProgress, false)
      }

      setTimeout(() => {
        if (isShowNProgress) {
          NProgress.done();
        }
      }, 2000)
    },
    loadEventsFunction(isShowNProgress, isPrev = false) {
      this.$store.dispatch('board/loadBookingBoard', {isPrev: isPrev}).then((r) => {
        if (isShowNProgress) {
          if (this.$refs.bookingCalendar) {
            this.$refs.bookingCalendar.setRouterData()
          }
        }
      })
    },
    checkDate(v) {
      let d = null

      if (this.getterPlannerBoardType == 'day') {
        d = dayjs(new Date(v)).isoWeekday(1).format('YYYY-MM-DD')
      } else if (this.getterPlannerBoardType == 'week') {
        d = dayjs(new Date(v)).isoWeekday(1).format('YYYY-MM-DD')
      } else if (this.getterPlannerBoardType == 'month') {
        d = dayjs(new Date(v)).startOf('month').format('YYYY-MM-DD')
      }

      if (d) {
        let diff = dayjs(new Date()).diff(d, 'day');
        if (diff >= 32 && !this.isLoadedPrev) {
          this.loadEventsFunction(false, true)
        }
      }
    },
    showSnapshot(v) {
      if (v == 1) {
        this.$set(this, 'isCalendarDisplaying', false)
        this.isBookingsSnapshot = false
        this.isPlannerDisplaying = true
        localStorage.setItem('bookingBoardType', 'planner')
      } else if (v == 2) {
        this.$set(this, 'isCalendarDisplaying', false)
        this.isBookingsSnapshot = true
        this.isPlannerDisplaying = false
        localStorage.setItem('bookingBoardType', 'snapshot')
        this.$set(this, 'isCalendarDisplaying', false)
      } else {
        this.$set(this, 'isCalendarDisplaying', true)
        this.isBookingsSnapshot = false
        this.isPlannerDisplaying = false
        localStorage.setItem('bookingBoardType', 'board')
        this.$nextTick(() => {
          this.$set(this, 'isCalendarDisplaying', true)
          if (this.$refs.bookingCalendar) {
            this.$refs.bookingCalendar.setType(this.getterPlannerBoardType)
          }
        })
      }
    },
  }
}
</script>

<style>
.planner-page .tabs-nav-right-search .search-filter .custom-dropdown {
  width: auto !important;
  min-width: 128px !important;
}
.planner-page .tabs-nav-right-search .search-filter .custom-dropdown.additional-menu-search-filter{
  width: 350px !important;
  left: -72px;
}
.v-event, .v-event-timed {
  min-height: 25px;
}

.event-name-template {
  display: flex;
  align-items: center;
}

.V3 .planner-page .red-blinking-time input {
  animation: red-blinking 1.5s linear infinite !important;
}

@keyframes red-blinking {
  0% {
    border-color: red;
  }
  50% {
    border-color: rgba(28, 31, 57, 0.25);
  }
  100% {
    border-color: red;
  }
}

.V3 .planner-page .available-day-blinking {
  background-color: #00b050 !important;
  border-color: #00b050 !important;
  animation: blinking-green 3s linear infinite;
  /*animation-iteration-count: infinite;*/
  /*animation-delay: 250ms;*/
}

@keyframes blinking-green {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.planner-page .calendar-area .v-calendar .v-event-timed-container {
  margin-right: 0px !important;
}
.planner-page .v-calendar-daily_head-day .v-event {
  margin-bottom: 3px;
}
.planner-page .overbooked-modal .modal-content {
  height: 250px !important;
}
.planner-page .v-calendar-daily_head-weekday {
  order: 3;
  z-index: 7;
}

.planner-page .v-calendar-daily_head-day-label {
  order: 2;
  z-index: 7;
}

.planner-page .white-header .v-calendar-daily_head-weekday,
.planner-page .white-header .v-calendar-daily_head-day-label .v-btn__content {
  color: white !important;
}

.planner-page .subtitle-day-week {
  height: 100%;
  order: 1;
  margin: 8px 15px;
  text-align: center;
}

.planner-page .v-event {
  min-height: 20px;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day {
  display: flex;
  /*justify-content: space-between;*/
  flex-direction: column;
}


.planner-page .v-calendar-daily .v-calendar-daily_head-day > div {
  order: 99999;
  display: none;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day .v-calendar-daily_head-weekday {
  order: 1 !important;
  display: block !important;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day .v-calendar-daily_head-day-label {
  order: 2 !important;
  display: block !important;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day .subtitle-day-week {
  order: 3 !important;
  display: block !important;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day .v-event {
  order: 4;
  display: block !important;
}

.disabled-planner-cell {
  background-color: #e4e4e4 !important;
}

.available-planner-cell {
  background-color: #00b050 !important;
}
.collapse-tooltip .tooltip-inner {
  border-radius: 0px 10px 10px 10px !important;
}

.collapse-tooltip {
  margin-left: 0px !important;
}

.V3 .booking-status-select .multiselect__input {
  min-width: auto !important;
}

.Vue-Toastification__container {
  z-index: 999999999;
}

.planner-page .all-day-event {
  text-align: center;
}

.planner-page .choose-button {
  background-color: #5E79FF;
  color: white;
  font-size: 13px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  padding: 0.75rem 1rem;
  margin-top: 10px;
  margin-left: 160px;
  width: 80px;
  position: absolute;
  top: 265px;
}

.planner-page .category-input {
  cursor: pointer;
}

.planner-page .category-color-picker {
  position: absolute;
  right: 99px;
  top: -30px;
}

.planner-page .adv-search-block .text-grey {
  right: 1rem !important;
}

.planner-page .header {
  z-index: 99;
  display: flex;
  padding-top: 20px !important;
  width: 99% !important;
  overflow: inherit;
  justify-content: flex-end;
}

.planner-page .planner-icon {
  font-size: 35px;
  color: black;
}

.planner-page .planner-icon:hover {
  cursor: pointer;
}

.planner-page .v-toolbar__content {
  justify-content: space-between;
}

@media (min-width: 992px) {
  .planner-page .modal-lg {
    max-width: 500px !important;
  }
}

.planner-page .planner-modal-btn {
  justify-content: end;
  padding-top: 20px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.modal-wizard .modal-footer {
  background-color: #fff;
}

.planner-page .calendar-area .v-application--wrap {
  height: 75vh;
  padding-left: 15px;
  padding-right: 15px;
  min-height: auto;
}

.planner-page .category-color-picker {
  display: block;
}

.planner-page .category-color-picker .v-application {
  /*height: 256px;*/
  max-height: 330px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgb(27 30 56 / 15%);
  border: 1px solid rgba(0, 0, 0, .15);
}

.planner-page .nav-tabs.nav-tabs-2 {
  position: absolute;
  top: 45px;
}

.planner-page .modal {
  right: -350px;
  z-index: 999999;
}

.planner-page .modal,
.planner-page .modal-wizard {
  top: 0 !important;
  left: 0 !important;
}

.planner-page .modal-dialog {
  right: 0;
  position: absolute;
  /*width: 330px;*/
  width: 400px;
  margin: 0;
}

@media screen and (max-height: 1160px) {
  .planner-page .modal-wizard .modal-footer {
    display: none;
  }

  .planner-page .planner-modal-btn {
    border-top: 1px solid rgba(27, 30, 56, 0.25);
    padding-bottom: 31px !important;
  }

  .planner-page .modal-content {
    height: 100%;
  }
}

@media screen and (min-height: 920px) and (max-height: 1159px) {
  .planner-page .modal-dialog {
    height: 100% !important;
  }
}

@media screen and (min-height: 1160px) {
  .planner-page .modal-dialog {
    height: 900px;
    bottom: 0;
    top: 286px;
  }

  .planner-page .modal-content {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .planner-page .planner-modal-btn {
    padding-top: 70px;
  }
}

.planner-page .fade.in {
  transform: translateX(-350px);
}

.planner-page .modal.fade .modal-dialog {
  right: 0;
}

.planner-page .modal-backdrop.in {
  opacity: 0 !important;
}

.planner-page .modal .form-group {
  margin: 0 !important;
  align-items: center;
  padding-bottom: 10px;
}

.planner-page .modal .multiselect__content-wrapper {
  height: 72px;
}

.planner-page .modal .reminder-multiselect .multiselect__content-wrapper {
  height: 150px;
}

.planner-page .modal .multiselect__tags {
  min-height: 32px !important;
}

.multiselect__option:after {
  line-height: 29px;
  padding-right: 5px;
  padding-left: 0px;
}

.V3 .planner-page .tabs-component {
  position: relative;
  top: 0;
}

.V3 .planner-page .tab-content-table {
  border-top: none;
}

.V3 .planner-page .form-control {
  padding: 0.5rem 0.75rem;
}

.text-category-menu {
  display: block;
  padding: 5px 10px 5px 15px !important;
  background-color: #80808038;
  font-size: 10px;
  font-weight: 700;
}

.V3 .planner-page .v-application {
  margin-top: 3px !important;
}

.categories-menu-color-icon {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  margin-right: 10px !important;
}

.V3 .planner-page .dropdown-menu-child {
  border-radius: 3px;
  right: -50px;
  min-width: 100px;
  padding-top: 0 !important;
}

.V3 .planner-page .dropdown-menu-child li a {
  padding: 10px 18px;
}

.V3 .planner-page .v-current-time {
  height: 2px;
  background-color: #7267E7;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

.V3 .planner-page .v-current-time .first::before {
  content: '';
  position: absolute;
  background-color: #7267E7;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}

.V3 .planner-page .v-calendar-weekly__day-label {
  cursor: default;
}

.V3 .planner-page .multiselect__tags {
  /*width: 176px !important;*/
}

.V3 .planner-page .multiselect-add-people .multiselect__content-wrapper {
  height: 130px;
}

.V3 .planner-page .multiselect-add-people.multiselect--disabled .multiselect__select{
  display: none !important;
}

.V3 .planner-page .multiselect-add-people.multiselect--disabled {
  opacity: 1 !important;
}

.planner-header-menu-btn-block {
  display: flex;
}


.planer-date-period-text {
  font-size: 25px;
  font-weight: 700;
}

@media screen and (max-width: 1600px) {
  .planner-header-menu-btn-block {
    justify-content: flex-end;
  }

  .planer-period-menu,
  .planer-options-menu {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
@media screen and (max-width: 1300px) {
  .planer-categories-menu{
    display: none;
  }
}
@media screen and (max-width: 1150px) {
  .weekly-target{
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 1185px) {
  .planer-period-menu{
    width: 100px;
    padding: 0 !important;
  }
  .planer-period-menu .dropdown-options{
    width: 100px;
  }
  .planer-period-menu .dropdown-options .btn{
    width: 100px !important;
  }
  .planer-options-menu .dropdown-options{
    width: 115px;
    margin-right: 0;
  }
  .planer-options-menu .dropdown-options .btn{
    width: 115px;
  }
  .planer-date-period-text-block{
    width: 300px !important;
  }
}
@media screen and (max-width: 1000px) {
  .planer-date-period-text{
    font-size: 20px;
  }
  .planner-icon{
    font-size: 30px !important;
  }
  .planer-date-period-text-block{
    width: 250px !important;
  }
  .planner-page .tabs-nav-right-search{
    width: 200px !important;
  }
  .planner-page .tabs-nav-right-search .search-filter .custom-dropdown.additional-menu-search-filter {
    left: -235px;
    max-height: 500px;
  }
}
@media screen and (max-width: 890px) {
  .planer-date-period-text{
    font-size: 16px;
  }
  .planner-icon{
    font-size: 25px !important;
  }
  .planer-date-period-text-block{
    width: 190px !important;
    position: relative;
    right: 20px;
  }
  .planer-date-period{
    white-space: inherit !important;
  }
}
@media screen and (max-width: 800px) {
  .planer-date-period-text-block{
    width: 150px !important;
  }
}
@media screen and (max-width: 767px) {
  .V3 .fixed-header.compact-sidebar .site-header {
    left: 0;
  }

  .V3 .page-header {
    z-index: 99999;
    min-height: 75px;
  }

  .V3 .tabs-component {
    padding-top: 0;
  }

  .planner-page .planner-icon {
    font-size: 25px;
  }

  .planer-date-period-text-block {
    width: 200px !important;
    text-align: start;
    padding-bottom: 3px;
    margin-right: 20px;
  }

  .planer-date-period-text {
    font-size: 18px;
    color: black;
    padding-bottom: 5px;
  }

  .V3 .planner-page .planer-date-period {
    top: 135px;
    justify-content: flex-end;
  }

  .V3 .dropdown-options,
  .planer-categories-menu {
    width: 110px !important;
  }

  .V3 .dropdown-options .btn {
    width: 110px !important;
    padding: 0.75rem 5px;
  }

  .V3 .dropdown-options .dropdown-arrow {
    width: 25px !important;
  }

  .planer-period-menu .dropdown-options,
  .V3 .planer-period-menu .dropdown-options .btn {
    width: 80px !important;
  }

  .fa.pull-right {
    margin-right: 0.3em;
  }

  .planer-options-menu .dropdown-options,
  .V3 .planer-options-menu .dropdown-options .btn {
    width: 90px !important;
  }

  .V3 .planer-categories-menu .dropdown-options .dropdown-menu {
    width: 200px !important;
  }

  .V3 .planner-page .planer-categories-menu .form-control {
    width: 165px !important;
  }
  .weekly-target{
    display: none;
  }
}

@media (max-width: 460px) {
  .V3 .planner-page .planer-date-period {
    top: 130px;
  }
}

@media (max-width: 425px) {
  .V3 .tabs-component .tab-header.float-sm-right,
  .V3 .tabs-component .tab-header.float-sm-left {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 450px) and (orientation: portrait) {
  .planer-categories-menu {
    display: none;
  }
}
</style>

<style scoped>
.cards-view-button-center .btn:not(.btn-primary):hover{
  border-color: #5E79FF;
  outline: 1px solid #5E79FF !important;
}
.planner-page .tabs-nav-right-search{
  position: absolute;
  right: 15px;
  width: 350px;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.tabs-nav-right-search .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.tabs-nav-right-search .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
}
.search-filter >>> input::placeholder{
  color: #a6a6a6;
  font-weight: 700;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.tabs-nav-right-search .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.tabs-nav-right-search .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
}
.search-filter >>> input::placeholder{
  color: #bbbbc2;
}
.search-filter >>> input:focus{
  background-color: #ebf0fc;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.search-filter >>> .search-filter__box__focused{
  background-color: #ebf0fc;
  border: 3px solid rgba(94, 121, 255, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
}
.search-filter >>> .search-filter__li--input{
  border-bottom: 0;
}
.search-filter >>> .search-filter__li{
  border-bottom: 0 !important;
}
.search-filter >>> .search-filter__box{
  border: 2px solid rgba(28, 31, 57, 0.25);
}
.cards-view-button-center .btn-primary{
  border-radius: 3px;
  color: #f8f8f8;
  transition: none;
  background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
}
.cards-view-button-center .btn-primary:hover{
  background: #5E78FF;
}
.cards-view-button-center{
  margin-left: 20vw;
  height: 41px;
}
.cards-view-button-center .btn{
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px !important;
}
@media screen and (min-width: 1900px) {
  .cards-view-button-center{
    margin-left: 35vw;
  }
}
@media screen and (max-width: 1200px) {
  .cards-view-button-center{
    margin-left: 7vw;
  }
}
@media screen and (max-width: 880px) {
  .cards-view-button-center{
    margin-left: 20px;
  }
}
</style>

