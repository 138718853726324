<template>
  <div class="estimate-booking" :class="{'estimate-booking-display-block': true}" style="position: static;">
    <div class="estimate-booking-header d-flex">
      <div class="estimate-booking-header-title" >Vehicle Booking</div>
      <div class="estimate-booking-body-settings-icon"><i class='bx bx-cog' @click="showMenuSettingsAddVehicleBooking = !showMenuSettingsAddVehicleBooking"></i>
        <div class="estimate-booking-settings" style="width: 450px" v-if="showMenuSettingsAddVehicleBooking">
          <div class="estimate-booking-header">
            <div class="estimate-booking-header-title">Settings</div>
          </div>
          <div class="estimate-booking-settings-body">
            <div class="estimate-booking-settings-body-main">
              <div class="form-group row no-gutter">
                <label for="card_number_length"
                       class="col-lg-5 col-form-label">Status to incl</label>
                <div class="col-lg-7 booking-status-select">
                  <multiselect
                      v-model="vehiclePlanner.vehicleStatuses"
                      :options="computedCeStatuses"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      :placeholder="''"
                      :multiple="true"
                      @input="debouncedSavePlannerBoard"
                      style="opacity: 1">
                    <template #tag="{ option }">
                      {{getStatusName(option)}}<span v-if="vehiclePlanner.vehicleStatuses.indexOf(option) + 1 !== vehiclePlanner.vehicleStatuses.length">, </span>
                    </template>
                    <template #option="{ option }">
                      {{getStatusName(option)}}
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="estimate-booking-header-close"><i class='bx bx-x clickable' @click="$emit('close')"></i></div>
    </div>
    <div class="estimate-booking-body">
      <div class="estimate-booking-body-search">
        <b-form-input
            v-model="filterForVehicle"
            type="text"
            class="form-control search-input"
            placeholder="Search or Filter results"
            debounce="300"
        ></b-form-input>
      </div>
      <div class="estimate-booking-body-main d-flex">
        <div class="estimate-booking-body-main-left" @scroll="removeAllDraggableElements">
          <template v-for="card in computedCardsForVehiclesToBook">
            <div class="estimate-booking-body-main-item noselect"
                 :id="'card-' + card.card_id"
                 :class="{'estimate-booking-body-main-item-active': activeVehicleCard == card.card_id, 'blocked-estimate-booking-item': isAlreadyAddedVehicleEvent(card.card_id) || !card.productionTime || (card.productionTime && Number(card.productionTime.daysCount) == 0)}"
                 @mousemove="onMouseMove($event, 'card-' + card.card_id)"
                 @mousedown="onMouseDown('card-' + card.card_id)"
                 @mouseup="onMouseUp($event, 'card-' + card.card_id)"
                 @click="selectVehicleCard(card.card_id)"
            >
              <div class="estimate-booking-body-main-item-title d-flex" style="position: relative">{{card.card_number}} {{card.customer_name}} <div class="estimate-booking-body-main-item-color" :style="{backgroundColor: card.productionTime ? getGroup(card.productionTime.category).color : null}"></div></div>
              <div class="estimate-booking-body-main-item-info d-flex">
                <div v-if="card.rego_number" class="estimate-booking-body-main-item-info-num">{{card.rego_number}} </div>
                <div :class="{'estimate-booking-body-main-item-info-name': !!card.rego_number}"> {{card.make}} {{card.model}}</div>
              </div>
            </div>
          </template>

        </div>
        <div class="estimate-booking-body-main-right"
             :class="{'estimate-booking-body-main-right-active': activeVehicleCard}"
             v-if="activeVehicleCardEvent && activeVehicleCardEvent.card"
        >
          <div class="estimate-booking-body-main-right-title"><span style="cursor:pointer;" @click="redirectToCard(activeVehicleCard)">{{activeVehicleCardEvent.card.number}} </span>{{activeVehicleCardEvent.card.vehicleOwner}}</div>
          <div class="estimate-booking-body-main-item-info estimate-booking-body-main-right-item d-flex">
            <div v-if="activeVehicleCardEvent && activeVehicleCardEvent.card && activeVehicleCardEvent.card.vehicleRego" class="estimate-booking-body-main-item-info-num">{{activeVehicleCardEvent.card.vehicleRego}}</div>
            <div :class="{'estimate-booking-body-main-item-info-name': !!(activeVehicleCardEvent && activeVehicleCardEvent.card && activeVehicleCardEvent.card.vehicleRego)}"> {{activeVehicleCardEvent.card.vehicleMake}} {{activeVehicleCardEvent.card.vehicleModel}}</div>
          </div>
          <div v-if="activeVehicleCardEvent && activeVehicleCardEvent.card && activeVehicleCardEvent.card.insurer" class="estimate-booking-body-main-item-info estimate-booking-body-main-right-item d-flex">
            <div class="estimate-booking-body-main-item-info-num">{{activeVehicleCardEvent.card.insurer}}</div>
          </div>
          <div class="estimate-booking-body-main-right-item" style="margin-bottom: 5px">
            <div class="estimate-booking-body-main-right-item-title">Category</div>
            <div class="form-control">
              <span style="top: 3px; position: relative" class="categories-menu-color-icon" :style="{backgroundColor: getCategory(activeVehicleCardEvent)}"></span> <span style="top: 0px">{{getGroup(activeVehicleCardEvent.groupId).name}}</span>
            </div>
          </div>
          <div class="estimate-booking-body-main-right-item">
            <div class="estimate-booking-body-main-right-item-colour" :style="{backgroundColor: getCategory(activeVehicleCardEvent)}"></div>
          </div>
          <div class="estimate-booking-body-main-right-item">
            <div class="estimate-booking-body-main-right-item-title">Approximate Days</div>
            <div class="form-control">
              <span>{{getDayAppr(activeVehicleCardEvent)}}</span>
            </div>
          </div>
          <div class="estimate-booking-body-main-right-item">
            <div class="estimate-booking-body-main-right-item-title">Total Job Value</div>
            <div>
              <number-formatter type="text"
                                class="form-control"
                                v-model="activeVehicleCardEvent.estimate.amountJob"
                                :isDisabled="true"
                                format="$0,0.00"
              ></number-formatter>
            </div>
          </div>
          <div class="estimate-booking-body-main-right-item">
            <div class="estimate-booking-body-main-right-item-title">Total Lbr Value</div>
            <div>
              <number-formatter type="text"
                                :isDisabled="true"
                                class="form-control"
                                v-model="activeVehicleCardEvent.estimate.amountLabour"
                                format="$0,0.00"
              ></number-formatter>
            </div>
          </div>

          <div class="estimate-booking-body-main-right-item">
            <div class="estimate-booking-body-main-right-item-title">Date Booked (Start)</div>
            <div>
              <date-picker v-model="activeVehicleCardEvent.dateStart"
                           :is-disabled="_isEmpty(activeVehicleCardEvent)"
                           @onChange="saveDateVehicle"
                           :disabled-dates="disabledDates"
                           place-holder-text="Date Booked (Start)"
              ></date-picker>
            </div>
          </div>

          <div class="estimate-booking-body-main-right-item">
            <div class="estimate-booking-body-main-right-item-title">Date Completed</div>
            <div>
              <date-picker v-model="activeVehicleCardEvent.dateEnd"
                           :is-disabled="_isEmpty(activeVehicleCardEvent)"
                           @onChange="saveDateVehicle"
                           :disabled-dates="disabledDates"
                           place-holder-text="Date Completed"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import NumberFormatter from "@/components/utility/number-formatter.vue";
import Multiselect from 'vue-multiselect';
import Axios from "axios";
import DatePicker from '../../utility/date-picker'

export default {
  name: "vehicle-booking",
  components: {
    NumberFormatter,
    Multiselect,
    DatePicker
  },
  data() {
    return {
      filterForVehicle: '',
      showMenuSettingsAddVehicleBooking: false,
      vehiclePlanner: {
        vehicleStatuses: []
      },
      activeVehicleCard: null,
      activeVehicleCardEvent: {},
      //isMouseDown: false,
      //selectedId: null
    }
  },
  props: {
    calendarValue: {
      type: [String, Date],
    },
    events: {
      type: Array
    },
    groups: {
      type: Array
    },
    settings: {
      type: Object
    },
    planner: {
      type: Object
    }
  },
  watch: {
    vendorInfo: {
      handler(v) {
        if (!_.isEqual(v.planner.vehicleStatuses, this.vehiclePlanner.vehicleStatuses)) {
          this.vehiclePlanner = _.cloneDeep(v.planner)
        }
      },
      deep: true
    },
    events: {
      handler(v) {
        if (this.activeVehicleCard) {
          let event = _.find(_.cloneDeep(this.events), (evt) => {
            return Number(this.activeVehicleCard) == evt?.card?.id && evt.type == 'vehicle'
          })

          this.activeVehicleCardEvent = event ? event : {}
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('scroll', this.removeAllDraggableElements);
    if (this.vendorInfo.vendor_id) {
      this.vehiclePlanner = _.cloneDeep(this.vendorInfo.planner)
    }
  },
  created() {
    this.debouncedSavePlannerBoard = _.debounce(this.saveVehicleStatuses, 500);
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('scroll', this.removeAllDraggableElements);
  },
  methods: {
    saveDateVehicle() {
      let originalEvent = _.find(_.cloneDeep(this.events), (evnt) => {
        return Number(evnt.id) == Number( this.activeVehicleCardEvent.id)
      })

      let start = this.activeVehicleCardEvent.dateStart;
      let end = this.activeVehicleCardEvent.dateEnd;
      console.log(start, end)
      if (!start || !end) {
        return
      }
      if (_.includes(start, '/')) {
        start = start.split('/').reverse().join('-')
      }
      if (_.includes(end, '/')) {
        end = end.split('/').reverse().join('-')
      }

      if(start == originalEvent.dateStart && end == originalEvent.dateEnd)  {
        return
      }

      Axios.put(
          "/fe/planner-board",
          {
            id: this.activeVehicleCardEvent.id,
            dateStart: start,
            dateEnd: end
          }
      ).then(response => {
        // this.$emit('loadEvents')
      })
    },
    _isEmpty(v) {
      return _.isEmpty(v);
    },
    redirectToCard(id) {
      this.$router.push({name: 'CardsEdit', params: {card_id: id}});
    },
    getCategory(v) {
      if (v?.estimate?.productionTime?.category) {
        return this.getGroup(v.estimate.productionTime.category)?.color
      }
      if (v?.card?.groupId) {
        let color = _.find(this.groups, (g) => {
          return Number(g.id) == Number(v.card.groupId)
        })
        if (color) {
          return color?.color
        }
        return null
      }
      return null
    },
    getGroup(v) {
      let r = _.find(this.settings.definitions.categories, (c) => {
        return c.id == v || _.toLower(c.name) == v
      })
      if (r) {
        let color = _.find(this.groups, (g) => {
          return Number(g.id) == Number(r.colorId)
        })
        return {
          color: color ? color.color : '',
          name: r.name
        }
      }
      return {
        color: '',
        name: '',
      }
    },
    isAlreadyAddedVehicleEvent(id) {
      return _.includes(this.computedAddedCardsIds, Number(id))
    },
    isGetDaysNotAvailable(id) {
      let c = _.find(_.cloneDeep(this.computedCardsForVehiclesToBook), (c) => {
        return Number(c.card_id) == Number(id)
      })
      if (c) {
        if (!c.productionTime) {
          return true
        }
        if (Number(c?.productionTime?.daysCount) > 0) {
          return false
        }
      }
      return true
    },
    getStatusName(v) {
      return _.find(this.ceStatuses, (s) => {
        return Number(s.ce_status_id) == Number(v)
      })?.name
    },
    saveVehicleStatuses() {
      Axios.post('fe/planner-board/settings', {
        planner: {
          vehicleStatuses: this.vehiclePlanner.vehicleStatuses,
          statuses: this.planner.statuses,
          timeDuration: this.planner.timeDuration,
          maxNbrPerSlot: this.planner.maxNbrPerSlot,
          weeks: this.planner.weeks
        }
      }).finally(() => {
        this.$store.dispatch('loadVendorInfo')
      })
    },
    getCardById(id) {
      let card =  _.find(_.cloneDeep(this.getterAllEstimates), (c) => {
        return Number(c.card_id) == Number(id)
      })
      if (card) {
        if (!card.productionTime) {
          card.productionTime = {}
        }
        return card
      }
      return  {
        productionTime: {}
      }
    },
    removeAllDraggableElements() {
      if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day'){
        _.forEach(this.computedCardsForBooking, (card) => {
          let el = document.getElementById('card-' + card.card_id)
          if (el) {
            el.style.position = 'static'
            el.style.width = 'auto'
          }
        })
        this.isMouseDown = false
        this.selectedId = null;
      }
    },
    onMouseMove(e, id) {
      if (!this.selectedId) {
        return
      }
      if (this.selectedId) {
        this.removeAllDraggableElementsExceptCurrent(this.selectedId.replace('card-', ''))
      }
      if (this.selectedId && this.isAlreadyAddedVehicleEvent(this.selectedId.replace('card-', '')) || this.isGetDaysNotAvailable(this.selectedId.replace('card-', ''))) {
        return
      }
      if (id && this.selectedId !== id) {
        return
      }
      if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day'){
        if (this.isMouseDown) {
          let el = document.getElementById(this.selectedId)
          if (!el) {
            return
          }
          el.style.position = 'absolute'
          el.style.left = e.clientX  - 162 + 'px'
          el.style.top = e.clientY  - 25 + window.pageYOffset  + 'px'
          el.style.width = '324px'
          el.style.backgroundColor = 'white'
        }
      }
    },
    onMouseDown(id) {
      this.mouseDownTime = 0;
      this.mouseDownTimeInterval = setInterval(() => {
        this.mouseDownTime +=1
      }, 1)
      if (this.isMouseDown || this.selectedId) {
        if (id && !this.selectedId) {
          this.selectedId = id
        }
        return
      }
      if (id && this.isAlreadyAddedVehicleEvent(id.replace('card-', '')) || this.isGetDaysNotAvailable(id.replace('card-', ''))) {
        return
      }
      if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day'){
        if (id) {
          this.selectedId = id
          console.log('on mouse down', id)
        }
        this.isMouseDown = true
      }
    },
    onMouseUp(e, id) {
      clearInterval(this.mouseDownTimeInterval)
      if (!this.selectedId) {
        return
      }
      if (id && this.isAlreadyAddedVehicleEvent(id.replace('card-', '')) || this.isGetDaysNotAvailable(id.replace('card-', ''))) {
        return
      }
      if (!this.isMouseDown) {
        return
      }
      if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day'){
        this.isMouseDown = false
        let el = document.getElementById(id)
        if (!el) {
          this.selectedId = null;
          this.removeAllDraggableElements()
          return
        }
        el.style.position = 'static'
        el.style.width = 'auto'
        let target = document.elementFromPoint(e.clientX, e.clientY)

        if (!_.includes(target.classList, 'v-calendar-daily_head-day')) {
          let targets = document.elementsFromPoint(e.clientX, e.clientY)
          target = _.find(targets, (t) => {
            return _.includes(t?.classList, 'v-calendar-daily_head-day')
          })
          if (!target) {
            this.selectedId = null;
            return;
          }
        }

        if (!_.includes(target.classList, 'v-calendar-daily_head-day')) {
          this.selectedId = null;
          return
        }

        let dateResult = null
        if (this.computedCalendarType === 'week') {
          let days = document.getElementsByClassName('v-calendar-daily_head-day')
          let index = null
          _.forEach(days, (d, ind) => {
            if (d == target) {
              index = ind + 1
            }
          })
          dateResult = dayjs(this.calendarValue).isoWeekday(index).format('YYYY-MM-DD')

        } else {
          dateResult =  dayjs(this.calendarValue).format('YYYY-MM-DD')
        }

        let title = '';
        let card = this.getCardById(this.selectedId.replace('card-', ''))

        if (card) {
          if (card.card_number) {
            title += card.card_number
            if (card.rego_number || card.make || card.model) {
              title += ' - '
            }
          }
          if (card.rego_number) {
            title += card.rego_number + ' ';
          }
          if (card.make) {
            title += card.make + ' ';
          }
          if (card.model) {
            title += card.model;
          }
        }

        let days = card?.productionTime?.daysCount ? card.productionTime.daysCount: 2

        let dateEnd = null;

        for (let i = 0; i < days; i++) {
          let newDay = dayjs(dateResult).add(i, 'day').format('YYYY-MM-DD')
          if (this.settings.days[dayjs(newDay).day()]) {
            dateEnd = newDay
          }
        }

        if (!dateEnd) {
          dateEnd = dateResult
        }

        let data = {
          "cardId": this.selectedId.replace('card-', ''),
          "dateStart": dateResult,
          "timeStart": '00:00:00',
          "dateEnd": dateEnd,
          "timeEnd": '23:59:59',
          "title": title,
          type: 'vehicle',
          "color": card.productionTime ? this.getGroup(card.productionTime.category).color : null
        }

        this.$emit('add', data)

        this.selectedId = null;

      }

    },
    removeAllDraggableElementsExceptCurrent(id = null) {
      if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day'){
        _.forEach(this.computedCardsForBooking, (card) => {
          let el = document.getElementById('card-' + card.card_id)
          if (el && Number(card.card_id) !== Number(id)) {
            el.style.position = 'static'
            el.style.width = 'auto'
          }
        })
      }
    },
    getDayAppr(event) {
      if (event?.estimate?.productionTime?.daysCount) {
        return event.estimate.productionTime.daysCount
      }
      if (event?.card?.daysCount) {
        return event.card.daysCount
      }

      return 0
    },
    selectVehicleCard(id, isFromMenu = false) {
      if (!isFromMenu && this.mouseDownTime >= 100) {
        return
      }
      if (this.activeVehicleCard == id && !isFromMenu) {
        this.activeVehicleCard = null
      } else {
        let event = _.find(_.cloneDeep(this.events), (evt) => {
          return Number(id) == evt?.card?.id && evt.type == 'vehicle'
        })

        let card = {}
        if (this.getCardById(id)) {
          let c = this.getCardById(id)
          card = {
            id: c.card_id,
            insurer: c.insurance,
            vehicleMake: c.make,
            vehicleModel: c.model,
            vehicleOwner: c.customer_name,
            number: c.card_number,
            vehicleRego: c.rego_number
          }
        }
        let estimate = _.find(this.getterAllEstimates, (est) => {
          return Number(est.card_id) == Number(id)
        })
        this.activeVehicleCardEvent = event ? event : {card: card, estimate: estimate ? estimate : {}}
        this.activeVehicleCard = id
      }


      if (isFromMenu) {
        setTimeout(() => {
          let el = document.getElementById('card-' + this.activeVehicleCard)
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: "start",
            });
          }
        }, 0)
      }
    },
  },
  computed: {
    ...mapGetters({
      getterAllCards: 'card/getAllCards',
      getterAllEstimates: 'estimate/getAllEstimates',
      userInfo: 'userInfo',
      getterBoardIn: 'board/getBoardIn',
      getterBoardDeparture: 'board/getBoardDeparture',
      getBookingItems: 'board/getBookingItems',
      getterVisibleUsers: 'visibleUsers',
      getterPlannerBoardType: 'getPlannerBoardType',
      vendorInfo: 'vendorInfo',
      ceStatuses: 'ceStatuses',
    }),
    computedAddedCardsIds() {
      let evetns =   _.filter(_.cloneDeep(this.events), (evt) => {
        return evt?.card?.id && evt.type == 'vehicle'
      })
      return _.map(evetns, (e) => {
        return Number(e.card.id)
      })
    },
    disabledDates() {
      let arr = []

      _.forEach(this.settings.days, (d, ind) => {
        if (!d) {
          arr.push(ind + 1)
        }
      })

      if (_.isEmpty(arr)) {
        return  {}
      }
      return { weekdays: arr }
    },
    computedCeStatuses() {
      return this.ceStatuses.map((el => {return el.ce_status_id}))
    },
    computedCalendarType() {
      if (this.getterPlannerBoardType === 'weekStarting') {
        return 'week';
      }
      return this.getterPlannerBoardType;
    },
    computedCardsForVehiclesToBook () {
      return _.filter(this.getterAllEstimates, (c) => {
        let b = false;
        if (_.isEmpty(this.filterForVehicle)) {
          b = true
        } else {
          let formattedFilter = _.trim(_.toLower(this.filterForVehicle))

          if (c.card_number && _.includes(_.trim(_.toLower(c.card_number)), formattedFilter)) {
            b = true;
          }
          if (c.estimate_number && _.includes(_.trim(_.toLower(c.estimate_number)), formattedFilter)) {
            b = true;
          }
          if (c.rego_number && _.includes(_.trim(_.toLower(c.rego_number)), formattedFilter)) {
            b = true;
          }
          if (c.customer_name && _.includes(_.trim(_.toLower(c.customer_name)), formattedFilter)) {
            b = true;
          }
          if (c.make && _.includes(_.trim(_.toLower(c.make)), formattedFilter)) {
            b = true;
          }
          if (c.model && _.includes(_.trim(_.toLower(c.model)), formattedFilter)) {
            b = true;
          }
        }
        return _.includes(this.vehiclePlanner.vehicleStatuses, c.card_status) && b
      })
    },
  }
}
</script>

<style scoped>
.search-filter >>> input::placeholder{
  color: #bbbbc2;
}
.search-filter >>> input:focus{
  background-color: #ebf0fc;
}
.search-filter >>> .search-filter__box__focused{
  background-color: #ebf0fc;
  border: 2px solid rgba(94, 121, 255, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
}
.search-filter >>> .search-filter__li--input{
  border-bottom: 0;
}
.search-filter >>> .search-filter__li{
  border-bottom: 0 !important;
}
.search-filter >>> .search-filter__box{
  border: 2px solid rgba(28, 31, 57, 0.25);
}

.estimate-booking-body-main-right-title:hover{
  color: #086fd7;
}
.estimate-booking-body-main-item-info-num:hover{
  color: #086fd7;
}
.blocked-estimate-booking-item {
  cursor: not-allowed !important;
}

.estimate-booking-body-main-item-color{
  height: 20px;
  width: 20px;
  border-radius: 3px;
  position: absolute;
  right: -10px;
  top: 0;
}
.estimate-booking-body-main-right-item-colour{
  border-radius: 3px;
  height: 20px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.estimate-booking-body-search >>> .search-filter{
  z-index: 9 !important;
}
.estimate-booking{
  width: 50%;
  margin-top: 18px;
  border: solid 1px #b9b9b9;
  border-radius: 5px;
  position: relative;
}
.estimate-booking-display-block{
  display: block;
}
.estimate-booking-header{
  position: relative;
  background-color: #e1e2e5;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px 5px 0 0;
}
.estimate-booking-header-close{
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 22px;
  cursor: pointer;
}
.estimate-booking-header-close .bx-x:hover{
  color: #5b5b5b;
  transition: 0.2s;
}
.estimate-booking-body-settings{
  margin: 0 15px;
  padding: 15px 10px 0 10px;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
}
.estimate-booking-body-settings-icon{
  position: absolute;
  bottom: 5px;
  right: 80px;
}
.estimate-booking-body-settings-icon .bx-cog{
  font-size: 22px;
  color: #254e9c;
  cursor: pointer;
}

.estimate-booking-body-search{
  padding: 15px 10px;
  margin: 0 15px;
  border-bottom: 1px solid #b9b9b9;
}
.estimate-booking-body-main-left{
  width: 60%;
  overflow-x: auto;
  scrollbar-width: thin;
  max-height: 60vh;
}
.estimate-booking-body-main-right{
  width: 40%;
  display: none;
}
.estimate-booking-body-main-item-title{
  font-weight: bold;
}
.estimate-booking-body-main-item-info-num{
  font-weight: bold;
}
.estimate-booking-body-main-item{
  border: 2px solid #b9b9b9;
  border-radius: 5px;
  margin: 10px 15px;
  padding: 10px 20px;
  cursor: move;
  z-index: 100;
}
.estimate-booking-body-main-item-info-name{
  margin-left: 15px;
}

.estimate-booking-body-main-right{
  border: 2px solid #b9b9b9;
  border-radius: 5px;
  margin: 10px;
  min-width: 200px;
  padding: 10px 15px;
}
.estimate-booking-body-main-right-title{
  font-weight: 700;
  margin-bottom: 0;
}
.estimate-booking-body-main-right-item{
  margin-bottom: 10px;
}
.estimate-booking-body-main-right-item-textarea{
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
  resize: none;
  padding: 0.5rem 0.75rem;
  width: 100%;
  min-height: 70px;
}
.estimate-booking-settings{
  position: absolute;
  z-index: 10;
  right: -40px;
  min-width: 280px;
  top: 25px;
  border: solid 1px #b9b9b9;
  border-radius: 5px;
}
.estimate-booking-settings:after{
  content: "";
  position: absolute;
  bottom: -50px;
  height: 1px;
  width: 1px;
}
.estimate-booking-settings-body{
  padding: 10px 15px;
  background-color: #fdfdfd;
  border-radius: 0 0 5px 5px;
}
.estimate-booking-settings-body-main .form-group{
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  min-height: 56px;
}
.estimate-booking-settings-body-main .col-form-label{
  position: relative;
  top: 5px;
  padding-right: 10px;
}
.estimate-booking-settings-body-table{
  margin-top: 30px;
}
.estimate-booking-settings-body-table-title{
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 1px solid #b9b9b9;
}
.estimate-booking-settings-body-table-title-item{
  width: 40%;
}
.estimate-booking-settings-body-table-title-item:nth-child(1){
  width: 20%;
}
.estimate-booking-settings-body-table-main-item-width{
  width: 40%;
}
.estimate-booking-settings-body-table-main-item-width:nth-child(1){
  width: 20%;
  padding-top: 23px;
}
.estimate-booking-settings-body-table-main-item-start{
  margin-right: 10px;
}
.estimate-booking-settings-body-table-main-item{
  height: 60px;
}
.estimate-booking-body-main-item-active{
  border-color: #5d78fd;
  background-color: #dde2fd !important;
}
.estimate-booking-body-main-right-active{
  border-color: #5d78fd;
  display: block;
}
</style>
