import { render, staticRenderFns } from "./booking-board.vue?vue&type=template&id=897cf6a8&scoped=true"
import script from "./booking-board.vue?vue&type=script&lang=js"
export * from "./booking-board.vue?vue&type=script&lang=js"
import style0 from "./booking-board.vue?vue&type=style&index=0&id=897cf6a8&prod&lang=css"
import style1 from "./booking-board.vue?vue&type=style&index=1&id=897cf6a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "897cf6a8",
  null
  
)

export default component.exports