<template>
  <div class="car-scheme-wrapper d-flex">
    <div class="car-scheme-image">
      <car-scheme-sedan-svg
          ref="sedanSvg"
          v-if="body && body.toLowerCase() == 'sedan'"
          @selectedPart="pathClick"
          :body="body"
          :activeTabCar="activeTabCar"
      ></car-scheme-sedan-svg>
      <car-scheme-suv-hatch-svg
          ref="suvHatchSvg"
          v-else-if="body"
          @selectedPart="pathClick"
          :body="body"
          :activeTabCar="activeTabCar"
      ></car-scheme-suv-hatch-svg>
    </div>
    <div class="car-scheme-nav">
      <ul>
        <li @click="$emit('close')"><i class='bx bx-arrow-to-left'></i></li>
        <li @click="activeTabCar = 'top-view'" @dblclick="emptyFilters('top-view')" :class="{'btn-active': activeTabCar == 'top-view'}"><img src="../../../../mjml/img/car/top.png" height="26" width="52"/></li>
        <li @click="activeTabCar = 'left-view'"  @dblclick="emptyFilters('left-view')" :class="{'btn-active': activeTabCar == 'left-view'}"><img src="../../../../mjml/img/car/left.png" height="25" width="50"/></li>
        <li @click="activeTabCar = 'right-view'"  @dblclick="emptyFilters('right-view')" :class="{'btn-active': activeTabCar == 'right-view'}"><img src="../../../../mjml/img/car/right.png" height="25" width="50"/></li>
      </ul>
    </div>
  </div>
</template>

<script>
import CarSchemeSedanSvg from "@/components/estimates/estimate-edit/car-scheme-sedan-svg.vue";
import CarSchemeSuvHatchSvg from "@/components/estimates/estimate-edit/car-scheme-suv-hatch-svg.vue";

export default {
  name: 'car-scheme',
  components: {CarSchemeSuvHatchSvg, CarSchemeSedanSvg},
  props: {
    body: {
      type: String,
      default: ''
    },
    estimateMethodology: {
      type: String,
      default: ''
    },
  },
  data: function () {
    return {
      activeTabCar: 'top-view',
    }
  },
  methods: {
    emptyFilters(v){

      if (this.$refs['suvHatchSvg']){
      // console.log('suvHatchSvg')
        this.$refs['suvHatchSvg'].emptyFilters();
      }
      if (this.$refs['sedanSvg']){
        // console.log('sedanSvg')
        this.$refs['sedanSvg'].emptyFilters();
      }
      // this.$emit('emptyFilters', v)
    },
    pathClick(v){
      this.$emit('selectedPart', v)
    },
  },
}
</script>

<style scoped>
.car-scheme-wrapper{
  height: 200px;
  width: 504px;
  background-color: white;
  border-radius: 3px;
  border: 2px solid grey;
}
.car-scheme-image{
  width: 80%;
}
.car-scheme-nav{
  width: 20%;
}
.car-scheme-nav ul{
  list-style-type:  none;
}
.car-scheme-nav ul .bx-arrow-to-left{
  font-size: 35px;
}
.car-scheme-nav ul li{
  cursor: pointer;
  margin: 5px 10px;
  text-align: center;
  padding: 3px 0;
  border: 1px solid white;
  border-radius: 3px;
}
.car-scheme-nav ul li:hover{
  border-color: #5E79FF;
  outline: 1px solid #5E79FF !important;
}
.car-scheme-nav ul li:first-child{
  height: 40px;
  padding: 0;
}
.car-scheme-nav .btn-active{
  border: 1px solid rgba(128, 128, 128, 0.59) !important;
}
</style>

