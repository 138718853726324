<template>
  <div class="whitespace-nowrap">
    <div v-if="!item.activity || !item.activity.Email || !item.activity.Email.date" class="notf-icon">
      <img :id="`i-email-${name}`" :src="iconEmailGrey"/>
      <b-tooltip
          :target="`i-email-${name}`"
          triggers="hover"
          placement="leftbottom"
          :disabled="true"
          custom-class="ps-tooltip ps-tooltip-table-custom"
      >
      </b-tooltip>
    </div>
    <div v-else @click="goToEmail(item)" class="notf-icon">
      <img :id="`i-email-${name}`" :src="iconEmailBlue"/>
      <b-tooltip
          :target="`i-email-${name}`"
          triggers="hover"
          placement="leftbottom"
          custom-class="ps-tooltip ps-tooltip-table-custom"
      >
        Unread {{ item.activity.Email.smsId ? 'SMS' : 'email' }} message at:<br/>
        <strong>{{ item.activity.Email.date }}</strong>
      </b-tooltip>
    </div>

    <div v-if="item?.activity?.External?.notice &&  item.activity.External.notice  != ''"
         class="notf-icon">
      <img :id="`i-external-${name}`" :src="iconExternalBlue"/>
      <b-tooltip
          :target="`i-external-${name}`"
          triggers="hover"
          placement="leftbottom"
          custom-class="ps-tooltip ps-tooltip-table-custom"
      >
        {{item.activity.External.notice}}
      </b-tooltip>
    </div>
    <div v-else class="notf-icon">
      <img :id="`i-external-${name}`" :src="iconExternalGrey"/>
      <b-tooltip
          :target="`i-external-${name}`"
          triggers="hover"
          placement="leftbottom"
          :disabled="true"
          custom-class="ps-tooltip ps-tooltip-table-custom"
      >
      </b-tooltip>
    </div>

    <div
        v-if="item.activity && item.activity.Assignment && item.activity.Assignment.type"
        class="notf-icon"
    >
      <img :id="`i-task-${name}`" :src="iconAssignBlue"/>
      <b-tooltip
          v-if="item.activity.Assignment.type && item.activity.Assignment.type == 'Task'"
          :target="`i-task-${name}`"
          triggers="hover"
          placement="leftbottom"
          custom-class="ps-tooltip ps-tooltip-table-custom"
      >
        Task Assigned From:<br/>
        <strong>{{ item.activity.Assignment.from }}</strong>
      </b-tooltip>
      <b-tooltip
          v-if="item.activity && item.activity.Assignment.type && item.activity.Assignment.type == 'Card'"
          :target="`i-task-${name}`"
          triggers="hover"
          placement="leftbottom"
          custom-class="ps-tooltip ps-tooltip-table-custom"
      >
        File Assigned From:<br/>
        <strong>{{ item.activity.Assignment.from }}</strong>
      </b-tooltip>
    </div>
    <div v-else class="notf-icon">
      <img :id="`i-task-${name}`" :src="iconAssignGrey"/>
      <b-tooltip
          :target="`i-task-${name}`"
          triggers="hover"
          placement="leftbottom"
          :disabled="true"
          custom-class="ps-tooltip ps-tooltip-table-custom"
      >
      </b-tooltip>
    </div>

    <div
        v-if="item.activity && item.activity.Message && item.activity.Message.date"
        class="notf-icon"
    >
      <img :id="`i-message-${name}`" :src="iconMessageBlue"/>
      <b-tooltip
          :target="`i-message-${name}`"
          triggers="hover"
          placement="leftbottom"
          custom-class="ps-tooltip ps-tooltip-table-custom"
      >
        Message From:<br/>
        <strong>{{ item.activity.Message.from }}</strong>
      </b-tooltip>
    </div>
    <div v-else class="notf-icon">
      <img :id="`i-message-${name}`" :src="iconMessageGrey"/>
      <b-tooltip
          :target="`i-message-${name}`"
          triggers="hover"
          placement="leftbottom"
          :disabled="true"
          custom-class="ps-tooltip ps-tooltip-table-custom"
      >
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import iconEmailGrey from '../assets/img/bx-envelope-gray.svg'
import iconEmailBlue from '../assets/img/bx-envelope-blue.svg'
import iconExternalGrey from '../assets/img/bx-link-external-gray.svg'
import iconExternalBlue from '../assets/img/bx-link-external-blue.svg'
import iconAssignGrey from '../assets/img/bx-user-gray.svg'
import iconAssignBlue from '../assets/img/bx-user-blue.svg'
import iconAssign from '../assets/img/bx-user.svg'
import iconMessageGrey from '../assets/img/bx-message-rounded-gray.svg'
import iconMessageBlue from '../assets/img/bx-message-rounded-blue.svg'
import iconMessage from '../assets/img/bx-message-rounded.svg'
import _ from 'lodash'

export default {
  props: {
    item: {
      type: Object,
      default: {
        card_id: '',
        activity: {
          Email: {
            date: '',
            id: 0,
            from: '',
            activity_id: 0,
          },
          Assignment: {
            from: '',
            type: ''
          },
          Message: {
            from: '',
            date: ''
          },
          External: {
            from: '',
            date: '',
            notice: '',
          },
        },
      }
    },
    // Unique String ID
    // must be unique row and tab
    name: {
      type: String,
    }
  },
  data () {
    return {
      iconEmailGrey,
      iconEmailBlue,
      iconExternalGrey,
      iconExternalBlue,
      iconAssignGrey,
      iconAssignBlue,
      iconMessageGrey,
      iconMessage,
      iconMessageBlue
    }
  },
  computed:{
    CMS () {
      let cms = this.item?.activity?.External?.from
      if (_.toLower(cms) === 'iag' || _.toLower(cms) === 'orm') {
        return 'ORM'
      } else if (_.toLower(cms) === 'claimlinq') {
        return 'ClaimLinq'
      } else if (_.toLower(cms) === 'estimage' ||  _.toLower(cms) === 'estimage online' ||  _.toLower(cms) === 'estimageonline'  ) {
        return 'Estimage'
      }
      return _.toUpper(cms)

    }
  },
  methods: {
    goToEmail (item) {
      this.$router.push(
          { name: 'CardsEdit', params: { action: 'goToEmail', card_id: item.card_id, activityId: item.activity.activityId } }
      )
    },

  }
}
</script>

<style>
.ps-tooltip-table-custom{
  top: 10px !important;
  left: 5px !important;
}
</style>
