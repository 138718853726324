<template>
  <div class="">
    <div class="" id="qr-code-wrapper">
      <div class="label">
        <div class="label-content">
          <p style="font-size: 12px" v-if="$route.query.customer">{{$route.query.customer}}</p>
          <p v-if="$route.query.insurer">{{$route.query.insurer}}</p>
          <p v-if="$route.query.rego">REGO: {{$route.query.rego}}</p>
        </div>

        <div class="qr-code">
          <p class="current-time-text">{{ currentTime | formatDateTime }}</p>
          <q-r-code :value="qrValue" :size="90"></q-r-code>
        </div>
      </div>
    </div>
    <div class="" id="qr-code-wrapper-2">
      <div class="label">
        <div class="label-content">
          <p style="font-size: 12px" v-if="$route.query.customer">{{$route.query.customer}}</p>
          <p v-if="$route.query.insurer">{{$route.query.insurer}}</p>
          <p v-if="$route.query.rego">REGO: {{$route.query.rego}}</p>
          <p style="font-size: 12px" v-if="$route.query.make || $route.query.model">{{$route.query.make}}<span v-if="$route.query.make && $route.query.model"> - </span> {{$route.query.model}}</p>
        </div>

        <div class="info-right">
          <p style="margin-bottom: 6px" class="current-time-text">{{currentTime | formatDateTime}}</p>
          <p style="margin-bottom: 5px; font-size: 15px;">Job Nbr: {{$route.query.cardNumber}}</p>
          <p>Location: </p>
        </div>
      </div>

      <button @click="printLabel" class="print-btn form-control">Print Key Label</button>
      <button v-if="$route.query.isPrintJobCard == 'true'" style="margin-top: 15px" @click="printJobCard" class="print-btn form-control">Print Job File</button>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode.vue';
import {appConfig} from "../../config";

export default {
  name: "qr-code-component",
  components: {
    QRCode,
  },
  data() {
    return {
      qrValue: this.$route.query.cardNumber,
      currentTime: new Date(),
      interval: null,
      isPrintedLabel: false,
    };
  },
  methods: {
    printLabel() {
      this.isPrintedLabel = true
      window.print();
    },
    printJobCard: function () {
      let card_id = this.$route.query.cardId
      let token = localStorage.getItem('token')
      window.open(appConfig.baseAPIURL + `/fe/pdf/jobcard/${card_id}?at=${token}`)
    },
  },
  mounted() {
    let siteBar = document.getElementsByClassName('site-sidebar');
    let siteHeader = document.getElementsByClassName('site-header');
    let siteContent = document.getElementsByClassName('site-content');
    let footer = document.getElementsByClassName('footer');
    if (siteBar[0]) {
      siteBar[0].style.display = "none"
    }
    if (footer[0]) {
      footer[0].style.display = "none"
    }
    if (siteHeader[0]) {
      siteHeader[0].style.display = "none"
    }
    if (siteContent[0]) {
      siteContent[0].style.padding = "0px"
      siteContent[0].style.margin = "0px"
    }

    setTimeout(() => {
      let nprogress = document.getElementById('nprogress')
      if (nprogress) {
        nprogress.style.display = "none"
      }
      if (this.$route?.query?.isPrintJobCard != 'true') {
        this.printLabel()
      }
    }, 100)

    this.interval = setInterval(() => {
      this.currentTime = new Date()
    }, 500)

    window.onafterprint = () => {
      if (this.isPrintedLabel && this.$route?.query?.isPrintJobCard == 'true') {
        let card_id = this.$route.query.cardId
        let token = localStorage.getItem('token')
        window.location.replace(appConfig.baseAPIURL + `/fe/pdf/jobcard/${card_id}?at=${token}`);
      } else {
        window.close()
      }
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped>
.current-time-text{
  margin-bottom: 10px
}
.print-btn {
  background-color: #5E79FF;
  border-color: #5E79FF;
  color: white;
  width: 120px;
  margin-top: 25px;
}
.label {
  width: 79mm;
  height: 39mm;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 3px;
  padding: 3mm 7px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.label-content{
  width: 170px;
  margin-right: 8px;
}
.label-content p {
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 8px;
  line-height: 16px;
}

.qr-code p,
.info-right p {
  font-weight: bold;
  font-size: 10px;
}

.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#qr-code-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  margin-bottom: 30px;
}

#qr-code-wrapper-2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>

<style>
@media print {
  a[href]:after { content: none; }
  @page { margin: 0;
    /*size: 79mm 39mm;*/
  }
  #qr-code-wrapper {
    page-break-after: always;
  }

  #qr-code-wrapper,
  #qr-code-wrapper-2 {
    width: 79mm !important;
    height: 39mm !important;
    margin: 0px !important;
    padding: 0px !important;
    overflow: hidden;
    border:none !important;
  }

  .label {
    border: none !important;
  }

  .print-btn {
    display: none;
  }
}
</style>
