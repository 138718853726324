import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
  promos: [],
  team: [],
  news: [],
  tasks: {},
  emails: {},
  sms: [],
  insurers: [],
  comments: {},
  lastTimestamp: 0,
};

export default {
  namespaced: true,
  mutations: Mutations,
  actions: Actions,
  state,
  getters: Getters,
};
