<script>

export default {
  name: 'AuthByCode',
  data () {
    return {
      isProcessing: false,
      authCode: null,
    }
  },
  created () {
    if (this.$route && this.$route.params && this.$route.params.authCode && this.$route.params.authCode != '') {
      this.authCode = this.$route.params.authCode
    }
  },
  mounted () {
    if (this.authCode && this.authCode != null && !this.isProcessing) {
      this.isProcessing = true
      this.loginByCode(this.authCode)
    }
  },
  methods: {
    auth () {
      this.$nextTick(() => {
        this.$store.dispatch('loadMenu')
        this.$store.dispatch('loadUserInfo').then(() => {
          this.$store.dispatch('loadVendorInfo').then(() => {
            this.$store.dispatch('loadDashboardSummary')
            this.$store.dispatch('loadCEStatuses')
            this.$store.dispatch('dashboard/loadNotices')
            this.$store.dispatch('card/loadCards', { page: 1, withTimestamp: false })
            this.$store.dispatch('estimate/loadEstimates', { page: 1, withTimestamp: false })
            this.$store.dispatch('invoice/loadInvoices', { page: 1, withTimestamp: false })
            this.$store.dispatch('dashboard/loadData', {isShowNProgress: false})
            this.$store.dispatch('board/loadInBoard', { page: 1, withTimestamp: false })
            this.$store.dispatch('board/loadDepartureBoard', { page: 1, withTimestamp: false })
          }).then(() => {
            this.$store.dispatch('loadVehicleMakeModels')
            this.$store.dispatch('loadVehicleScheduleNumbers')
            this.$store.dispatch('loadPartCategories')
            this.$store.dispatch('loadButterfly')
            this.$store.dispatch('loadUsers')
            this.$store.dispatch('loadBusinessTypes')
            this.$store.dispatch('loadCustomerVehicles')
            this.$store.dispatch('loadPartsSearchLocation')
          }).then(()=>{
            this.$store.dispatch('report/loadReports')
          })
        })
      })
    },
    loginByCode (authCode) {
      //let authCode = this.authCode ;
      if (authCode) {
        NProgress.start()
        this.$store.dispatch('loginByCode', { authCode })
            .then((response) => {
              this.authCode = null
              if (response && response.data && response.data._status) {
                this.auth()
                NProgress.done()
                this.$router.push('/')
              } else {
                NProgress.done()
                this.$router.push('/login')
              }
            })
            .catch(error => {
              NProgress.done()
              this.$router.push('/login')
            })
      }
    },
  }
}
</script>

<template>

</template>



