<template>
  <div class="d-flex flex-column work-center-main">
    <div class="d-flex dashboard-btns">
      <div style="min-width: 290px !important">
        <search-filter
            type="estimates"
            class="search-filter"
            style="padding-left: 13px !important; margin-right: 20px"
            v-model="searchFilter"
            :limitLength="50"
            :filterValue="searchFilter"
            @selectAdditional="redirectToCardEdit"
            @onEnter="onEnter"
            :placeholder="'Search'"
            :additionalMenu="additionalMenu"
            :debug="false"
            height="41px"
            aria-expanded="false"
            data-toggle="dropdown"></search-filter>
      </div>
      <new-card class="new-card-section"></new-card>

    </div>
    <div>
      <div v-if="!isDisplayBoardUser" class="dashboard-page">
        <div class="line-divider-dashboard"></div>
        <div class="white-bg row dashboard-header-border">
          <div class="col-12 col-md-12 col-lg-3 p-0">
            <div class="card">
              <div class="dashboard-header pl-1 d-flex justify-content-between">
                <strong>My To Do List</strong>
                <div>
                  <i class='bx bx-dots-horizontal-rounded'></i>
                </div>
              </div>
              <div class="card-block dashboard-body dashboard-body-additional-block today-block">
                <tabs :options="{ useUrlFragment: false, isOrdered:true }" @changed="changedTab" ref="dashboard-tasks-tabs">
                  <tab name="Tasks" id="dashboard-tasks-tab-tasks" :suffix="tasksSuffix">
                    <div v-if="(tasks && tasks.length > 0)">
                      <div v-if="computedTasks[navItem.key].length" v-for="navItem in tasksNav" class="">
                        <div class="card-navigation d-flex justify-content-between">
                          <div @click="navItem.display = !navItem.display" :class="{'active': !navItem.display}">
                            <i class='bx bxs-chevron-down' style="position: relative; top: 4px"></i> {{ navItem.label }}
                          </div>
                          <div v-if="navItem.key == firstNotEmptyNav.tasks">
                            <i @click="isHideReadTasks = !isHideReadTasks" class='bx bx-task'></i>
                            <i @click="isSortTask = !isSortTask" class='bx bx-transfer'></i>
                          </div>
                        </div>
                        <task-summary v-for="(task, ti) in computedTasks[navItem.key]" v-bind:key="ti" :task="task" v-show="!(task.isViewed && isHideReadTasks) && navItem.display"></task-summary>
                      </div>
                    </div>
                    <div class="no-notices-block" v-else>
                      No tasks
                    </div>
                  </tab>
                  <tab name="Comments" id="dashboard-tasks-tab-comments" :suffix="commentSuffix">
                    <div v-if="(comments && comments.length > 0)">
                      <div v-if="computedComments[navItem.key].length" v-for="navItem in commentsNav" class="">
                        <div class="card-navigation d-flex justify-content-between">
                          <div @click="navItem.display = !navItem.display" :class="{'active': !navItem.display}">
                            <i class='bx bxs-chevron-down' style="position: relative; top: 4px"></i> {{ navItem.label }}
                          </div>
                          <div v-if="navItem.key == firstNotEmptyNav.comments">
                            <i @click="isHideReadComments = !isHideReadComments" class='bx bx-task'></i>
                            <i @click="isSortComment = !isSortComment" class='bx bx-transfer'></i>
                          </div>
                        </div>
                        <comment-summary v-for="(comment, ci) in computedComments[navItem.key]" v-bind:key="ci" :comment="comment" v-show="!(comment.isViewed && isHideReadComments) && navItem.display"></comment-summary>
                      </div>
                    </div>
                    <div class="no-notices-block" v-else>
                      No comments
                    </div>
                  </tab>
                  <tab name="Files" id="dashboard-tasks-tab-cards" :suffix="cardsSuffix">
                    <div v-if="(getterCards && getterCards.length > 0)">
                      <div v-if="computedCards[navItem.key].length" v-for="navItem in cardsNav" class="">
                        <div class="card-navigation d-flex justify-content-between">
                          <div @click="navItem.display = !navItem.display" :class="{'active': !navItem.display}">
                            <i class='bx bxs-chevron-down' style="position: relative; top: 4px"></i> {{ navItem.label }}
                          </div>
                          <div v-if="navItem.key == firstNotEmptyNav.cards">
                            <i @click="isHideReadCards = !isHideReadCards" class='bx bx-card'></i>
                            <i @click="isSortCard = !isSortCard" class='bx bx-transfer'></i>
                          </div>
                        </div>
                        <cards-summary v-for="(card, ci) in computedCards[navItem.key]" :key="ci" :card="card" v-show="!(card.isViewed && isHideReadCards) && navItem.display"></cards-summary>
                      </div>
                    </div>
                    <div v-else class="no-notices-block">
                      No files
                    </div>
                  </tab>
                  <template slot="nav-item-right">
                    <div class="tabs-nav-right-search" style="margin-bottom: 0px !important">
                      <b-form-input v-model="filter"
                                    type="text"
                                    class="search-input"
                                    placeholder="Search..."
                                    debounce="500"/>
                    </div>
                  </template>
                </tabs>
              </div>
            </div>
          </div>
          <div v-if="isShowEmails" class="col-12 col-md-12 col-lg-3 p-0">
            <div class="card">
              <div class="dashboard-header pl-1 d-flex justify-content-between">
                <strong>Messages</strong>
                <div>
                  <i class='bx bx-dots-horizontal-rounded'></i>
                </div>
              </div>
              <div class="card-block emails-block dashboard-body dashboard-body-additional-block message-block">
                <tabs :options="{ useUrlFragment: false, isOrdered:true }"  ref="dashboard-tasks-tabs">
                  <tab name="Emails" id="dashboard-tasks-tab-emails" :suffix="emailsSuffix">
                    <div v-if="(emails && emails.length > 0)" class="">
                      <div v-if="computedEmails[navItem.key].length" v-for="navItem in emailsNav" class="">
                        <div class="card-navigation d-flex justify-content-between">
                          <div @click="navItem.display = !navItem.display" :class="{'active': !navItem.display}">
                            <i class='bx bxs-chevron-down' style="position: relative; top: 4px"></i> {{ navItem.label }}
                          </div>
                          <div v-if="navItem.key == firstNotEmptyNav.emails">
                            <i @click="isHideReadEmails = !isHideReadEmails" class='bx bx-envelope'></i>
                            <i @click="isSortEmail = !isSortEmail" class='bx bx-transfer'></i>
                          </div>
                        </div>

                        <email-summary v-for="(email, ei) in computedEmails[navItem.key]" v-bind:key="ei" :email="email" v-show="!(email.isViewed && isHideReadEmails) && navItem.display" />
                      </div>
                    </div>
                    <div v-else class="empty-default">
                      <div>
                        <div class="no-notices-block mb-3">
                          No emails
                        </div>
                      </div>
                    </div>
                  </tab>
                  <tab name="SMS" id="dashboard-tasks-tab-sms"  :suffix="smsSuffix">
                    <div v-if="(sms && sms.length > 0)" class="">
                      <div v-if="computedSMS[navItem.key].length" v-for="navItem in smsNav" class="">
                        <div class="card-navigation d-flex justify-content-between">
                          <div @click="navItem.display = !navItem.display" :class="{'active': !navItem.display}">
                            <i class='bx bxs-chevron-down' style="position: relative; top: 4px"></i> {{ navItem.label }}
                          </div>
                          <div v-if="navItem.key == firstNotEmptyNav.sms">
                            <i @click="isHideReadSMS = !isHideReadSMS" class='bx bx-mobile-alt'></i>
                            <i @click="isSortSMS = !isSortSMS" class='bx bx-transfer'></i>
                          </div>
                        </div>

                        <sms-summary v-for="(message, si) in computedSMS[navItem.key]" v-bind:key="si" :sms="message" v-show="!(message.isViewed && isHideReadEmails) && navItem.display" />
                      </div>
                    </div>
                    <div v-else class="empty-default">
                      <div>
                        <div class="mb-3 no-notices-block">
                          No SMS
                        </div>
                      </div>
                    </div>
                  </tab>
                  <tab name="Insurer" :suffix="insurerSuffix">
                    <div class="" v-if="(insurers && insurers.length > 0)">
                      <div v-if="computedInsurers[navItem.key].length" v-for="navItem in insurersNav" class="">
                        <div class="card-navigation d-flex justify-content-between">
                          <div @click="navItem.display = !navItem.display" :class="{'active': !navItem.display}">
                            <i class='bx bxs-chevron-down' style="position: relative; top: 4px"></i> {{ navItem.label }}
                          </div>
                          <div v-if="navItem.key == firstNotEmptyNav.insurers">
                            <i @click="isHideReadInsurers = !isHideReadInsurers" class='bx bx-detail'></i>
                            <i @click="isSortInsurers = !isSortInsurers" class='bx bx-transfer'></i>
                          </div>
                        </div>
                        <insurer-summary v-for="(insurer, index) in computedInsurers[navItem.key]" v-bind:key="index" :insurer="insurer" v-show="!(insurer.isViewed && isHideReadInsurers) && navItem.display"></insurer-summary>
                      </div>
                    </div>
                    <div class="empty-default">
                      <div>
                        <div v-if="false" class="mb-3 no-notices-block">
                          No Insurer
                        </div>
                      </div>
                    </div>
                  </tab>
                  <template slot="nav-item-right">
                    <div class="tabs-nav-right-search" style="margin-bottom: 0px !important">
                      <b-form-input v-model="filterEmail"
                                    type="text"
                                    class="search-input"
                                    placeholder="Search..."
                                    debounce="500"/>
                    </div>
                  </template>
                </tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NewCard from './new-card';
  import {mapGetters} from "vuex";
  import TaskSummary from './tasks/task-summary';
  import CommentSummary from './tasks/comment-summary';
  import EmailSummary from './tasks/email-summary';
  import CardsSummary from './tasks/cards-summary';
  import _ from "lodash" ;
  import {VueContext} from 'vue-context';
  import {isMobile, isMobileOnly} from '../../deviceDetect/index';
  import SmsSummary from "@/components/dashboard/tasks/sms-summary";
  import SearchFilter from '../search-filter/search-filter-2'
  import InsurerSummary from "@/components/dashboard/tasks/insurer-summary.vue";
  import dayjs from "dayjs";

  export default {
    name: 'dashboard',
    components: {
      InsurerSummary,
      SmsSummary,
      NewCard,
      TaskSummary,
      CommentSummary,
      EmailSummary,
      CardsSummary,
      VueContext,
      SearchFilter
    },
    data() {
      return {
        filter: null,
        filterEmail: null,
        activeTab: '',
        searchFilter: '',
        isMobile,
        isMobileOnly,

        isHideReadCards: false,
        isSortCard: false,
        isHideReadComments: false,
        isSortComment: false,
        isHideReadTasks: false,
        isSortTask: false,
        isHideReadEmails: false,
        isSortEmail: false,
        isHideReadSMS: false,
        isSortSMS: false,
        isSortInsurers: false,
        isHideReadInsurers: false,

        tasksNav: [
          {
            label: 'Today',
            key: 'today',
            display: true,
          },
          {
            label: 'Yesterday',
            key: 'yesterday',
            display: true,
          },
          {
            label: 'This week',
            key: 'thisWeek',
            display: true,
          },
          {
            label: 'Last week',
            key: 'lastWeek',
            display: true,
          },
          {
            label: 'This month',
            key: 'thisMonth',
            display: true,
          },
          {
            label: 'Last month',
            key: 'lastMonth',
            display: true,
          },
          {
            label: 'Older',
            key: 'older',
            display: true,
          },
        ],
        commentsNav: [
          {
            label: 'Today',
            key: 'today',
            display: true,
          },
          {
            label: 'Yesterday',
            key: 'yesterday',
            display: true,
          },
          {
            label: 'This week',
            key: 'thisWeek',
            display: true,
          },
          {
            label: 'Last week',
            key: 'lastWeek',
            display: true,
          },
          {
            label: 'This month',
            key: 'thisMonth',
            display: true,
          },
          {
            label: 'Last month',
            key: 'lastMonth',
            display: true,
          },
          {
            label: 'Older',
            key: 'older',
            display: true,
          },
        ],
        cardsNav: [
          {
            label: 'Today',
            key: 'today',
            display: true,
          },
          {
            label: 'Yesterday',
            key: 'yesterday',
            display: true,
          },
          {
            label: 'This week',
            key: 'thisWeek',
            display: true,
          },
          {
            label: 'Last week',
            key: 'lastWeek',
            display: true,
          },
          {
            label: 'This month',
            key: 'thisMonth',
            display: true,
          },
          {
            label: 'Last month',
            key: 'lastMonth',
            display: true,
          },
          {
            label: 'Older',
            key: 'older',
            display: true,
          },
        ],
        emailsNav: [
          {
            label: 'Today',
            key: 'today',
            display: true,
          },
          {
            label: 'Yesterday',
            key: 'yesterday',
            display: true,
          },
          {
            label: 'This week',
            key: 'thisWeek',
            display: true,
          },
          {
            label: 'Last week',
            key: 'lastWeek',
            display: true,
          },
          {
            label: 'This month',
            key: 'thisMonth',
            display: true,
          },
          {
            label: 'Last month',
            key: 'lastMonth',
            display: true,
          },
          {
            label: 'Older',
            key: 'older',
            display: true,
          },
        ],
        smsNav: [
          {
            label: 'Today',
            key: 'today',
            display: true,
          },
          {
            label: 'Yesterday',
            key: 'yesterday',
            display: true,
          },
          {
            label: 'This week',
            key: 'thisWeek',
            display: true,
          },
          {
            label: 'Last week',
            key: 'lastWeek',
            display: true,
          },
          {
            label: 'This month',
            key: 'thisMonth',
            display: true,
          },
          {
            label: 'Last month',
            key: 'lastMonth',
            display: true,
          },
          {
            label: 'Older',
            key: 'older',
            display: true,
          },
        ],
        insurersNav: [
          {
            label: 'Today',
            key: 'today',
            display: true,
          },
          {
            label: 'Yesterday',
            key: 'yesterday',
            display: true,
          },
          {
            label: 'This week',
            key: 'thisWeek',
            display: true,
          },
          {
            label: 'Last week',
            key: 'lastWeek',
            display: true,
          },
          {
            label: 'This month',
            key: 'thisMonth',
            display: true,
          },
          {
            label: 'Last month',
            key: 'lastMonth',
            display: true,
          },
          {
            label: 'Older',
            key: 'older',
            display: true,
          },
        ],
      }
    },
    methods: {
      getNavObject(array, dateField, filters, filter, sortField) {
        let result = {
          today: [],
          yesterday: [],
          thisWeek: [],
          lastWeek: [],
          thisMonth: [],
          lastMonth: [],
          older: [],
        }

        let items = _.orderBy(array, [(itm) => new Date(itm[dateField]).getTime()], 'desc')
        _.forEach(items, (email) => {
          if (filter) {
            let isShow = _.some(filters, (f) => {
              if (_.isArray(f)) {
                return this.isFilteredElement(email[f[0]][f[1]], filter)
              } else {
                return this.isFilteredElement(email[f], filter)
              }
            })
            if (!isShow) {
              return
            }
          }
          let date = dayjs(email[dateField])
          let monday = dayjs().isoWeekday(1).format('YYYY-MM-DD')
          let sunday = dayjs().isoWeekday(7).format('YYYY-MM-DD')
          let lastMonday = dayjs().isoWeekday(-6).format('YYYY-MM-DD')
          let lastSunday = dayjs().isoWeekday(0).format('YYYY-MM-DD')
          if (dayjs(date).format('YYYY-MM-DD') == dayjs(new Date()).format('YYYY-MM-DD')) {
            result.today.push(email)
          } else if (dayjs(date).format('YYYY-MM-DD') == dayjs(new Date(new Date().setDate(new Date().getDate()-1))).format('YYYY-MM-DD')) {
            result.yesterday.push(email)
          } else if (dayjs(date).isBetween( dayjs(monday), dayjs(sunday), null, '[]')) {
            result.thisWeek.push(email)
          } else if (dayjs(date).isBetween( dayjs(lastMonday), dayjs(lastSunday), null, '[]')) {
            result.lastWeek.push(email)
          } else if (dayjs(date).month() == new Date().getMonth() && dayjs(date).year() == new Date().getFullYear()) {
            result.thisMonth.push(email)
          } else if (dayjs(date).month() == new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth() && dayjs(date).year() == new Date(new Date().setMonth(new Date().getMonth() - 1)).getFullYear()) {
            result.lastMonth.push(email)
          } else {
            result.older.push(email)
          }
        })

        if (this[sortField]) {
          result.today = _.orderBy(result.today, [(itm) => new Date(itm[dateField]).getTime()], 'asc')
          result.yesterday = _.orderBy(result.yesterday, [(itm) => new Date(itm[dateField]).getTime()], 'asc')
          result.thisWeek = _.orderBy(result.thisWeek, [(itm) => new Date(itm[dateField]).getTime()], 'asc')
          result.lastWeek = _.orderBy(result.lastWeek, [(itm) => new Date(itm[dateField]).getTime()], 'asc')
          result.thisMonth = _.orderBy(result.thisMonth, [(itm) => new Date(itm[dateField]).getTime()], 'asc')
          result.lastMonth = _.orderBy(result.lastMonth, [(itm) => new Date(itm[dateField]).getTime()], 'asc')
          result.older = _.orderBy(result.older, [(itm) => new Date(itm[dateField]).getTime()], 'asc')
        } else {
          result.today = _.orderBy(result.today, [(itm) => new Date(itm[dateField]).getTime()], 'desc')
          result.yesterday = _.orderBy(result.yesterday, [(itm) => new Date(itm[dateField]).getTime()], 'desc')
          result.thisWeek = _.orderBy(result.thisWeek, [(itm) => new Date(itm[dateField]).getTime()], 'desc')
          result.lastWeek = _.orderBy(result.lastWeek, [(itm) => new Date(itm[dateField]).getTime()], 'desc')
          result.thisMonth = _.orderBy(result.thisMonth, [(itm) => new Date(itm[dateField]).getTime()], 'desc')
          result.lastMonth = _.orderBy(result.lastMonth, [(itm) => new Date(itm[dateField]).getTime()], 'desc')
          result.older = _.orderBy(result.older, [(itm) => new Date(itm[dateField]).getTime()], 'desc')
        }

        return result
      },
      redirectToCardEdit(itm) {
        this.$router.push('/files/view/' + itm.value)
      },
      onEnter() {
        if (!_.isEmpty(this.additionalMenu) && !_.isEmpty(this.searchFilter)) {
          if (this.additionalMenu.length == 1) {
            this.redirectToCardEdit(this.additionalMenu[0])
          } else {
            this.$store.commit('setCardFilter', this.searchFilter);
            this.$router.push('/files')
          }
        }
      },
      isFilteredElement(filterText, query) {
        if (!query) {
          return true;
        }
        if (filterText && filterText != '') {
          return (new RegExp(query, 'iug').test(filterText));
        }
        return false;
      },
      changedTab(tab) {
        this.activeTab = tab.tab;
      },
    },
    computed: {
      ...mapGetters({
        isDisplayBoardUser: 'isDisplayBoardUser',
        isShopManagerUser: 'isShopManagerUser',
        isAdministrator: 'isAdministrator',
        isCustomerService: 'isCustomerUser',
        userInfo: 'userInfo',

        emails: 'dashboard/getEmails',
        sms: 'dashboard/getSms',
        insurers: 'dashboard/getInsurers',
        tasks: 'dashboard/getTasks',
        comments: 'dashboard/getComments',
        getterCards: 'card/getCardsAssignedToMe',
        cardsAll: 'card/getAllCards',
      }),
      firstNotEmptyNav() {
        return {
          tasks: _.findKey(this.computedTasks, (e) => {return !_.isEmpty(e)}),
          comments: _.findKey(this.computedComments, (e) => {return !_.isEmpty(e)}),
          cards: _.findKey(this.computedCards, (e) => {return !_.isEmpty(e)}),
          emails: _.findKey(this.computedEmails, (e) => {return !_.isEmpty(e)}),
          sms: _.findKey(this.computedSMS, (e) => {return !_.isEmpty(e)}),
          insurers: _.findKey(this.computedInsurers, (e) => {return !_.isEmpty(e)}),
        }
      },
      additionalMenu() {
        if (_.isEmpty(this.searchFilter)) {
          return []
        }
        let data = _.filter(this.cardsAll, (r) => {
          return _.startsWith(_.trim(_.toLower(r.card_number)), _.trim(_.toLower(this.searchFilter))) ||
              _.startsWith(_.trim(_.toLower(r.rego_number)), _.trim(_.toLower(this.searchFilter))) ||
              _.includes(_.trim(_.toLower(r.customer_name)), _.trim(_.toLower(this.searchFilter)))
        })
        let result = [];
        _.forEach(data, (itm) => {
          let label = itm.card_number
          if (itm.rego_number) {
            label +=  ' - '
          }

          if (itm.rego_number) {
            label += itm.rego_number + ' '
          }

          result.push({
            value: itm.card_id,
            label: label,
            estimateNumber: itm.card_number,
            regoNumber: itm.rego_number,
            customerName: itm.customer_name,
            insurerName: itm.insurance,
            make: itm.make,
            model: itm.model,
            buildDate: itm.dom,
          })
        })
        result = _.orderBy(result, ['label'], ['asc']).slice(0, 10)
        return result
      },
      smsSuffix() {
        let notViewedSMS = _.filter(this.sms, (s) => {
          return !s.isViewed
        });
        if (notViewedSMS.length) {
          return '<span class="tag tag-info">' + notViewedSMS.length + '</span>'
        }
        return  ''
      },
      insurerSuffix() {
        let notViewedInsurers = _.filter(this.insurers, (s) => {
          return !s.isViewed
        });
        if (notViewedInsurers.length) {
          return '<span class="tag tag-info">' + notViewedInsurers.length + '</span>'
        }
        return  ''
      },
      commentSuffix() {
        let notViewedComments = _.filter(this.comments, (s) => {
          return !s.isViewed
        });
        if (notViewedComments.length) {
          return '<span class="tag tag-info">' + notViewedComments.length + '</span>'
        }
        return  ''
      },
      tasksSuffix() {
        let notViewedTasks = _.filter(this.tasks, (s) => {
          return !s.isViewed
        });
        if (notViewedTasks.length) {
          return '<span class="tag tag-info">' + notViewedTasks.length + '</span>'
        }
        return  ''
      },
      emailsSuffix() {
        let notViewedEmails = _.filter(this.emails, (s) => {
          return !s.isViewed
        });
        if (notViewedEmails.length) {
          return '<span class="tag tag-info">' + notViewedEmails.length + '</span>'
        }
        return  ''
      },
      cardsSuffix() {
        let notViewedCards = _.filter(this.getterCards, (s) => {
          return !s.isViewed
        });
        if (notViewedCards.length) {
          return '<span class="tag tag-info">' + notViewedCards.length + '</span>'
        }
        return  ''
      },
      isShowEmails() {
        if (_.isEmpty(this.userInfo)) {
          return true
        } else {
          return this.isShopManagerUser || this.isAdministrator || this.isCustomerService;
        }
      },
      computedTasks() {
        return this.getNavObject(this.tasks, 'createdAt', ['notesText', 'name', 'cardNumber', 'estimateNumber', 'createdById'], this.filter, 'isSortTask')
      },
      computedComments() {
        return this.getNavObject(this.comments, 'createdOn', ['text', ['task', 'name'], ['task', 'cardNumber',], ['task', 'estimateNumber',], 'createdById'], this.filter, 'isSortComment')
      },
      computedCards() {
        return this.getNavObject(this.getterCards, 'sortCreatedDate', ['card_number', 'customer_name', 'rego_number', 'make', 'model', 'colour', 'insurance', 'created_on', 'priority', 'status_name', 'claim_number', 'filters', 'partNumber', 'driverName', ['assignedTo', 'name'],], this.filter, 'isSortCard')
      },
      computedEmails() {
        return this.getNavObject(this.emails, 'createdOn', ['from', 'recipient', 'subject'], this.filterEmail, 'isSortEmail')
      },
      computedInsurers() {
        return this.getNavObject(this.insurers, 'date', ['insurerName', 'estimateNumber', 'message'], this.filterEmail, 'isSortInsurers')
      },
      computedSMS() {
        return this.getNavObject(this.sms, 'smsCreatedOn', ['smsFrom', 'smsTo', 'smsText'], this.filterEmail, 'isSortSMS')
      },
    },
    created() {
      if (this.isDisplayBoardUser){
        this.$router.push('/boards/vehicle-in')
      }
    },
  }
</script>

<style>
  .dashboard-btns .search-filter .custom-dropdown {
    width: auto !important;
    min-width: 128px !important;
  }
  .dashboard-btns .search-filter .custom-dropdown.additional-menu-search-filter{
    width: 350px !important;
    left: -45px;
  }
  @media screen and (max-width: 768px) {
    .dashboard-btns .search-filter .custom-dropdown.additional-menu-search-filter {
      left: -45px;
      max-height: 500px;
    }
  }
  @media screen and (max-width: 400px) {
    .dashboard-btns .search-filter .custom-dropdown.additional-menu-search-filter {
      left: -45px;
      max-height: 350px;
      width: 300px !important;
    }
  }
  @media (max-width: 1100px) {
    .dashboard-btns {
      flex-direction: column;
    }
    .dashboard-btns .new-card-section {
      align-items: center;
      margin: 0 auto;
    }

    .dashboard-btns .new-card-section .add-new-card {
      margin-right: 0px !important;
      margin-top: 20px !important;
      margin-bottom: 0 !important;
    }

    .dashboard-btns .new-card-section .workflow-btn {
      margin-top: 20px !important;
      margin-bottom: 0 !important;
    }

    .dashboard-btns .new-card-section .new-tasks-button {
      margin-top: 20px !important;
      margin-bottom: 0 !important;
      margin-left: 20px !important;
    }
  }
</style>

<style scoped>
    .white-bg{
      background-color: #e6e6e9;
      padding: 15px;
      height: calc(100vh - 210px);
    }
    .dashboard-btns .search-filter >>> .search-filter__box{
      border: 3px solid #5e77fd;
      border-radius: 7px;
    }
    .dashboard-btns .search-filter >>> .search-filter__input{
      font-size: 13px !important;
      font-weight: 800;
      color: black;
      text-transform: uppercase;
    }
    .dashboard-btns .search-filter >>> .search-filter__input::placeholder{
      color: #5e77fc;
    }
    .card-navigation{
      font-size: 14px;
      font-weight: 800;
      padding: 0 10px;
      color: #6079fc;
    }
    .card-navigation .bx{
      font-size: 22px;
    }
    .card-navigation .bx-transfer{
      transform: rotate(90deg);
      margin-left: 15px;
    }
    .card-navigation div.active .bxs-chevron-down{
      transform: rotate(180deg);
    }
    .card{
      border: 1px solid rgba(28, 31, 57, 0.25);
      margin: 0 10px;
      border-radius: 7px;
      padding: 10px 5px;
    }
    .card-block >>> .tab-content{
      overflow-y: auto;
      scrollbar-width: thin;
      max-height: calc(100vh - 450px);
    }
    .dashboard-btns{
      padding: 15px;
    }
    .dashboard-body-additional-block >>> .tabs-component {
      border: 0;
    }
    .V3 .card-block{
      padding: 0;
    }
    .dashboard-header{
      font-size: 15px;
    }
    .dashboard-header .bx-dots-horizontal-rounded{
      font-size: 24px;
      position: relative;
      right: 10px;
      cursor: pointer;
    }
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__input{
      font-size: 13px !important;
      font-weight: 800;
      color: black;
    }
    .tabs-nav-right-search .search-filter >>> .custom-dropdown{
      top: 38px;
      left: -85px;
      padding-top: 2px;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__box{
      border: 3px solid #5e77fd;
      border-radius: 7px;
    }
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .search-filter >>> input::placeholder{
      color: #bbbbc2;
    }
    .search-filter >>> .search-filter__box__focused{
      border: 3px solid rgba(94, 121, 255, 0.5) !important;
      box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
    }
    .search-filter >>> .search-filter__li--input{
      border-bottom: 0;
    }
    .search-filter >>> .search-filter__li{
      border-bottom: 0 !important;
    }
    .tabs-nav-right-search-font-weight >>> input{
      font-weight: 700;
    }
    .tabs-nav-right-search-font-weight >>> input::placeholder{
      font-weight: 600;
    }

    .no-notices-block {
      text-align: center;
      margin-top: 15px;
    }
    .d-flex {
      display: flex;
    }
    .flex-row {
      flex-direction: row;
    }
    .flex-column {
      flex-direction: column;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .work-center-main {
      font-family: "Nunito Sans";
      font-size: 13px;
      color: #1C1F39;
      background-color: #fff;
      padding: 15px 0;
    }
    .right-block {
      display: flex;
      justify-content: flex-start;
      /* flex-direction: row; */
      flex-flow: row wrap;
      width: 100%;
    }
</style>

<style scoped>
    .emails-block:hover {
      cursor: pointer;
    }
    .uppercase {
      text-transform: uppercase;
    }

    .dashboard-col {
      padding: 0;
    }
    @media (max-width: 1300px) {
      .white-bg{
        height: auto;
      }
      .col-lg-3{
        width: 100%;
      }
      .V3 .card{
        margin-bottom: 15px;
      }
    }
    @media (min-width: 576px) {
      .dashboard-col:first-child {
        margin-left: 15px;
      }

      .dashboard-col:last-child {
        margin-right: 15px;
      }
    }

    @media (max-width: 575.98px) {
      .dashboard-col ::v-deep .tab-header.rs-btn-group {
        /* display: none; */
      }
    }
    .dashboard-body-additional-block >>> ul .tabs-component-tab{
      border-bottom: 5px solid #e1e2e5 !important;
    }
    .today-block >>> ul .tabs-component-tab{
      width: 33%;
    }
    .message-block >>> ul .tabs-component-tab{
      width: 33%;
    }
    .notices-block >>> ul .tabs-component-tab{
      width: 33%;
    }
    .dashboard-col .dashboard-body {
      padding-left: 15px;
      padding-right: 15px;
      background-color: rgba(241, 241, 247, 0.618);
      min-height: 400px;
      background-color: rgba(27, 30, 56, 0.1) !important;
    }

    .dashboard-col ::v-deep .tabs-component {
      position: inherit;
      margin-bottom: 0.75rem;
    }

    .dashboard-col ::v-deep .tabs-component .tab-header {
      height: 50px;
      flex-flow: row nowrap;
    }

    .dashboard-col ::v-deep .nav-tabs-2 .nav-link {
      height: 39px;
    }

    .dashboard-col ::v-deep .dashboard-row-element {
      background-color: #FFFFFF;
      /* background-color: #efefee; */ /* for muted */
    }

    .dashboard-col .dashboard-body ::v-deep .tab-content {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .empty-default {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }

    /* Dashboard Search Input Style */
    .dashboard-body ::v-deep .tabs-component {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .dashboard-body ::v-deep .tabs-component::after {
      content: unset;
    }

    .dashboard-body ::v-deep .tabs-component .nav-tabs {
      /* flex: 0 0 auto; */
      /* background-color: yellow; */
    }

    .dashboard-body ::v-deep div.tab-header.rs-btn-group {
      flex: 1 1 290px;
    }

    .dashboard-body ::v-deep div.tabs-nav-right-search {
      min-width: unset !important;
      width: 100%;
    }

    .dashboard-body ::v-deep div.tabs-nav-right-search {
      height: 50px;
      display: flex;
      align-items: center;
    }
    /* End - Dashboard Search Input Style */

    @media (max-width: 991px) {
      .card {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .noLink {
      pointer-events: none;
    }

    .btn-border-theme {
      border: 1px solid #5E79FF;
    }

    .btn-border-theme:hover {
      border: 1px solid #5E79FF;
      background-color: #fff;
    }
</style>

<style>
    .show-count-dropdown .btn {
      border: 1px solid #00B050 !important;
      background-color: #FFFFFF !important;
      color: #333333 !important;
    }

    .dashboard-header {
      padding-bottom: 5px;
    }

    .dashboard-body {
      padding-left: 15px;
      padding-right: 15px;
      min-height: 400px;
    }

    .view-tasks-block {
      align-self: center;
    }
    @media (max-width: 991px) {
      .padding-md-block {
        padding-left: 1rem;
      }
    }
    .dashboard-body-additional-block .dropdown-options,
    .dashboard-body-additional-block .ex-options-button {
      width: 120px !important;
    }

    .dashboard-body-additional-block .tabs-component ul {
      display: flex;
      flex-wrap: wrap;
    }

    .dashboard-body-additional-block .tabs-component {
      margin-top: 10px;
      background-color: white;
      padding: 0 !important;
    }

    .dashboard-body-additional-block .tabs-component::after {
      display: none;
    }

    .dashboard-body-additional-block .tab-header {
      width: 100%;
    }

    .dashboard-body-additional-block .tabs-nav-right-search {
      width: 100%;
      margin: 15px 0 !important;
    }

    .dashboard-body-additional-block .tab-content {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .line-divider-dashboard {
      width: 100%;
      height: 10px;
      position: relative;
      background: #d7d7d7;
      left: 0px;
    }
</style>
