<template>
  <div class="">
    <div style="margin-top: -15px"  class="billing">
      <div class="">
        <div class="tabs-component tabs-component-2" style="padding-top: 0px; margin-bottom: 15px">
          <div class="tabs-component-w">
            <ul class="nav nav-tabs nav-tabs-2  float-sm-left">
            <li style='padding-top: 0px' @click="selectedTab('settings')" class="tabs-component-tab nav-item">
              <span href="" class="tabs-component-tab-a nav-link" :class="{'active': currentTab === 'settings'}">Settings</span>
              </li>
            <li @click="selectedTab('invoices')" class="tabs-component-tab nav-item">
              <span href="" class="tabs-component-tab-a nav-link" :class="{'active': currentTab === 'invoices'}">Invoices</span>
              </li>
            </ul>
          </div>
        </div>
      <div v-if="currentTab === 'settings'" class="">
          <div class="col-lg-5 col-12">
            <div class="card-header bg-navyblue">
              <strong>Plan</strong>
            </div>
            <div class="card-block bg-white">

              <div class="form-group row no-gutter rs-toggle">
                <label class="col-sm-3 col-form-label  left-col"
                       for="status">
                  Status
                </label>
                <div class="col-sm-9 right-col  col-with-active-btn">
                  <div class="d-flex">
                    <toggle-button
                        :disabled="true"
                        v-model="vendorInfo.isActive"
                        id="status"
                        class="mb-0 rs-toggle__button"
                        color="#5E79FF"
                        style="opacity: 1"
                        :sync="true"
                        :width="40"
                        :labels="false"/>
                    <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                <template v-if="isStatusActive">Active</template>
                                <template  v-else>Not Active</template>
                            </span>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutter">
                <label style="margin-top: 22px" class="col-sm-3 col-form-label left-col"
                       for="plan">
                  Plan Type
                </label>
                <div class="col-sm-9 right-col col-with-active-btn">
                  <multiselect v-model="computedPlanType"
                               :options="value.billing.availablePlans.plans"
                               :showLabels="false"
                               label="name"
                               @select="onSelectPlanType"
                               track-by="id"
                               placeholder="Select Plan Type"
                               :disabled="!isStatusActive"
                               :close-on-select="true"
                               style="z-index: 4; opacity: 1; width: 162px;">
                  </multiselect>
                </div>
              </div>
              <div class="form-group row no-gutter">
                <label style="margin-top: 2px" class="col-sm-3 col-form-label  left-col"
                       for="cost">
                  Cost (Per/Month)
                </label>
                <div class="col-sm-9 right-col">
                  <number-formatter ref="rr"
                                    v-model="computedCost"
                                    id="cost"
                                    type="text"
                                    class="form-control w-162"
                                    placeholder=""
                                    format="$00,0.00"
                                    :isDisabled="true"
                  ></number-formatter>
                </div>
              </div>
              <div class="form-group row no-gutter">
                <label style="margin-top: 2px" class="col-sm-3 col-form-label  left-col"
                       for="limit">
                  Data Storage Limit
                </label>
                <div class="col-sm-9 right-col">
                  <input
                      v-model="computedLimit"
                      id="limit"
                      type="text"
                      readonly
                      class="form-control w-162">
                </div>
              </div>
              <div class="form-group row no-gutter">
                <label class="col-sm-3 col-form-label left-col"
                       for="data_storage_consumed">
                  <p>Data Storage Consumed</p>
                  <p class="description-text">Includes Images and Files that
                    <br>
                    are stored as part of your
                    <br>
                    account.
                  </p>
                </label>
                <div class="col-sm-9 right-col">
                  <b-progress height="32px" class="progress-of-gb">
                    <span class="progress-text" :class="[{'progress-text-black': (!isOverLimit && computedProgressTextAlign != 'progress-text-left')}, {'progress-text-white':isOverLimit}, computedProgressTextAlign]">{{this.value.billing.info.storage.consumed}} GB</span>
                    <b-progress-bar :class="[ computedColorForProgressBar ]" class="progress-bar-of-gb"  :max="this.value.billing.info.storage.limit" variant="success" :value="computedStorageConsumedLimit">

                    </b-progress-bar>
                  </b-progress>
                  <p v-if="isOverLimit" style="font-size: 12px">Your account Data storage limit has been reached. Increase it by adding a Data Add-on pack.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-12">
            <div class="card-header bg-navyblue">
              <strong>Add-Ons</strong>
            </div>
            <div class="card-block bg-white" style="padding-right: 0px">

              <div class="form-group row no-gutter rs-toggle">
                <label class="col-sm-3 col-form-label description"
                       for="rego">
                  <p>REGO/VIN LOOKUP</p>
                  <p class="description-text">Charged at $0.48 per lookup (Ex GST).
                    <br>
                    Billed Monthly</p>

                </label>
                <div class="col-sm-9 col-with-active-btn">
                  <div class="d-flex">
                    <toggle-button
                        v-model="value.billing.settings.reg_vin_lookup"
                        id="rego"
                        class="mb-0 rs-toggle__button"
                        color="#5E79FF"
                        :sync="true"
                        :width="40"
                        :disabled="value.billing.settings.reg_vin_lookup_with_build_and_compliance || !isStatusActive"
                        :labels="false"/>
                    <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                  <template v-if="value.billing.settings.reg_vin_lookup">Active</template>
                  <template v-else>Not Active</template>
                </span>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutter rs-toggle">
                <label class="col-sm-3 col-form-label description"
                       for="build_and_compliance">
                  <p>REGO/VIN LOOKUP W/Build & Compliance</p>
                  <p class="description-text">Charged at $0.60 per lookup (Ex GST).
                    <br>
                    Billed Monthly</p>

                </label>
                <div class="col-sm-9 col-with-active-btn">
                  <div class="d-flex">
                    <toggle-button
                        v-model="value.billing.settings.reg_vin_lookup_with_build_and_compliance"
                        id="build_and_compliance"
                        class="mb-0 rs-toggle__button"
                        color="#5E79FF"
                        :sync="true"
                        :width="40"
                        :disabled="value.billing.settings.reg_vin_lookup || !isStatusActive"
                        :labels="false"/>
                    <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                  <template v-if="value.billing.settings.reg_vin_lookup_with_build_and_compliance">Active</template>
                  <template v-else>Not Active</template>
                </span>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutter rs-toggle">
                <label class="col-sm-3 col-form-label description"
                       for="rego_always_on">
                  <p>REGO/VIN LOOKUP - Always On</p>
                  <p class="description-text">Enables always on for every New File
                    <br>
                    or Rego field search. No extra Charge</p>
                </label>
                <div class="col-sm-9 col-with-active-btn">
                  <div class="d-flex">
                    <toggle-button
                        v-model="value.billing.settings.reg_vin_lookup_always_on"
                        @change="changedLookupStatus"
                        id="rego_always_on"
                        class="mb-0 rs-toggle__button"
                        color="#5E79FF"
                        :disabled="!isStatusActive"
                        :sync="true"
                        :width="40"
                        :labels="false"/>
                    <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                  <template v-if="value.billing.settings.reg_vin_lookup_always_on">Active</template>
                  <template v-else>Not Active</template>
                </span>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutter rs-toggle">
                <label class="col-sm-3 col-form-label description"
                       for="sms">
                  <p>SMS Messaging - Outgoing</p>
                  <p class="description-text">Charged at $0.10 per SMS (Ex GST)
                    <br>
                    Billed Monthly</p>
                </label>
                <div class="col-sm-9 col-with-active-btn">
                  <div class="d-flex">
                    <toggle-button
                        v-model="value.billing.settings.sms_messaging"
                        id="sms"
                        class="mb-0 rs-toggle__button"
                        color="#5E79FF"
                        :sync="true"
                        :disabled="!isStatusActive"
                        :width="40"
                        :labels="false"/>
                    <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                  <template v-if="value.billing.settings.sms_messaging">Active</template>
                  <template v-else>Not Active</template>
                </span>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutter rs-toggle">
                <label class="col-sm-3 col-form-label description"
                       for="sms">
                  <p>SMS Messaging - Incoming</p>
                  <p class="description-text">Charged at $0.02 per SMS (Ex GST)
                    <br>
                    Billed Monthly</p>
                </label>
                <div class="col-sm-9 col-with-active-btn">
                  <div class="d-flex">
                    <toggle-button
                        v-model="value.billing.settings.sms_messaging_incoming"
                        id="sms"
                        class="mb-0 rs-toggle__button"
                        color="#5E79FF"
                        :sync="true"
                        :disabled="!isStatusActive"
                        :width="40"
                        :labels="false"/>
                    <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                  <template v-if="value.billing.settings.sms_messaging_incoming">Active</template>
                  <template v-else>Not Active</template>
                </span>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutter">
                <label class="col-sm-3 col-form-label description"
                       for="add_on">
                  <p>Data Storage Add-on</p>
                  <p class="description-text">
                    Charged at {{ this.currentStorageAddonInfo.price | formatMoney }} per {{ this.currentStorageAddonInfo.per }}
                    <br>
                    (Ex GST). Billed {{ this.currentStorageAddonInfo.billed }}
                  </p>
                </label>
                <div class="col-sm-9 col-with-active-btn">
                  <multiselect v-model="computedPlan"
                               :options="value.billing.availablePlans.storages"
                               :showLabels="false"
                               label="name"
                               @select="onSelectPlan"
                               :option-height="31.5"
                               placeholder="Select GB"
                               :disabled="!isStatusActive"
                               :close-on-select="true"
                               style="z-index: 4; opacity: 1;">
                  </multiselect>
                </div>
              </div>
              <div class="form-group row no-gutter rs-toggle">
                <label class="col-sm-3 col-form-label description"
                       for="add_on_status">
                  <p>Data Storage Add-on - As Needed</p>
                  <p class="description-text">Automatically adds additional <br/> storage on demand once you limit <br/> is reached.</p>
                </label>
                <div class="col-sm-9 col-with-active-btn">
                  <div class="d-flex">
                    <toggle-button
                        v-model="value.billing.settings.storage_addon_as_needed"
                        id="add_on_status"
                        class="mb-0 rs-toggle__button"
                        color="#5E79FF"
                        :disabled="!isStatusActive"
                        :sync="true"
                        :width="40"
                        :labels="false"/>
                    <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                  <template v-if="value.billing.settings.storage_addon_as_needed">Active</template>
                  <template v-else>Not Active</template>
                </span>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutter rs-toggle">
                <label class="col-sm-3 col-form-label description"
                  for="add_on_status">
                  <p>Close your Repair-shop account.</p>
                  <p class="description-text" style="max-width: 200px">All accounts users and services will no longer be accessible.</p>
                </label>
                <div class="col-sm-9 col-with-active-btn">
                  <button @click="clickCloseAccount()" style="background-color: #5E79FF; color: white; width: 130px" class="btn btn-primary waves-effect waves-light">Close Account</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div v-if="currentTab === 'invoices'" class="rs-scroll">
        <invoices></invoices>
        </div>
      </div>
    </div>
    <modal-wizard class="billing-modal" ref="actionСonfirmation" cancelButtonText="No" finishButtonText="Yes" style="width: 100px"
                  @on-complete="completedChangingSubscriptionConfirmation" @on-close="completedChangingSubscriptionDecline">
      <modal-wizard-tab title="Changing Data Storage Add-on" description="" title-icon-class="ti-align-right">
        <template>
          <div>You are about to change/add a Data storage add-on pack which will be added to your monthly bill.</div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
    <modal-wizard class="billing-modal" ref="planTypeModal" cancelButtonText="No" finishButtonText="Yes" style="width: 100px"
                  @on-complete="completedPlanTypeModal" @on-close="canceledPlanTypeModal">
      <modal-wizard-tab title="Changing Plan Type" description="" title-icon-class="ti-align-right">
        <template>
          <div>Are you sure?</div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
    <modal-wizard class="billing-modal" ref="closeAccount" cancelButtonText="Cancel" finishButtonText="Confirm" style="width: 100px"
                  @on-complete="closeAccount">
      <modal-wizard-tab title="Close Account" description="" title-icon-class="ti-align-right">
        <template>
          <div>You are about to close this account, user accounts and services will no longer be available.</div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
  </div>
</template>

<script>
  import NumberFormatter from '../utility/number-formatter';
  import {mapGetters} from "vuex";
  import Multiselect from "vue-multiselect";
  import _ from "lodash";
import Axios from "axios";
import {appConfig} from "../../config";
import Invoices from './billing/invoices';

  export default {
    name: "Billing",
    components: {
      NumberFormatter,
      Multiselect,
      Invoices,
    },
    data() {
      return {
        cost: '0',
        showSettings: true,
        showInvoices: false,
        currentTab: 'Settings',
        currentPlan: null,
        planToRemove: false,
        newPlanType: null,
      }
    },
    mounted() {
      if (!_.isEmpty(this.$storage.get("views.advancedSettings.billing.defaultView"))) {
        this.currentTab = this.$storage.get("views.advancedSettings.billing.defaultView");
      } else {
        let path = this.$route.path.split('/');
        let subTab = path[path.length-1];
        if (subTab === 'settings' || subTab === 'invoices') {
          this.currentTab = subTab;
        } else {
          this.currentTab = 'settings';
        }
      }
    },
    methods: {
      canceledPlanTypeModal() {
        this.newPlanType = null;
      },
      completedPlanTypeModal() {
        if (!this.newPlanType) {
          return;
        }
        if (this.newPlanType.id == '0') {
          let self = this;
          let plan = _.find(this.value.billing.info.activeSubscriptions, (s) => {
            return s.subscription.id == self.computedPlanType.id;
          })
          this.removeSubscription(plan.id, 'Free');
        } else {
          this.addSubscription(this.newPlanType.id, this.newPlanType.name);
        }
        this.$refs.planTypeModal.hide();
      },
      onSelectPlanType(plan) {
        this.newPlanType = plan;
        this.$refs.planTypeModal.show();
      },
    downloadInvoice: function () {
      // let invoice_id = this.$route.params.invoice_id;
      // let q = JSON.stringify(this.invoice.expand);
      // let token = localStorage.getItem('token');
      // var expand_url = '?e=' + encodeURIComponent(q) + '&at=' + token;
      //
      // let url = appConfig.baseAPIURL + '/fe/pdf/invoice/' + invoice_id + expand_url;
      // window.open(url);
      return;
    },
    sctollToTop: function () {
      setTimeout(() => {
        document.getElementsByClassName('modal-dialog')[0].scrollIntoView();
        return true;
      }, 100);
    },
    showPreviewInvoiceModal: function () {
      let invoice_id = this.$route.params.invoice_id;
      var self = this;
      NProgress.start();
      Axios.get(`/fe/invoice/${invoice_id}`)
          .then(response => {
            self.previewInvoice = response.data;

            self.previewInvoice.all_line_items = _.map(self.previewInvoice.all_line_items, lines => {
              lines.line_items.lines = _.mapValues(lines.line_items.lines, items => {
                return _.filter(items, item => item.total != 0);
              });
              return lines;
            });

            let countSubtotal = 0;
            _.forEach(self.previewInvoice.all_line_items, function (lines) {
              _.forEach(lines.line_items.lines, function (item) {
                if (item && item.length > 0) {
                  countSubtotal++;
                }
              });
            });
            self.countSubtotal = countSubtotal;
            NProgress.done();
            self.$refs.previewInvoiceModal.show();

            [].slice.call(Array.from(document.querySelectorAll('[data-class-modal-invoice]'))).map(elem => {
              Object.keys(this.invoiceClasses).forEach(classes => {
                if (document.querySelector('.' + elem.getAttribute('data-class-modal-invoice'))
                    .querySelector('.' + this.invoiceClasses[classes] + '_expand')
                    .getAttribute('data-show-invoice') === 'true') {
                  elem.querySelector('.' + this.invoiceClasses[classes] + '_expand').style.display = 'block';
                  elem.querySelector('.' + this.invoiceClasses[classes]).style.display = 'none';
                } else {
                  elem.querySelector('.' + this.invoiceClasses[classes] + '_expand').style.display = 'none';
                  elem.querySelector('.' + this.invoiceClasses[classes]).style.display = 'block';
                }
              });
            });

          })
          .catch(error => {
            toastr.error("Error occurred while loading invoice info");
            NProgress.done();
          });

    },
      onSelectPlan(plan) {
        if(this.value.billing.settings.storage_addon_id == plan.id){
          return;
        }
        if(plan.id == 0){
          this.planToRemove = this.currentStorageAddonInfo.activeSubscriptionId;
        } else {
          this.currentPlan = plan;
        }
        this.$refs.actionСonfirmation.show();
      },
      changedLookupStatus() {
        if (this.value.billing.settings.reg_vin_lookup_always_on && !this.value.billing.settings.reg_vin_lookup_with_build_and_compliance && !this.value.billing.settings.reg_vin_lookup) {
          this.value.billing.settings.reg_vin_lookup = true;
        }
      },
      clickCloseAccount() {
        this.$refs.closeAccount.show();
      },
      closeAccount() {
        NProgress.start();
        Axios.post('/fe/settings/vendor-close-account', { action: "DELETE" })
            .then(response => {
              if (response.data && response.data._status) {
                toastr.success("Account has been closed");
              } else {
                toastr.error(response.data.msg);
              }
            })
            .catch(e => console.error(e))
            .finally(() => NProgress.done());
        this.$refs.closeAccount.hide();
      },
    selectedTab(tab) {
      this.currentTab = tab;
      this.$storage.set("views.advancedSettings.billing.defaultView", tab);
      let params = {name: 'billing', params: {tab: tab}};
      this.$router.push(params).catch(() => {
      });
    },
    selectBilling(tab){
        if (tab === 'Settings') {
          this.currentTab = 'Settings'
          this.showSettings = true
          this.showInvoices = false
        } else if (tab === 'Invoices') {
          this.currentTab= 'Invoices'
          this.showSettings = false
          this.showInvoices = true
        }
      },
      completedChangingSubscriptionDecline(){
        this.currentPlan = null;
        this.planToRemove = false;
      },
      completedChangingSubscriptionConfirmation() {
          if(this.planToRemove){
            this.removeSubscription(this.planToRemove);
            this.planToRemove = false;
          } else if(this.currentPlan){
            this.addSubscription(this.currentPlan.id);
            this.planToRemove = null;
          }
          this.$refs.actionСonfirmation.hide();
      },
      addSubscription(subscriptionId, toastrText = false){
        Axios.post('/fe/subscriptions/subscribe', { subscriptionId })
              .then(response => {
                  if (response.data && response.data._status) {
                this.$emit('reloadVendorSettings', toastrText ? toastrText : null);
                // this.value.billing.info.activeSubscriptions.push(response.data.subscription)
                // if(response.data.subscription.subscription.type == 'plan'){
                //   this.value.billing.settings.plan_id = response.data.subscription.subscription.id;
                // } else if(response.data.subscription.subscription.type == 'storage addon'){
                //   this.value.billing.settings.storage_addon_id = response.data.subscription.subscription.id;
                //   let vm = this;
                //   let item = _.find(vm.value.billing.availablePlans.storages, function (itm) {
                //     return itm.id == vm.value.billing.settings.storage_addon_id;
                //   });
                //   let limit = item.name.split('Gb');
                //   this.value.billing.info.storage.limit = Number(limit[0]) + 10;
                // }
                  }
              })
              .catch(e => console.error(e));
      },
      removeSubscription(activeSubscriptionId, toastrText = false){
        Axios.post('/fe/subscriptions/unsubscribe', { activeSubscriptionId })
            .then(response => {
              if(response.data && response.data._status){
                this.$emit('reloadVendorSettings', toastrText ? toastrText : null);
                // this.value.billing.info.activeSubscriptions.push(response.data.subscription)
                // if(response.data.subscription.subscription.type == 'plan'){
                //   this.value.billing.settings.plan_id = response.data.subscription.subscription.id;
                // } else if(response.data.subscription.subscription.type == 'storage addon'){
                //   this.value.billing.settings.storage_addon_id = response.data.subscription.subscription.id;
                //   let vm = this;
                //   let item = _.find(vm.value.billing.availablePlans.storages, function (itm) {
                //     return itm.id == vm.value.billing.settings.storage_addon_id;
                //   });
                //   let limit = item.name.split('Gb');
                //   this.value.billing.info.storage.limit = Number(limit[0]) + 10;
                // }
              }
            })
            .catch(e => console.error(e));
      },
    },
    props: {
      value: {}
    },
    computed: {
      ...mapGetters({
        isShopManagerUser: 'isShopManagerUser',
        isProductionManagerUser: 'isProductionManagerUser',
        vendorInfo: 'vendorInfo',
        isStatusActive: 'isStatusActive',
      }),
      computedPlanType: {
        get: function () {
          let vm = this;
          try {
            let item = {
              id: 0,
              name: 'Free',
              price: 0,
              type: 'plan',
            };
            _.forEach(this.value.billing.availablePlans.plans, function (itm) {
              let activeSub = _.find(vm.value.billing.info.activeSubscriptions, (s) => {
                return s.subscription.id == itm.id && s.subscription.type == 'plan'
              })
              if (activeSub) {
                item = itm
              }
            });
            return item;
          } catch (e) {
            console.log(e)
            return {
              id: 0,
              name: 'Free',
              price: 0,
              type: 'plan',
            };
          }
        },
        set: function (item) {

        },
      },
      computedProgressTextAlign() {
        let limit = 10;

        if (+this.value.billing.info.storage.limit !== 0) {
          limit = this.value.billing.info.storage.limit;
        }
        let percents =  100 / Number(limit) * parseFloat(this.computedStorageConsumedLimit);
        if (percents <= 50) {
          return 'progress-text-right'
        } else {
          return 'progress-text-left'
        }
      },
      computedPlan: {
        get: function () {
          let vm = this;
          try {
            let item = _.find(vm.value.billing.availablePlans.storages, function (itm) {
              return itm.id == vm.currentStorageAddonInfo.id;
            });
            return item;
          } catch (e) {
            return {};
          }
        },
        set: function (item) {

        },
      },
      computedLimit: {
        get() {
          let result = this.value.billing.info.storage.limit + 'GB';
          return result
        },
        set (value) {
          return value
        }
      },
      computedCost: {
        get() {
          let result = 0;
          _.forEach(this.value.billing.info.activeSubscriptions, (s) => {
            result += parseFloat(s.subscription.price)
          })
          return result
        },
        set(value) {
          return value
        }
      },
      computedColorForProgressBar() {
        if (this.isOverLimit) {
          return  'progress-bar-of-gb-error';
        }
        return 'progress-bar-of-gb-default';
      },
      isOverLimit() {
        return this.value.billing.info.storage.limit != 0 && this.value.billing.info.storage.consumed >= this.value.billing.info.storage.limit;
      },
      computedStorageConsumedLimit() {
        if (this.isOverLimit) {
          return this.value.billing.info.storage.limit
        }
        return this.value.billing.info.storage.consumed;
      },
      disabled(){
        if ((this.isShopManagerUser || this.isProductionManagerUser) && this.isStatusActive) {
          return false
        }
        return true
      },
      currentStorageAddonInfo(){
        let data = {
          id: 0,
          activeSubscriptionId: 0,
          price: 0,
          per: '0Gb',
          billed: 'Monthly'
        };

        let addon = null;
        for(let a of this.value.billing.info.activeSubscriptions){
          if(a.subscription && a.subscription.type == 'storage addon'){
            addon = a;
            break;
          }
        }
        if(addon){
          data.id = addon.subscription.id;
          data.activeSubscriptionId = addon.id;
          data.price = addon.subscription.price;
          data.per = Math.round(addon.subscription.features.storage.maxAmount.value / 1073741824) + 'Gb';
          data.billed = 'every ' + addon.subscription.periodLength + ' ' + addon.subscription.periodVariant + '(s)';
        }
        return data;
      },
    }

  }
</script>

<style>
  .fade.show {
    opacity: 1 !important;
  }
  .billing-modal .modal-dialog {
    width: 480px;
  }
</style>

<style scoped>
.credit-card-block {
  border-radius: 10px;
  border: 1px solid rgba(27, 30, 56, 0.25) !important;
}
.credit-card-block-last {
  padding: 15px;
  width: 320px;
}
.credit-card-block-first {
  width: 350px;
  padding: 15px 15px 15px 0px;
}
.color-blue {
  color: #5E79FF;
}
.border-bottom-without-plus {
  border-bottom: 1px solid rgba(27, 30, 56, 0.25) !important;
}
  .not-active {
    background-color: #fff !important;
    color: #fff !important;
  }
  .btn{
    color: #1C1F39;
    background-color: rgba(27, 30, 56, 0.12);
    width: 75px;
    font-weight: bold;
  }
  .btn-group {
    border: 1px solid rgba(27, 30, 56, 0.12);
    border-radius: 3px;
    padding: 2px 5px;
    width: 162px;
  }
  .billing .nav-item:hover {
    cursor: pointer;
  }
  .billing .description-text {
    color: rgba(0, 0, 0, 0.5);
  }
  .billing .text-active {
    min-width: 60px;
  }
  .progress-of-gb {
    width: 162px;
    border: 2px solid rgba(27, 30, 56, 0.12);
    border-radius: 3px;
    position: relative;
  }
  .progress-bar-of-gb-default {
    background-color: #5E79FF !important;
  }

  .progress-bar-of-gb-error {
    background-color: red !important;
  }

  .progress-bar-of-gb {
    height: 28px;
    text-align: right;
    border-radius: 3px;
  }
  .progress-text {
    font-size: 12px;
    line-height: 28px;
    margin-right: 10px;
    white-space: nowrap;
    font-weight: bold;
  }
  .progress-text-left {
    float: left;
    padding-left: 7px;
  }

  .progress-text-right {
    position: absolute;
    right: -6px;
    z-index: 100;
  }

  .progress-text-black{
    color: #000;
  }
  @media (min-width: 576px) {
    .billing .col-sm-9 {
      width: 25% !important
    }
    .billing .col-with-active-btn {
      width: 25% !important
    }
    .billing .description {
      width: 39%;
    }
    .col-with-active-btn {
      padding-top: 16px;
    }
    .col-sm-9 {
      width: 50%;
    }

  }
  @media (min-width: 992px) {
    .billing .description {
      width: 67%;
    }
    .billing .left-col {
      width: 50%;
    }
    .col-with-active-btn {
      padding-top: 16px;
    }
  }

  .w-162 {
    width: 162px;
  }
</style>
