import Axios from "axios";
import NProgress from 'nprogress';
import _ from "lodash";

export const Actions = {
  loadData(context, payload = {isShowNProgress: false, timestamp: 0}) {
    // console.log(payload, 'payload')
        if (payload.isShowNProgress) {
            NProgress.start();
        }
        let url = '/fe/workcenter'
            if (payload.timestamp) {
            url = url += '/' + payload.timestamp
            }
        let request = Axios.get(url).then(response => {
            if (response.data._status) {
              context.commit('loadTasks', response.data.tasks);
              context.commit('loadComments', response.data.comments);
              context.commit('loadEmails', response.data.emails);
              context.commit('loadSms', response.data.sms);
              context.commit('loadInsurers', response.data.insurers)
            }
                }).catch(error => {
            console.log(error);
        }).finally(() => {
            NProgress.done();
        });
            return request
  },
  loadNotices(context) {
    Axios.get("/fe/adv/notices")
        .then(response => {
          if (response.data._status) {
            _.forEach(response.data.promos, function (promo) {
              context.commit('loadPromos', promo);
            })
            //to remove the promo if there is an update
            context.commit('filterPromos', response.data.promos);
            context.commit('loadNews', response.data.news);
            context.commit('loadTeam', response.data.team);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
};
