<script>
    import Axios from 'axios';
    import AmazonAds from './utility/amazon-ads';
    import GoogleLocation from './utility/google-location';
    import Multiselect from "vue-multiselect";
    import _ from "lodash";
    import Email from './utility/email-validator';
import { mapGetters } from 'vuex';

    export default {
        name: 'suppliers-new',
        data: function () {
            return {
                isCheckedEmail: true,
                supplier: {
                    business_name: '',
                    abn: '',
                    type: '',
                    dms: '',
                    phone: '',
                    fax: '',
                    email: '',
                    address: '',
                    level_unit_lot: '',
                    street_name: '',
                    suburb: '',
                    state: '',
                    postcode: '',
                    country: ''
                },
                optionsSupplierType: [
                  {value: 'Select', key: ''},
                  {value: 'OEM', key: 'OEM'},
                  {value: 'After Market', key: 'AM'},
                  {value: 'Wrecker', key: 'WRECKER'},
                ],
                optionsForState: [
                    {value: 'Select your state', key: '',},
                    {value: 'Queensland', key: 'QLD',},
                    {value: 'Northern Territory', key: 'NT',},
                    {value: 'New South Wales', key: 'NSW',},
                    {value: 'Victoria', key: 'VIC',},
                    {value: 'Tasmania', key: 'TAS',},
                    {value: 'South Australia', key: 'SA',},
                    {value: 'Western Australia', key: 'WA',},
                ],
                optionsForCountry: [
                    {value: 'Select your country', key: '',},
                    {value: 'Australia', key: 'AU',},
                    {value: 'New Zealand', key: 'NZ',},
                ],
            };
        },
        computed: {
            ...mapGetters({
                getterBusinessName : 'getBusinessName',
            }),
          computedSupplierType: {
            get: function () {
              let vm = this;
              try {
                let item = _.find(this.optionsSupplierType, function (itm) {
                  return itm.key == vm.supplier.type;
                });
                return item;
              } catch (e) {
                return {};
              }
            },
            set: function (item) {
              if (item) {
                this.supplier.type = item.key;
              }
            },
          },
          computedSupplierState: {
			get: function () {
				let vm = this;
				try {
				let item = _.find(this.optionsForState, function (itm) {
					return itm.key == vm.supplier.state;
				});
				return item;
				} catch (e) {
				return {};
				}
			},
			set: function (item) {
				if (item) {
				this.supplier.state = item.key;
				}
			},
		  },
		  computedSupplierCountry: {
			get: function () {
				let vm = this;
				try {
				let item = _.find(this.optionsForCountry, function (itm) {
					return itm.key == vm.supplier.country;
				});
				return item;
				} catch (e) {
				return {};
				}
			},
			set: function (item) {
				if (item) {
				this.supplier.country = item.key;
				}
			},
		  },
        },
        methods: {
            saveData: function () {
              if (_.isEmpty(this.supplier.email)) {
                toastr.error('Your changes could not be saved, please add email');
                return;
              }
                if (!this.isCheckedEmail) {
                    toastr.error('Your changes could not be saved, please check the form fields again');
                    return;
                }
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: '/fe/supplier',
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify(this.supplier)
                })
                // Axios.post('/fe/job', JSON.stringify({customer: this.job.customer, vehicle: this.job.vehicle, booking_date: this.job.booking_date, completion_date: this.job.completion_date}))
                    .then(response => {
                        if (response.status == 200) {
                            toastr.success('Supplier details saved');
                            this.$router.push('/suppliers');
                        } else {
                            toastr.error(response.data.msg);
                        }
                        NProgress.done();
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            addressChanged: function (addr) {
                this.supplier.address = addr["address"];
                this.supplier.street_name = addr["street_name"];
                this.supplier.level_unit_lot = addr['levelUnitLot'];
                this.supplier.suburb = addr["suburb"];
                this.supplier.state = addr["state"];
                this.supplier.postcode = addr["postcode"];
                this.supplier.country = addr["country"];
            },
        },
        components: {
            AmazonAds,
            GoogleLocation,
            Multiselect,
            Email,
        }
    };
</script>
<style scoped>
.btn-save-hover{
  transition: none;
  background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
}
.btn-save-hover:hover{
  background: #5E78FF;
}
.rs-btn-group{
    padding-top: 8px;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}
.card-block{
    padding-left: 0px;
    padding-top: 10px;
}
</style>

<template>
    <div>
        <div class="page-header">
            <h4>Add Supplier</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Add Supplier</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
        </div>
        <div class="box box-block bg-white">
            <!--<p class="font-90 text-muted mb-1">Add Supplier details</p>-->
            <div class="card main-card">

                <div class="rs-btn-group">
                    <button @click="saveData" type="button" class="btn btn-primary w-min-sm waves-effect waves-light btn-save-hover">Save</button>
                </div>

                <div class="card-block">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-header bg-navyblue">
                                    <i class="ti-layout-list-thumb align-middle"></i><strong>&nbsp;&nbsp;Supplier Details</strong>
                                </div>
                                <div class="card-block bg-white">

                                    <div class="form-group row">
                                        <label for="business_name" class="col-lg-3 col-form-label">Business Name</label>
                                        <div class="col-lg-9">
                                            <input type="text" v-model="supplier.business_name" class="form-control" id="business_name" placeholder="Business Name">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="abn" class="col-lg-3 col-form-label">ABN</label>
                                        <div class="col-lg-9">
                                            <input type="text" v-model="supplier.abn" class="form-control" id="abn" placeholder="ABN">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="type" class="col-lg-3 col-form-label">Type</label>
                                        <div class="col-lg-9">
                                            <multiselect
                                                v-model="computedSupplierType"
                                                :options="optionsSupplierType"
                                                :showLabels="false"
                                                :option-height="29"
                                                :max-height="203"
                                                :close-on-select="true"
                                                track-by="key"
                                                label="value"
                                            ></multiselect>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="dms" class="col-lg-3 col-form-label">DMS</label>
                                        <div class="col-lg-9">
                                            <input type="text" v-model="supplier.dms" class="form-control" id="dms" placeholder="DMS">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="phone" class="col-lg-3 col-form-label">Phone</label>
                                        <div class="col-lg-9">
                                            <input type="text" v-model="supplier.phone" class="form-control" id="phone" placeholder="Phone">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="fax" class="col-lg-3 col-form-label">Fax</label>
                                        <div class="col-lg-9">
                                            <input type="text" v-model="supplier.fax" class="form-control" id="fax" placeholder="Fax">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="email" class="col-lg-3 col-form-label">Email</label>
                                        <email
                                            :email="supplier.email"
                                            @onChange="(value) => supplier.email = value"
                                            @checked="(value) => isCheckedEmail = value"
                                            class="col-lg-9"
                                        ></email>
                                    </div>
                                    <div class="form-group row">
                                        <label for="address" class="col-lg-3 col-form-label">Search Address</label>
                                        <div class="col-lg-9">
                                            <google-location type="text" v-model="supplier.address" id="address" placeholder="Search for an Address"
                                                             @onPlaceChange="addressChanged"></google-location>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="level_unit_lot" class="col-lg-3 col-form-label">Level/Unit/Lot Nbr</label>
                                        <div class="col-lg-9">
                                            <input type="text" v-model="supplier.level_unit_lot" class="form-control" id="level_unit_lot" placeholder="Lot, Unit, or Level number">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="street_name" class="col-lg-3 col-form-label">Street Name</label>
                                        <div class="col-lg-9">
                                            <input type="text" v-model="supplier.street_name" class="form-control" id="street_name" placeholder="Street Name">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="suburb" class="col-lg-3 col-form-label">Suburb</label>
                                        <div class="col-lg-9">
                                            <input type="text" v-model="supplier.suburb" class="form-control" id="suburb" placeholder="Suburb">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="state" class="col-lg-3 col-form-label">State</label>
                                        <div class="col-lg-9">
                                            <multiselect
                                                v-model="computedSupplierState"
                                                :options="optionsForState"
                                                :showLabels="false"
                                                :option-height="29"
                                                :max-height="203"
                                                :close-on-select="true"
                                                track-by="key"
                                                label="value"
                                            ></multiselect>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="postcode" class="col-lg-3 col-form-label">Postcode</label>
                                        <div class="col-lg-9">
                                            <input type="text" v-model="supplier.postcode" class="form-control" id="postcode" placeholder="Postcode">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="country" class="col-lg-3 col-form-label">Country</label>
                                        <div class="col-lg-9">
                                            <multiselect
                                                v-model="computedSupplierCountry"
                                                :options="optionsForCountry"
                                                :showLabels="false"
                                                :option-height="29"
                                                :max-height="203"
                                                :close-on-select="true"
                                                track-by="key"
                                                label="value"
                                            ></multiselect>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <amazon-ads></amazon-ads>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
