export const Mutations = {
  updaterSetCheckEmail(state, timestamp) {
    state.isNeedUpdateEmail = timestamp;
  },

  updaterSetCheckFiles(state, timestamp) {
    state.isNeedUpdateFiles = timestamp;
  },

  updaterSetCheckPromos(state, timestamp) {
    state.isNeedUpdatePromos = timestamp;
  },

  updaterSetCheckImages(state, timestamp) {
    state.isNeedUpdateImages = timestamp;
  },

  updaterSetCheckActivity(state, timestamp) {
    state.isNeedUpdateActivity = timestamp;
  },

  updaterSetLastTimestamp(state, timestamp) {
    state.lastTimestamp = timestamp;
  },
}
