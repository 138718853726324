import _ from 'lodash';

export const Getters = {
  lastTimestamp: state => {
    if (!state.lastTimestamp) {
      return 0;
    }
    return (state.lastTimestamp - 30);
  },
  getPromos: state => {
    return state.promos;
  },
  getNews: state => {
    return state.news;
  },
  getTeam: state => {
    return state.team;
  },
  getEmails: state => {
    return state.emails
  },
  getSms: state => {
    return state.sms;
  },
  getInsurers: state => {
    return _.filter(state.insurers, (i) => {
      return i.estimateId
    });
  },
  countNewEmails: (state, getters, rootState, rootGetters) => {
    return rootGetters['dashboard/getEmails'].length;
  },
  getTasks: state => {
    return state.tasks;
  },
  getComments: state => {
    return state.comments;
  },
}
