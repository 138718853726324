<template>
  <div class="mb-0-75 border pt-0-5 pb-0-5 dashboard-row-element clickable" @click="goToCard(card)">
    <div class="row">
      <div class="col-8 col-sm-10 pl-0">
        <strong>
          <template>File Assigned</template>
        </strong>
      </div>
      <div class="col-4 col-sm-2 pl-0 text-align-right">
        <div class="d-flex align-items-center justify-content-end wrap-text">
          <span class="pull-right d-flex align-items-center justify-content-end ml-1">
            <i :class="{'active-card-icon': !card.isViewed }" class='bx bx-card font-20 text-muted'></i>
          </span>
        </div>
      </div>
      <div class="col-8 col-sm-11 pl-0">
        <strong>
          {{ card.card_number }}
          <template v-if="card.rego_number"> - {{ card.rego_number }}</template>
          <template v-if="card.make"> - {{ card.make }}</template>
        </strong>
      </div>
      <div class="col-8 col-sm-11 pl-0">
        <template v-if="card.make">{{ card.make }}</template>
        <template v-if="card.model"> - {{ card.model }}</template>
        <template v-if="card.colour"> - {{ card.colour }}</template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardsSummary",
  components: {},
  props: {
    card: {
      type: [Object, null],
      default() {
        return {};
      }
    },
  },
  methods: {
    goToCard(card) {
      this.$router.push({name: 'CardsEdit', params: {card_id: card.card_id}});
    }
  },
};
</script>

<style scoped>

  .dashboard-row-element {
    background-color: white;
    padding: 10px 15px !important;
  }

  .task-priority,
  .bx {
    text-align: left;
  }

  .task-priority {
    width: 50px;
  }

  .bx {
    width: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1327px) {
    .task-priority,
    .bx {
      text-align: right;
    }
  }

  .font-20 {
    font-size: 20px;
  }

  .theme-color {
    color: #979595;
  }

  .dashboard-row-element {
    background-color: white;
    padding: 10px 15px !important;
  }

  .wrap-text {
    flex-wrap: wrap;
  }
</style>
