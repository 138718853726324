<script>
    import {mapGetters} from 'vuex';
    import Axios from "axios";
    import {isMobileOnly} from "../../deviceDetect/index";
    import CardsBoard from './cards-board/cards-board'
    import {appConfig} from "../../config";
    import CardsTables from './cards-table/cards-tables'

    export default {
        name: "cards",
        data: function () {
            return {
              categories: [],
              currentViewActive: "table-view",
            };
        },
        computed: {
            ...mapGetters({
                isShopManagerUser: 'isShopManagerUser',
                isProductionManagerUser: 'isProductionManagerUser',
                isEstimatorUser: 'isEstimatorUser',
                isPDRTechnicianUser: 'isPDRTechnicianUser',
                isDisplayBoardUser: 'isDisplayBoardUser',
                isTechnicalUser: 'isTechnicalUser',
                isPanelTechnicianUser: 'isPanelTechnicianUser',
                isPaintTechnicianUser: 'isPaintTechnicianUser',
                isCustomerUser: 'isCustomerUser',
                isStripperFitterUser: 'isStripperFitterUser',
                isDetailerUser: 'isDetailerUser',

                isStatusActive: 'isStatusActive',
                getterBusinessName : 'getBusinessName',
              getterTokens: 'card/getTokens',
            }),
            isCanSearchBySomeFields() {
              return !this.isPDRTechnicianUser && !this.isPanelTechnicianUser && !this.isPaintTechnicianUser && !this.isStripperFitterUser && !this.isDisplayBoardUser
            },
            isTableViewActive: function () {
                if (this.isDisplayBoardUser) {
                  return false
                }
                if (!this.isShopManagerUser && !this.isProductionManagerUser && !this.isEstimatorUser && !this.isPDRTechnicianUser && !this.isCustomerUser) {
                    return true;
                }
                if (
                    this.currentViewActive == "table-view" ||
                    (this.currentViewActive == "board-view" && isMobileOnly)
                ) {
                    return true;
                }
                return false;
            },
            isBoardViewActive: function () {
                if (this.isDisplayBoardUser) {
                  return true
                }
                if (!this.isShopManagerUser && !this.isProductionManagerUser && !this.isEstimatorUser && !this.isPDRTechnicianUser && !this.isCustomerUser) {
                    return false;
                }
                if (this.currentViewActive == "board-view" && !isMobileOnly) {
                    return true;
                }
                return false;
            },

          isMobileOnly: function () {
            return isMobileOnly;
          },

        },

        methods: {
          switchMode(v) {
            this.currentViewActive = v
            this.setTitle()
          },
          loadCategories() {
            Axios.get('/fe/planner-board/groups')
                .then(response => {
                  if (response.data.groups && response.data._status) {
                    this.categories = response.data.groups
                  }
                })
                .finally(()=> {})
          },
          setTitle (isNeedToClear = false) {
            let text = !isNeedToClear && this.currentViewActive == "board-view" ? 'Process Board' : appConfig.appName
            let title = document.getElementsByTagName('title')
            if (title?.[0]) {
              title[0].innerText = text
            }
          }
        },
        created() {
          this.loadCategories()
            this.$storage.setOptions({
                prefix: "repairshop_",
                driver: "local",
                ttl: 30 * 60 * 60 * 24 * 1000, // 30 days
            });
          if (this.$route.query.trackerFilter) {
            this.$store.commit('setCardFilter', this.$route.query.trackerFilter);
          }
        },
        mounted() {
          this.$store.dispatch('card/loadHiddenStatuses');

          if (this.$route.params && this.$route.params.filter) {
            this.$store.commit('setCardFilter', this.$route.params.filter);
          }

            if (
                this.$storage.get("views.cards.defaultView") &&
                this.$storage.get("views.cards.defaultView") != ""
            ) {
                this.currentViewActive = this.$storage.get("views.cards.defaultView");
                this.setTitle()
            }

          this.setTitle()

          if (!this.isCanSearchBySomeFields && this.getterTokens.length == 8) {
            this.$store.commit('card/setTokensNew',
                    {
                  0: [],
                  1: [],
                  2: [],
                  3: [],
                  4: [],
                  5: [],
                }
                )
          }
        },
      beforeRouteLeave(to, from, next) {
        if (this.filter == this.$route.params.trackerFilter || this.filter == this.$route.query.trackerFilter) {
          this.$store.commit('setCardFilter', '');
        }
        if (this.filter == this.$route.params.filter) {
          this.$store.commit('setCardFilter', '');
        }
        next();
      },
        beforeDestroy() {
          this.setTitle(true)
        },
        components: {
          CardsBoard,
          CardsTables
        },
    }
</script>

<template>
    <div class="ex-cards-view cards-view cards-page">
        <div class="page-header">
            <h4 v-if="isTableViewActive">View Files</h4>
            <h4 v-else>Workflow</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                </li>
                <li v-if="isTableViewActive" class="breadcrumb-item active">View Files</li>
                <li v-else class="breadcrumb-item active">View Workflow</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
            <div
                    v-if="(isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser) && isStatusActive"
                    class="page-header-nav-btn">
                <router-link to="/files/new" class="btn btn-primary plus-btn-hover">
                    <i class="ti-plus"></i> New Estimate
                </router-link>
            </div>
        </div>


      <div v-show="isTableViewActive">
        <cards-tables
            @switch="switchMode"
        ></cards-tables>
      </div>
      <div v-show="isBoardViewActive">
        <cards-board
            v-if="!isMobileOnly"
            :categories="categories"
            @switch="switchMode"
        ></cards-board>
      </div>

    </div>
</template>

<style scoped>
    .page-header{
      touch-action: none;
    }
    .list-complete-enter,
    .list-complete-leave-active {
        opacity: 0.5;
    }
    .site-content-mobile .header-display-name {
      display: none;
    }
    .site-content-mobile .box-block >>> .tab-header.nav.nav-tabs {
      padding: 0 !important;
      margin-left: 15px;
      margin-right: 15px;
      border-bottom: 5px solid #e1e2e5 !important;
    }


    @media screen and (max-width: 767px) {
        .hasSubContext:hover:after {
            transform: rotate(90deg);
        }
        .subContext {
            left: 0 !important;
            right: auto !important;
            top: 100% !important;
            width: 100% !important;
        }
        .tabs-nav-right-search.tabs-nav-right-search-font-weight .search-filter{
          padding-left: 0px !important;
        }
    }
    @media screen and (max-width: 460px) {
      .cards >>> .tabs-component {
        top: 135px;
      }
    }
</style>

<style>
    .site-content-mobile .tabs-component .tab-header.view-header-buttons{
      position: absolute;
      left: 50%;
    }
    .board-header-title {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 23px;
    }

    .board-header-title .card-menu-button {
        margin-left: 5px;
    }

    .board-header-title .title-count {
        display: flex;
        align-items: center;
    }

    .board-header-title .count-box {
        display: flex;
        width: 28px;
        height: 28px;
        flex-shrink: 0;
        background: #FFFFFF;
        border-radius: 50%;
        color: #5e77fc;
        font-size: 12px;
        line-height: 14px;
        font-weight: 900;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }

    .board-header-title .show-more {
        font-size: 30px;
        color: rgba(28, 31, 57, 0.5);
    }

    .card-menu-popup * {
        list-style: none;
    }

    .card-menu-popup {
        width: 140px;
        text-align: left;
        font-size: 11px;
        line-height: 15px;
        font-weight: bold;
        border-radius: 3px;
        color: #1C1F39;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(27, 30, 56, 0.15);
        padding: 0;
        position: relative;
        z-index: 9999;
    }

    .card-menu-popup ul {
        padding: 0;
        margin: 0;
    }

    .card-menu-popup li {
        width: 140px;
        border-radius: 3px 3px 0 0;
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
    }

    .card-menu-popup > li.parent {
        position: relative;
    }

    .card-menu-popup > li.parent:hover {
        background-color: rgba(94, 121, 255, 0.1);
        color: #5E79FF;
    }

    .card-menu-popup > li.parent:hover .sub-menu {
        display: block;
        position: absolute;

        padding-left: 2px;
        left: 100%;
        top: 0;
        z-index: 2;
    }

    .card-menu-popup.card-menu-popup--left > li.parent:hover .sub-menu {
        padding-left: unset;
        left: unset;
        padding-right: 2px;
        right: 100%;
    }

    .card-menu-popup.card-menu-popup--bottom > li.parent:hover .sub-menu {
        top: unset;
        bottom: 0;
      /*top:0;*/
      /*bottom:unset;*/
    }

    .card-menu-popup > li.parent:hover .sub-menu-inner {
        background: #FFFFFF;
        color: initial;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(27, 30, 56, 0.15);
    }

    .card-menu-popup > li.parent:hover .sub-menu-inner li:hover {
        background-color: rgba(94, 121, 255, 0.1);
        color: #5E79FF;
    }

    .card-menu-popup > li.parent:hover .sub-menu .bx {
        font-size: 1.618em;
    }

    .card-menu-popup li > .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .card-menu-popup.card-menu-popup.card-menu-popup--left li > .title {
        justify-content: flex-start;
    }

    .card-menu-popup li > .title i {
        font-size: 21px;
        color: initial;
    }

    .card-menu-popup .sub-menu {
        display: none;
    }

    .card-menu-popup .sub-menu li {
        display: flex;
        align-items: center;
        height: 31px;
    }

    .card-menu-popup .sub-menu li .status-color {
        margin-right: 5px;
    }

    .status-color {
        display: block;
        height: 11px;
        width: 11px;
        background: #FF5E5E;
        border-radius: 3px;
    }

    .status-color.medium-color {
        background: #FFDB00;
    }

    .status-color.low-color {
        background: #18D06B;
    }
    .status-color.on-hold-color {
      background: #419abe;
    }
    .status-color.undo-priority-color {
      background: black;
    }

</style>

<!-- Table style-->
<style>
    .cards-view #declineORMJob .modal-content {
      margin-left: 20%;
      width: 60%;
    }

    .cards-view #declineORMJob .tab-content {
      background: none !important;
      overflow-x: inherit !important;
    }

    .V3 .cards-page .tab-content-table table th {
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }

    .V3 .cards-page .tab-content-table table td {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
    }

    .V3 .cards-page .no-gutters {
        display: flex;
        justify-content: space-between;
    }

    .V3 .notf-icon .tooltip-text {
        width: 140px;
        /* margin-left: -68px; */
    }

    .V3 .notf-icon .tooltip-text.pos-top {
        margin-left: -68px;
    }

    .V3 .notf-icon .tooltip-text.pos-top.left {
        margin-left: -20px;
    }

    .V3 .notf-icon .tooltip-text.pos-top.left:after {
        left: 17%;
    }
</style>

<style>
    .cards-page .copy-card-modal #finishModalWizardButton {
        width: 68px;
    }

    .cards-page .copy-card-modal #cancelModalWizardButton {
        width: 68px;
    }

    .cards-page .copy-card-modal-text {
        padding-bottom: 20px;
    }

    .cards-page .copy-card-modal .tab-content {
        background: none;
    }
</style>
