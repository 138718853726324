<template>
  <div class="card-files row card-files-wrapper">
    <template v-if="!selectedFile">
      <div class="row nav-title pagination-block-bottom">
        <div class="col-md-8 p-0">
          <b-table
              class="rs-scroll rs-scroll--y"
              id="card-files-table"
              responsive
              hover
              :items="files"
              :sort-by="'sortDate'"
              :sort-desc="true"
              :fields="fields"
              :current-page="cardsCurrentPage"
              :per-page="cardsPerPage"
              @row-contextmenu="openMenuForFiles"
          >
            <template v-slot:cell(name)="data">
              <input v-if="data.item.isEdit" v-model="data.item.name2" @change="saveFile(data.item)" class="form-control"/>
              <span v-else @click="openFileViewer(data.item)" class="file-name-span">{{data.item.name}}</span>
            </template>
            <template v-slot:cell(uploaded_by)="data">
              <span class="uploaded-by">{{data.item.user}}</span>
            </template>
            <template v-slot:cell(type)="data">
              <multiselect
                  v-if="data.item.isEdit"
                  :options="fileTypeOptions.map(opt => {return opt.value})"
                  v-model="data.item.type"
                  :showLabels="false"
                  :option-height="29"
                  class="type-multiselect"
                  :max-height="150"
                  @input="saveFile(data.item)"
                  :close-on-select="true"
                  placeholder="Type"
                  :disabled="!isStatusActive"
              >
                <template #singleLabel="{ option }">
                  <span>{{getFileTypeLabel(option)}}</span>
                </template>
                <template #option="{ option }">
                  <span>{{getFileTypeLabel(option)}}</span>
                </template>
              </multiselect>
              <span v-else>{{getFileTypeLabel(data.item.type)}}</span>
            </template>
            <template v-slot:cell(created_by)="data">
              <span class="uploaded-date">{{data.item.date}}</span>
            </template>
            <template v-slot:cell(sortDate)="data">
              <span class="file-size">{{ data.item.date }}</span>
            </template>
            <template v-slot:cell(fileSize)="data">
              <span class="file-size">{{ data.item.fileSize | formatFileSize }}</span>
            </template>
            <template v-slot:cell(action)="data">
              <div class="d-flex">
                <i
                    @click="editFile(data.item)"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: `Edit doc name and type`, placement: 'left'}"
                    class='bx bx-edit-alt' style="color: #5E79FF; font-size: 22px; margin-right: 5px; transform: translateY(-1px); cursor: pointer">
                </i>
                <i
                    v-if="data.item && data.item.isPdf"
                    @click="selectedFile = data.item"
                    class='bx bxs-file-pdf'
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: `View PDF`, placement: 'left'}"
                    style="color: #bd5008; font-size: 22px; margin-right: 5px; transform: translateY(-1px); cursor: pointer"></i>
                <a v-if="isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser" @click="remTableFile(data.item.id)" class="trash-icon"
                   v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Delete File', placement:'leftbottom'}"
                >
                  <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!-- Generator: sketchtool 60.1 (101010) - https://sketch.com -->
                    <desc>Created with sketchtool.</desc>
                    <g id="UI-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Dashboard-Redesign-(Detail-Cards)Files" transform="translate(-1264.000000, -933.000000)" fill="#FF5E5E" fill-rule="nonzero">
                        <g id="Group-4" transform="translate(265.000000, 933.000000)">
                          <g id="trash" transform="translate(999.000000, 0.000000)">
                            <path d="M6,2 L6,1 C6,0.44771525 6.44771525,0 7,0 L11,0 C11.5522847,0 12,0.44771525 12,1 L12,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,5 C18,6.1045695 17.1045695,7 16,7 L15.867,7 L15.187,17.2 C15.081727,18.775582 13.773095,20 12.194,20 L5.826,20 C4.24834545,20 2.94028051,18.7780028 2.833,17.204 L2.137,7 L2,7 C0.8954305,7 0,6.1045695 0,5 L0,4 C0,2.8954305 0.8954305,2 2,2 L6,2 Z M16,4 L2,4 L2,5 L16,5 L16,4 Z M4.141,7 L4.828,17.068 C4.86376888,17.5927957 5.29998676,18.0001655 5.826,18 L12.194,18 C12.7207338,18 13.1572356,17.5915853 13.192,17.066 L13.862,7 L4.142,7 L4.141,7 Z M7,8 C7.55228475,8 8,8.44771525 8,9 L8,16 C8,16.5522847 7.55228475,17 7,17 C6.44771525,17 6,16.5522847 6,16 L6,9 C6,8.44771525 6.44771525,8 7,8 Z M11,8 C11.5522847,8 12,8.44771525 12,9 L12,16 C12,16.5522847 11.5522847,17 11,17 C10.4477153,17 10,16.5522847 10,16 L10,9 C10,8.44771525 10.4477153,8 11,8 Z" id="Shape"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </template>
          </b-table>
        </div>
        <div class="col-md-4">
          <div class="row mb-1">
            <vue-dropzone
                v-on:vdropzone-removed-file="removeFileDropzone"
                v-on:vdropzone-success="successFileUpload"
                v-on:vdropzone-file-added="addFileUpload"
                v-on:vdropzone-complete="completeFileUpload"
                v-on:vdropzone-sending="sendFileUpload"
                v-if="isStatusActive"
                ref="fileUpDrop"
                id="fileUploadDrop"
                class="dropify-wrapper dropzoneContainer"
                :options="fileUploadDropzoneOptions"
                :useCustomSlot="true">
              <div class="dropify-message" style="transform: translateY(0%); min-width: 125px;">
                <i class='bx bx-cloud-upload' ></i>
                <p class="dnd-title">Drag and Drop Doc Here</p>
                <p class="text-or mb-0-5 dnd-or">Or</p>
                <div class="text-sm-center mb-1 ex-image-upload-buttons">
                  <button  type="button" class="btn btn-primary waves-effect waves-light browse-btn browse-btn-files">Browse Docs</button>
                  <button @click="clickCancelDropzoneFiles" type="button" class="btn btn-secondary waves-effect waves-light cancel-btn">Cancel</button>
                </div>
              </div>
            </vue-dropzone>
            <slot/>
          </div>
        </div>

        <vue-context ref="rightClickMenuForFiles" @open="contextOpen" @close="contextClose">
          <ul slot-scope="item">
            <li @click="moveToEstimateFiles(item.data)">Move to Estimate
              Docs
            </li>
            <li @click="copyToEstimateFiles(item.data)">Copy to Estimate Docs</li>
<!--            <li v-if="item.data &&  item.data.isEstimateAccess" @click="removeFromEstimateFiles(item.data)">Remove from-->
<!--              Estimate Files-->
<!--            </li>-->
            <li v-if="item && item.data && !item.data.isEdit" @click="editFile(item.data)">Edit Doc Name</li>
<!--            <li v-if="item && item.data && item.data.isEdit" @click="editFile(item.data)">Stop Edit Doc Name</li>-->
            <li v-if="item.data &&  item.data.isPdf" @click="openFileViewer(item.data)">View PDF</li>
            <li @click="remTableFile(item.data.id)">Delete Doc</li>
          </ul>
        </vue-context>
      </div>
      <div class="card-files-pagination">
        <block-pagination
            :role="'Docs'"
            :count="files.length"
            :countFiltered="files.length"
            :currentPage="cardsCurrentPage"
            @changedCurrentPage="value => cardsCurrentPage = value"
            :perPage="cardsPerPage"
            @changedPerPage="value => cardsPerPage = value"
        >
        </block-pagination>
      </div>
    </template>
    <template v-else>
      <pdf-viewer :documentHeight="documentHeight" :documentWidth="documentWidth" :documentPath="selectedFile && selectedFile.url ? selectedFile.url : ''" @closePdf="onClosePdf"></pdf-viewer>
    </template>
  </div>
<!--    <div class="col-md-12">-->
<!--      <amazon-ads></amazon-ads>-->
<!--    </div>-->
</template>


<script>
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import {appConfig} from "../../config";
  import Axios from "axios";
  import {mapActions, mapGetters} from 'vuex';
  import BlockPagination from '../utility/block-pagination';
  import PdfViewer from '../utility/pdf-viewer'
  import _ from "lodash";
  import Multiselect from 'vue-multiselect'
  import { VueContext } from 'vue-context'

  export default {
    props: {
      documentHeight: {
        type: Number,
        default: 500
      },
      documentWidth: {
        type: Number,
        default: 0
      },
      cardId: {
        type: [Number, String],
        default: 0
      },
    },
  components: {
    // AmazonAds,
    vueDropzone: vue2Dropzone,
    BlockPagination,
    PdfViewer,
    Multiselect,
    VueContext,
  },

  data: function() {
    return {
      contextOpenEvent: null,
      selectedFile: null,
      fields: [{
          label: 'Doc Name',
          key: 'name',
          sortable: true,
          tdClass: 'file-name',
          thClass: 'file-name'
        },
        {
          label: 'Type',
          key: 'type',
          sortable: true,
          tdClass: 'file-type',
          thClass: 'file-type'
        },
        {
          label: 'Uploaded By',
          key: 'user',
          sortable: true,
          tdClass: 'uploaded-by',
          thClass: 'uploaded-by'
        },
        {
          label: 'Date',
          key: 'sortDate',
          sortable: true,
          tdClass: 'uploaded-date',
          thClass: 'uploaded-date'
        },
        {
          label: 'Doc Size',
          key: 'fileSize',
          sortable: true,
          tdClass: 'file-size',
          thClass: 'file-size',
        },
        {
          label: 'Action',
          key: 'action',
          tdClass: 'action',
          thClass: 'action'
        }
      ],
      fileUploadDropzoneOptions: {
        headers: { "Authorization": localStorage.getItem('token') },
        url: appConfig.baseAPIURL+'/fe/card/uploadFilesDropzone',
        method: 'post',
        maxFilesize: 50,
        // maxFiles: 2,     //Can be used to limit the maximum number of files that will be handled by this Dropzone
        thumbnailWidth: 200,
        timeout: 300000,
        clickable: '.browse-btn-files',
        addRemoveLinks: false,
        acceptedFiles: 'application/pdf, .doc, .docx',
        dictFileTooBig: "Your doc has exceeded the maximum limit of 5MB per doc. Please reduce the file size and upload again",
      },
      newFileAdded: false,
      cardsPerPage: 10,
      cardsCurrentPage: 1,
      fileTypeOptions: [
        {
          value: 'miscellaneous',
          label: 'Miscellaneous',
        },
        {
          value: 'jobRequest',
          label: 'Job Request',
        },
        {
          value: 'assessorAdjustment',
          label: 'Assessor Adjustment',
        },
        {
          value: 'authority',
          label: 'Authority',
        },
        {
          value: 'subletInvoice',
          label: 'Sublet Invoice',
        },
        {
          value: 'supplierInvoice',
          label: 'Supplier Invoice',
        },
        {
          value: 'supplierCredit',
          label: 'Supplier Credit',
        },
        {
          value: 'mechanical',
          label: 'Mechanical',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      file_ids: 'fileIdsInCardEdit',
      files: 'filesInCardEditGetter',
      updateData: 'updateDataGetter',
      isShopManagerUser: 'isShopManagerUser',
      isProductionManagerUser: 'isProductionManagerUser',
      isEstimatorUser: 'isEstimatorUser',
      isPDRTechnicianUser: 'isPDRTechnicianUser',
      isStatusActive: 'isStatusActive',
    }),

    totalFiles: function () {
      return this.files.length;
    },

    fromFiles: function() {
      if (this.totalFiles<1) return 0;
      else return (this.cardsCurrentPage - 1) * this.cardsPerPage + 1;
    },

    toFiles: function() {
      const toFiles = (this.cardsCurrentPage - 1) * this.cardsPerPage + this.cardsPerPage;
      if (toFiles > this.totalFiles) return this.totalFiles;
      return toFiles;
    }
  },

  methods: {
    ...mapActions([
      'addFileIdInCardEdit',
      'addFileInCardEdit',
      'clearFileIdsInCardEdit',
      'removeFileInCardEditById',
      'removeFileIdInCardEdit',
      'unshiftFileInCardEdit',
      'updateDataInCardEdit',
    ]),
    moveToEstimateFiles: function (file) {
      //  console.log(file);
      Axios.put(`/fe/card/${this.cardId}/file-access/` + file.id, { moveToEstimate: true })
          .then(response => {
            if (response.status === 200) {
              toastr.success(response.data._msg)
              if (response.data._status) {
                let index = _.findIndex(this.files, (f) => {
                  return Number(f.id) == Number(file.id)
                })
                if (index !== -1) {
                  this.files.splice(index, 1)
                }
              }
              file.isEstimateAccess = true
            }
          })
          .catch(error => {
            console.log(error)
          })

      this.contextClose()
    },
    copyToEstimateFiles: function (file) {
      Axios.put(`/fe/card/${this.cardId}/file-access/` + file.id, { copyToEstimate: true })
          .then(response => {
            if (response.status === 200) {
              toastr.success(response.data._msg)
            }
          })
          .catch(error => {
            console.log(error)
          })

      this.contextClose()
    },
    removeFromEstimateFiles: function (file) {
      Axios.put(`/fe/card/${this.cardId}/file-access/` + file.id, { isEstimateAccess: false })
          .then(response => {
            if (response.status === 200) {
              toastr.success(response.data._msg)
              file.isEstimateAccess = false
            }
          })
          .catch(error => {
            console.log(error)
          })

      this.contextClose()
    },
    contextOpen: function (event, w, e) {
      this.contextOpenEvent = event;
    },
    contextClose: function () {
      let event = this.contextOpenEvent;
      if (event) {
        let row = event.target.parentElement;
        let rows = row.parentElement.rows;
        if (rows) {
          for (let i = 0; i < rows.length; i++) {
            let elem = rows[i];
            elem.style.backgroundColor = "";
          }
        }
      }
    },
    openMenuForFiles: function (item, index, event) {
      if (!this.isStatusActive) {
        return
      }
      event.preventDefault()
      let row = _.find(document.elementsFromPoint(event.clientX, event.clientY), (el) => {
        return _.toLower(el.tagName) == 'td'
      })
      if (_.isEmpty(row)) {
        return
      }
      row = row.parentElement
      let rows = row.parentElement.rows;
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          let elem = rows[i];
          elem.style.backgroundColor = "";
        }
      }
      if (row) {
        row.style.backgroundColor = "#F3F3F3";
      }
      this.$refs.rightClickMenuForFiles.open(event, item)
    },
    openFileViewer(item) {
      if (item.isPdf) {
        this.selectedFile = item
      }
    },
    editFile(item) {
      this.$set(item, 'isEdit', !item.isEdit)
      if (item.isEdit) {
        let name = _.cloneDeep(item.name);
        if (item.isPdf) {
          if (_.includes(name, '.pdf')) {
            name = name.replace('.pdf', '')
            this.$set(item, 'fileType', '.pdf')
          }
        } else {
          if (_.includes(name, '.docx')) {
            name = name.replace('.docx', '')
            this.$set(item, 'fileType', '.docx')
          }
          if (_.includes(name, '.doc')) {
            name = name.replace('.doc', '')
            this.$set(item, 'fileType', '.doc')
          }
        }
        this.$set(item, 'name2', name)
      }
      this.contextClose()
    },
    getFileTypeLabel(v) {
      return _.find(this.fileTypeOptions, (o) => {
        return o.value == v
      })?.label || ''
    },
    saveFile(item) {
      NProgress.start()
      Axios({
        method: 'put',
        responseType: 'json',
        url: `/fe/card/${this.$route.params.card_id}/file/${item.id}`,
        data: {
          id: item.id,
          type: item.type,
          name: item.name2 + item.fileType
        }
      }).then((r) => {
        if (r?.data?._status) {
          toastr.success('Saved!')
        }
      }).finally(() => {
        NProgress.done()
      })
    },
    onClosePdf() {
      this.selectedFile = null
      this.$emit('openPDF', false)
    },

    addFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = "none";
    },

    successFileUpload (file, response) {
      if (response.status) {
        this.addFileIdInCardEdit(response.file_id);

        this.autoUpdateFile();
        const vm = this;
        setTimeout(function () {
          vm.$refs.fileUpDrop.removeFile(file);
        }, 1000)
      }
    },

    autoUpdateFile: function (event) {
      let update = {};
      update.file_ids = this.file_ids;
      this.setUpdateData(update);
      this.$emit('beforeDebouncedAutoSave');
    },

    setUpdateData: function (v) {
      let uid = Math.random().toString(36).substr(2, 9) + Date.now();
      // this.updateData[uid] = v;
      this.updateDataInCardEdit({uid, v});
    },

    removeFileDropzone ($event) {
      this.files.forEach((file) => {
        if (!file.uuid) {
          this.addFileInCardEdit(file);
        } else if (file.uuid && file.uuid !== $event.upload.uuid) {
          this.addFileInCardEdit(file);
        }
      })
    },

    remTableFile: function (id) {
      if (!this.isStatusActive) {
        return;
      }
      const index = this.files.findIndex(file => file.id === id);
      const filename = this.files[index].name;

      if (this.files[index].id) {
        this.$refs.fileUpDrop.dropzone.files.forEach((file, i) => {
          if (file.upload.uuid === this.files[index].uuid) {
            this.$refs.fileUpDrop.removeFile(file);
          }
        });

        if (this.files[index].id != null) {
          // this.sendFileRemove(this.files[index]);
          Axios.put(`/fe/card/${this.cardId}/file-access/` + this.files[index].id, { deleteFromCard: true })
              .then(response => {
                if (response.status === 200) {
                  // toastr.success(response.data._msg)
                  if (response.data._status) {
                    // this.files.splice(index, 1)
                  }
                }
              })
              .catch(error => {
                console.log(error)
              })

          this.contextClose()

        }
        this.removeFileInCardEditById(index);
      }
      toastr.success(filename + 'has been deleted');
    },

    completeFileUpload($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute("style");
    },

    addFile: function (file) {
      if (file.src == '' || file.src === undefined || file.src === null) {
        return;
      } else {
        this.unshiftFileInCardEdit(file);
        this.newFileAdded = true;
      }
    },

    sendFileUpload($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = "none";
      let reader = new FileReader();
      reader.onload = (e) => {
        let fileBase60Code = e.target.result;
        this.addFile({
          name: $event.name,
          src: fileBase60Code,
          user: this.$store.state.userInfo.first_name + ' ' + this.$store.state.userInfo.last_name,
          date: this.formatDateTime(),
          uuid: $event.upload.uuid
        });
      };
      reader.readAsDataURL($event);
    },

    clickBrowse: function (id) {
      $(id).trigger("click");
    },

    clickCancelDropzoneFiles: function () {
      this.$refs.fileUpDrop.removeAllFiles(true);
      this.clearFileIdsInCardEdit();
    },

    sendFileRemove: function (file) {
      NProgress.start();
      let url = '/fe/card/sendFileRemove';
      Axios({
        method: 'post',
        responseType: 'json',
        url: url,
        validateStatus: function (status) {
          return status < 500;
        },
        data: {key: file.key, id: file.id, card_id: this.$route.params.card_id}
      })
        .then(response => {
          if (response.status == 200) {
            // toastr.success(response.data.msg);
          } else {
            // toastr.error(response.data.msg);
          }
          NProgress.done();

        })
        .catch(error => {
          NProgress.done();
          toastr.error(error);
        });
    },

    formatDateTime() {
      const date = new Date();
      const year = date.getFullYear();
      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [month, day, year].join('-') + ' || ' + this.formatTime(date);
    },

    formatTime (date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutes + ' ' + ampm;
    }
},
}
</script>

<style>
  .V3 .card-files .dropzoneContainer {
    margin: 5px 15px;
    height: 195px;
    border: 1px dashed rgba(27,30,56,0.25);
    border-radius: 3px;
    background: white !important;
    height: auto;
    font-size: 13px;
    font-weight: bold;
  }

  .V3 .card-files .dropify-wrapper .dropify-message span.file-icon:before {
    content: '\E800';
    font-weight: 800;
    font-size: 37px;
    color: blue;
  }

  .V3 .card-files .browse-btn {
    height: 38px;
    width: 126px;
    border-radius: 3px;
    background-color: #5E79FF;
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
  }

  .V3 .card-files .cancel-btn {
    box-sizing: border-box;
    height: 39px;
    width: 127px;
    border: 1px solid rgba(28,31,57,0.25);
    border-radius: 3px;
  }

  .V3 .card-files .dnd-title {
    color: #1C1F39;
    font-size: 13px;
    font-weight: bold;
  }

  .V3 .card-files table thead {
    font-size: 13px;
    font-weight: bold;
    line-height: 18px;
  }

  .V3 .card-files table thead th{
    border: 0;
  }

  .V3 .card-files table thead tr {
    height: 3rem;
    border-radius: 3px;
  }

  .V3 .card-files .file-name {
    width: 45%;
    cursor: pointer;
    color: #5E79FF;
    overflow-wrap: break-word;
  }

  .V3 .card-files .file-name-span:hover {
    text-decoration: underline;
  }

  .V3 .card-files .file-name-span {
    width: 45%;
    cursor: pointer;
    color: #5E79FF;
    overflow-wrap: break-word;
  }

  .V3 .card-files .uploaded-by {
    width: 20%;
    color: #1C1F39;
  }

  .V3 .card-files .uploaded-date {
    width: 25%;
    color: #1C1F39;
  }

  .file-size {
    padding-left: 5px !important;
    padding-right: 5px !important;
    text-align: right;
    width: 5%;
    white-space: nowrap;
  }

  .file-size div {
    padding-right: 15px;
  }

  .V3 .card-files i {
    font-size: 37px;
    color: #5E79FF
  }

  .V3 .card-files .action {
    padding-top: 11px;
  }

  .V3 .card-files .trash-icon {
    cursor: pointer;
  }

  .V3 .card-files .dnd-or {
    opacity: 0.25;
    color: #1C1F39;
    font-size: 13px;
    font-weight: bold;
  }

  .V3  #card-files-table tr td {
    padding: 16px 15px 14px 15px;
  }

  .nav-title {
    padding-left: 15px;
  }

  @media screen and (max-width: 767px) {
    .nav-title {
      padding: 15px 15px 0 15px;
    }
  }


</style>

<style scoped>
.card-files >>> .file-type{
  min-width: 195px;
}
.type-multiselect >>> .multiselect__tags{
  min-width: 150px;
}
.pagination-block-bottom .table-responsive{
  padding-bottom: 80px !important;
  margin-bottom: 0px !important;
}
</style>
