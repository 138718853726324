<script>
import Axios from 'axios'
import Summernote from './utility/summernote'
import Users from './settings/Users'
import MyAccount from './settings/MyAccount'
import BusinessProfile from './settings/BusinessProfile'
import Integrations from './settings/Integrations'
import Groups from './settings/Groups'
import Billing from './settings/Billing'
import Sms from './settings/Sms'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import Production from "@/components/settings/Production.vue";
import EstimatesBooking from "./settings/estimates-booking/EstimatesBooking.vue";
import { uuid } from 'vue-uuid'
import TheInvoices from "@/components/settings/TheInvoices";
import TheEstimates from "@/components/settings/TheEstimates";
import TheCards from "@/components/settings/TheCards";

export default {
  name: 'settings',
  data () {
    return {
      isAddLocationPressed: 0,
      isSettingsAlreadyLoaded: false,
      filter: '',
      tab: '',
      typingTimer: {},
      typingTimerCEStatusId: null,
      settings: {
        estimatesBooking: {
          scheduling: {
            "reasons": [],
            "attempts": [],
            "nonAttendanceCardStatusId": null,
            "autoAddToStatus": null,
            "onSiteCardStatusId": null,
            "onSiteAutoAddToStatus": null,
          },
          "customerNotifications": {
            "sendByEmailActive": false,
            "emailPersonalizedMessage": null,
            "sendBySmsActive": false,
            "smsTemplateId": null,
            "smsAppendText": null
          },
          "reBooking": {
            "sendByEmailActive": false,
            "emailPersonalizedMessage": null,
            "sendBySmsActive": false,
            "smsTemplateId": null
          }
        },
        rates: {
          custom: {
            default_rates_rr: 0,
            default_rates_repair: 0,
            default_rates_paint: 0,
            default_rates_paint_material: 'not_set',
            default_rates_mechanical: null,
          },
          ntar: {
            default_rates_rr: 0,
            default_rates_repair: 0,
            default_rates_paint: 0,
            default_rates_paint_material: 'not_set',
            default_rates_mechanical: null,
          },
          ltar: {
            default_rates_rr: 0,
            default_rates_repair: 0,
            default_rates_paint: 0,
            default_rates_paint_material: 'not_set',
            default_rates_mechanical: null,
          },
          rs: {
            default_rates_rr: 0,
            default_rates_repair: 0,
            default_rates_paint: 0,
            default_rates_paint_material: 'not_set',
            default_rates_mechanical: null,
          },
          default: 'custom',
        },
        demo: {
          ids: null,
          enabled: false,
        },
        partssearch: {
          api_key: '',
          enabled: false,
          partssearchDefaultLocation: null,
        },
        estimage: {
          integrationStatus: false,
          cloudIntegrationStatusBySupport: false,
          cloudIntegrationStatus: false,
          integrationStatusBySupport: false,
          configuratorExeVersion: '1.x.x.x',
        },

        claimlinq: {
          api_key: '',
          enabled: false
        },

        estimates: {
          estimate_prefix: '',
          font: '',
          font_size: '10',
          draft_estimate_title: '',
          estimate_title: '',
          ii: {},
          estimate_terms: '',
          email_prefix: '',
          autoSave: true,
          isHideSeries: false,
        },
        cards: {
          card_prefix: '',
          card_number: 1,
          card_number_length: 3,
          font: '',
          font_size: '10',
          card_title: '',
          ce_statuses: [],
        },
        invoices: {
          invoice_prefix: '',
          invoice_number: 1,
          invoice_number_length: 3,
          font: '',
          font_size: '10',
          invoice_title: '',
          ii: {},
          email_prefix: '',
          auto_save: false,
        },
        triggers: [],
        billing: {
          settings: {
            status: false,
            plan_type: 'free',
            plan_id: 0,
            storage_limit: 0,
            storage_addon_id: 0,
            storage_addon_as_needed: false,
            reg_vin_lookup: false,
            reg_vin_lookup_with_build_and_compliance: false,
            reg_vin_lookup_always_on: false,
            sms_messaging: false,
            sms_messaging_incoming: false,
          },
          availablePlans: {
            plans: [
              {
                type: 'free',
                name: 'Free',
                cost: 0
              },
              {
                type: 'paid',
                name: 'Paid',
                cost: 100
              }
            ],
            storages: [
              {
                limit: 10,
                name: '10Gb',
                cost: 50
              },
              {
                limit: 20,
                name: '20Gb',
                cost: 100
              },
            ]
          },
          info: {
            storage: {
              limit: 0,
              consumed: 0
            },
            activeSubscriptions: [],
          }
        },
        maxSmsTextLength: 122,
        sms: {
          sendToCustomer: {
            status: false,
            smsReply: false,
            smsServices: false,
            messageText: '',
          }
        },
      },
      switch: { size: 'small', color: '#3E70C9' },
      nameAddStatus: '',
      fields: [
        {
          label: 'Username',
          key: 'username',
          sortable: true,
          tdClass: 'text-center clickable number',
          thClass: 'text-center bg-navyblue number'
        },
        {
          label: 'Name',
          key: 'full_name',
          sortable: true,
          tdClass: 'text-center clickable card-number',
          thClass: 'text-center bg-navyblue card-number'
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: 'text-center clickable number',
          thClass: 'text-center bg-navyblue number'
        },
        {
          label: 'Location',
          key: 'address',
          sortable: true,
          tdClass: 'clickable customer',
          thClass: 'bg-navyblue customer'
        },
        {
          label: 'Work Phone',
          key: 'work_phone',
          sortable: true,
          tdClass: 'text-center clickable rego',
          thClass: 'text-center bg-navyblue rego'
        },
        {
          label: 'Mobile Phone',
          key: 'mobile_phone',
          sortable: true,
          tdClass: 'text-center clickable make',
          thClass: 'text-center bg-navyblue make'
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: 'text-center clickable model',
          thClass: 'text-center bg-navyblue model'
        },
        {
          label: 'Role',
          key: 'role',
          sortable: true,
          tdClass: 'text-center clickable colour',
          thClass: 'text-center bg-navyblue colour'
        },
        {
          label: 'Actions',
          key: 'actions',
          sortable: true,
          tdClass: 'text-center clickable insurance',
          thClass: 'text-center bg-navyblue insurance'
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isTechnicalUser: 'isTechnicalUser',
      isCustomerUser: 'isCustomerUser',
      isEstimatorUser: 'isEstimatorUser',
      isPDRTechnicianUser: 'isPDRTechnicianUser',
      isShopManagerUser: 'isShopManagerUser',
      isProductionManagerUser: 'isProductionManagerUser',
      isStatusActive: 'isStatusActive',
      getterBusinessName: 'getBusinessName',
    }),
    isActiveParssearchCode () {
      return !!(this.settings && this.settings.partssearch && this.settings.partssearch.api_key && this.settings.partssearch.enabled)
    },
    isSetDefaultPSLoc () {
      return !!(this.settings && this.settings.partssearch && this.settings.partssearch.partssearchDefaultLocation)
    },
    defaultPSLocId () {
      return this.settings.partssearch.partssearchDefaultLocation
    },
  },
  methods: {
    onTabChange () {
      $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
        this.tab = $(e.target).attr('href')
        let route = '', params = ''
        if (this.tab === '#users') {
          route = 'users'
          this.$storage.set('views.advancedSettings.defaultView', 'users')
        } else if (this.tab === '#business-profile') {
          route = 'business-profile'
          this.$storage.set('views.advancedSettings.defaultView', 'business-profile')
        } else if (this.tab === '#production') {
          route = 'production'
          this.$storage.set('views.advancedSettings.defaultView', 'production')
        } else if (this.tab === '#integrations') {
          route = 'integrations'
          this.$storage.set('views.advancedSettings.defaultView', 'integrations')
        } else if (this.tab === '#groups') {
          route = 'groups'
          this.$storage.set('views.advancedSettings.defaultView', 'groups')
        } else if (this.tab === '#my-account') {
          route = 'my-account'
          this.$storage.set('views.advancedSettings.defaultView', 'my-account')
        } else if (this.tab === '#methodology') {
          route = 'methodology'
          this.$storage.set('views.advancedSettings.defaultView', 'methodology')
        } else if (this.tab === '#estimates') {
          route = 'estimates'
          this.$storage.set('views.advancedSettings.defaultView', 'estimates')
        } else if (this.tab === '#estimates-booking') {
          route = 'estimates-booking'
          this.$storage.set('views.advancedSettings.defaultView', 'estimates-booking')
        } else if (this.tab === '#files') {
          route = 'files'
          this.$storage.set('views.advancedSettings.defaultView', 'files')
        } else if (this.tab === '#invoices') {
          route = 'invoices'
          this.$storage.set('views.advancedSettings.defaultView', 'invoices')
        } else if (this.tab === '#billing') {
          route = 'billing'
          this.$storage.set('views.advancedSettings.defaultView', 'billing')
        } else if (this.tab === '#sms') {
          route = 'sms'
          this.$storage.set('views.advancedSettings.defaultView', 'sms')
        }
        if (route === 'integrations') {
          let subTab = 'partssearch'
          if (!_.isEmpty(this.$storage.get('views.advancedSettings.integrations.defaultView'))) {
            subTab = this.$storage.get('views.advancedSettings.integrations.defaultView')
          }
          let params = { name: 'integrations', params: { tab: subTab } }
          this.$router.push(params).catch(() => {})
        } else if (route === 'billing') {
          let subTab = 'settings'
          if (!_.isEmpty(this.$storage.get('views.advancedSettings.billing.defaultView'))) {
            subTab = this.$storage.get('views.advancedSettings.billing.defaultView')
          }
          let params = { name: 'integrations', params: { tab: subTab } }
          this.$router.push(params).catch(() => {})
        } else if (route === 'business-profile') {
          let subTab = 'details'
          if (!_.isEmpty(this.$storage.get('views.advancedSettings.business.defaultView'))) {
            subTab = this.$storage.get('views.advancedSettings.business.defaultView')
          }
          let params = { name: 'business', params: { tab: subTab } }
          this.$router.push(params).catch(() => {})
        } else {
          params = (route) ? { name: 'settings', params: { tab: route } } : '/settings'
          this.$router.push(params).catch(() => {})
        }
      })
    },
    onClickAddLocation: function () {
      this.isAddLocationPressed++
    },
    addNewUser () {
      this.$refs.users.onClickAddNewUser()
    },
    isTabActive: function (tab) {
      let url = this.$route.path
      //console.log('this.tab', this.tab);
      //console.log('tab', tab);
      //console.log('url', url);
      if (this.tab === '#integrations' && tab === 'integrations' && url.includes('/settings/integrations')) {
        return true
      } else if (this.tab === '#production' && tab === 'production' && url === '/settings/production') {
        return true
      } else if (this.tab === '#users' && tab === 'users' && url === '/settings/users') {
        return true
      } else if (this.tab === '#groups' && tab === 'groups' && url === '/settings/groups') {
        return true
      } else if (this.tab === '#business-profile' && url.includes('/settings/business-profile') && tab === 'business-profile') {
        return true
      } else if (this.tab === '#my-account' && url === '/settings/my-account' && tab === 'my-account') {
        return true
      } else if (this.tab === '#methodology' && url === '/settings/methodology' && tab === 'methodology') {
        return true
      } else if (this.tab === '#estimates' && url === '/settings/estimates' && tab === 'estimates') {
        return true
      } else if (this.tab === '#estimates-booking' && url === '/settings/estimates-booking' && tab === 'estimates-booking') {
        return true
      } else if (this.tab === '#files' && url === '/settings/files' && tab === 'files') {
        return true
      } else if (this.tab === '#invoices' && url === '/settings/invoices' && tab === 'invoices') {
        return true
      } else if (this.tab === '#billing' && url.includes('/settings/billing') && tab === 'billing') {
        return true
      } else if (this.tab === '#sms' && url === '/settings/sms' && tab === 'sms') {
        return true
      }
      return false
    },
    updateSettings: function () {
      if (this.tab === '#integrations' && this.$route.path.includes('/settings/integrations/webtrim')) {
        this.$refs.integrations.saveWebtrim()
        return
      }

      let data = _.cloneDeep(this.settings)

      if (data?.production?.days) {
        _.forIn(data.production.days, (itm, ind) => {
          if (_.toString(itm)) {
            data.production.days[ind] = itm == 'true' ? true : false
          }
        })
      }

      if (!_.isEmpty(data?.production?.definitions?.categories)) {
        _.forEach(data.production.definitions.categories, (itm, ind) => {
          if (!itm.id) {
            itm.id = uuid.v1()
          }
        })
      }

      NProgress.start()
      Axios.post('/fe/settings/vendor', data)
          .then(response => {
            NProgress.done()
            toastr.success(response.data.msg)
            if (this.isTabActive('files')) {
              this.$store.dispatch('loadCEStatuses');
            }
          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
          .finally(() => {
            if (this.tab === '#integrations' && this.$route.params.tab === 'partssearch') {
              this.$store.dispatch('loadPartsSearchLocation')
            }
          })

      if (this.isTabActive('files') && this.$refs['cardsTab']) {
        this.$refs['cardsTab'].saveData()
      }
    },
    setUrl () {
      let url = this.$route.path

      if (url.includes('/settings/integrations')) {
        this.tab = '#integrations'
      } else if (url === '/settings/production') {
        this.tab = '#production'
      } else if (url === '/settings/users') {
        this.tab = '#users'
      } else if (url === '/settings/groups') {
        this.tab = '#groups'
      } else if (url.includes('/settings/business-profile')) {
        this.tab = '#business-profile'
      } else if (url === '/settings/my-account') {
        this.tab = '#my-account'
      } else if (url === '/settings/methodology') {
        this.tab = '#methodology'
      } else if (url === '/settings/estimates') {
        this.tab = '#estimates'
      } else if (url === '/settings/estimates-booking') {
        this.tab = '#estimates-booking'
      } else if (url === '/settings/files') {
        this.tab = '#files'
      } else if (url === '/settings/invoices') {
        this.tab = '#invoices'
      } else if (url.includes('/settings/billing')) {
        this.tab = '#billing'
      } else if (url === '/settings/billing') {
        this.tab = '#billing'
      } else if (url === '/settings/sms') {
        this.tab = '#sms'
      }

    },
    loadVendorSettings (toastrText = false) {
      this.isSettingsAlreadyLoaded = false
      NProgress.start()
      Axios.get('/fe/settings/vendor')
          .then(response => {
            if (response.data) {
              this.settings = response.data
              if (!this.settings.estimates.ii) {
                this.settings.estimates.ii = {
                  show_tax_number: false,
                  show_col_headings: false,
                  show_item_code: false,
                  show_unit_price_qty: false,
                  show_payment_advice: false,
                  show_address: false,
                  show_tax_column: false,
                  show_logo: false,
                  hide_discount: false,
                  show_contact_number: false,
                  show_payment_service: false
                }
              }

              if (!this.settings.invoices.ii) {
                this.settings.invoices.ii = {
                  show_tax_number: false,
                  show_col_headings: false,
                  show_item_code: false,
                  show_unit_price_qty: false,
                  show_payment_advice: false,
                  show_address: false,
                  show_tax_column: false,
                  show_logo: false,
                  hide_discount: false,
                  show_contact_number: false,
                  show_payment_service: false,
                  advice: ''
                }
              }

              if (this.settings?.production?.days) {
                _.forIn(this.settings.production.days, (itm, ind) => {
                  if (_.isBoolean(itm)) {
                    this.settings.production.days[ind] = _.toString(itm)
                  }
                })
              }

              if (toastrText) {
                toastr.success('Your change to play type ' + toastrText + ' has been successful')
              }
            }
          })
          .catch(error => {
            toastr.error(error)
          })
          .finally(() => {
            NProgress.done()
            this.isSettingsAlreadyLoaded = true
          })
    },
  },
  mounted: function () {
    let routePath = _.cloneDeep(this.$route.path)
    if (this.isEstimatorUser) {
      let params = { name: 'settings', params: { tab: 'my-account' } }
      this.$router.push(params).catch(() => {})
    } else if (this.$route.params.customTab) {
      if (this.$route.params.customSubTab !== '') {
        if (this.$route.params.customTab === 'business-profile') {
          let params = { name: 'business', params: { tab: this.$route.params.customSubTab } }
          this.$router.push(params).catch(() => {})
          this.$storage.set('views.advancedSettings.defaultView', 'business-profile')
        }
      } else {
        this.$storage.set('views.advancedSettings.defaultView', this.$route.params.customTab)
        let params = { name: 'settings', params: { tab: this.$route.params.customTab } }
        this.$router.push(params).catch(() => {})
      }
    } else if (!_.isEmpty(this.$storage.get('views.advancedSettings.defaultView'))) {
      let currentTab = ''
      currentTab = this.$storage.get('views.advancedSettings.defaultView')
      let resultPath = '/settings/' + currentTab
      if (this.$route.path !== resultPath) {
        if (currentTab === 'integrations') {
          let subTab = 'partssearch'
          if (!_.isEmpty(this.$storage.get('views.advancedSettings.integrations.defaultView'))) {
            subTab = this.$storage.get('views.advancedSettings.integrations.defaultView')
          }
          let params = { name: 'integrations', params: { tab: subTab } }
          this.$router.push(params).catch(() => {})
        } else if (currentTab === 'business-profile') {
          let subTab = 'details'
          if (!_.isEmpty(this.$storage.get('views.advancedSettings.business.defaultView'))) {
            subTab = this.$storage.get('views.advancedSettings.business.defaultView')
          }
          let params = { name: 'business', params: { tab: subTab } }
          this.$router.push(params).catch(() => {})
        } else if (currentTab === 'billing') {
          let subTab = 'settings'
          if (!_.isEmpty(this.$storage.get('views.advancedSettings.billing.defaultView'))) {
            subTab = this.$storage.get('views.advancedSettings.billing.defaultView')
          }
          let params = { name: 'billing', params: { tab: subTab } }
          this.$router.push(params).catch(() => {})
        } else {
          this.$router.push({ name: 'settings', params: { tab: currentTab } }).catch(() => {})
        }
      }
    } else {
      let path = this.$route.path.split('/')
      let tab = path[path.length - 1]
      if (path[path.length - 2] === 'integrations') {
        let params = { name: 'integrations', params: { tab: path[path.length - 1] } }
        this.$router.push(params).catch(() => {})
      } else if (path[path.length - 2] === 'business-profile') {
        let params = { name: 'business', params: { tab: path[path.length - 1] } }
        this.$router.push(params).catch(() => {})
      } else if (path[path.length - 2] === 'billing') {
        let params = { name: 'billing', params: { tab: path[path.length - 1] } }
        this.$router.push(params).catch(() => {})
      } else if (tab === 'integrations' || tab === 'files' || tab === 'estimates' ||
          tab === 'invoices' || tab === 'users' || tab === 'groups' ||
          tab === 'my-account' || tab === 'business-profile' || tab === 'billing' || tab === 'sms') {

        if (tab === 'integrations') {
          let subTab = 'partssearch'
          if (!_.isEmpty(this.$storage.get('views.advancedSettings.integrations.defaultView'))) {
            subTab = this.$storage.get('views.advancedSettings.integrations.defaultView')
          }
          let params = { name: 'integrations', params: { tab: subTab } }
          this.$router.push(params).catch(() => {})
        } else if (tab === 'business-profile') {
          let subTab = 'details'
          if (!_.isEmpty(this.$storage.get('views.advancedSettings.business.defaultView'))) {
            subTab = this.$storage.get('views.advancedSettings.business.defaultView')
          }
          let params = { name: 'business', params: { tab: subTab } }
          this.$router.push(params).catch(() => {})
        } else if (tab === 'billing') {
          let subTab = 'settings'
          if (!_.isEmpty(this.$storage.get('views.advancedSettings.billing.defaultView'))) {
            subTab = this.$storage.get('views.advancedSettings.billing.defaultView')
          }
          let params = { name: 'billing', params: { tab: subTab } }
          this.$router.push(params).catch(() => {})
        } else {
          this.$router.push({ name: 'settings', params: { tab: tab } }).catch(() => {})
        }
      } else {
        let params = { name: 'integrations', params: { tab: 'partssearch' } }
        this.$router.push(params).catch(() => {})
      }
    }

    this.setUrl()

    if (this.isShopManagerUser) {
      if (routePath == this.$route.path) {
        this.loadVendorSettings()
      }

      // $('#invoice_payment_advice').summernote({airMode: true})

    }
  },
  watch: {
    isShopManagerUser (newVal) {
      if (newVal && !this.isSettingsAlreadyLoaded) {
        console.log('load settings 2')
        this.loadVendorSettings()
      }
    },
  },
  components: {
    Production,
    EstimatesBooking,
    Summernote,
    Users,
    MyAccount,
    BusinessProfile,
    Integrations,
    Groups,
    Billing,
    Sms,
    TheInvoices,
    TheEstimates,
    TheCards,
  }
}
</script>

<template>
  <div>
    <div class="page-header">
      <h4>Advanced Settings</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item active">Advanced Settings</li>
      </ol>
      <h4 class="header-display-name">{{ getterBusinessName }}</h4>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="box box-block bg-white">
          <div>
            <div>
              <div class="setting-card">
                <div class="tabs-component">
                  <ul class="tab-header nav nav-tabs nav-tabs-2 float-sm-left btn-right-padding">
                    <template v-if="isShopManagerUser">
                      <li class="tabs-component-tab nav-item">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('files')}" data-toggle="tab" @click="onTabChange"
                           href="#files">Files</a>
                      </li>
                      <li class="tabs-component-tab nav-item">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('estimates')}" data-toggle="tab"
                           @click.prevent="onTabChange"
                           href="#estimates">Estimates</a>
                      </li>
                      <li class="tabs-component-tab nav-item">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('estimates-booking')}" data-toggle="tab"
                           @click.prevent="onTabChange"
                           href="#estimates-booking">Estimates Booking</a>
                      </li>
                      <li class="tabs-component-tab nav-item">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('invoices')}" data-toggle="tab"
                           @click.prevent="onTabChange"
                           href="#invoices">Invoices</a>
                      </li>
                      <li class="tabs-component-tab nav-item"
                          v-if="isShopManagerUser || isCustomerUser || isProductionManagerUser">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('production')}" data-toggle="tab" @click="onTabChange"
                           href="#production">Production</a>
                      </li>
                      <li class="tabs-component-tab nav-item">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('users')}" data-toggle="tab" @click.prevent="onTabChange"
                           href="#users">Users</a>
                      </li>
                      <li class="tabs-component-tab nav-item">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('groups')}" data-toggle="tab" @click.prevent="onTabChange"
                           href="#groups">Groups</a>
                      </li>
                      <li class="tabs-component-tab nav-item">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('integrations')}" @click="onTabChange"
                           data-toggle="tab" href="#integrations">Integrations</a>
                      </li>
                    </template>
                    <li class="tabs-component-tab nav-item"
                        v-if="isShopManagerUser || isCustomerUser || isProductionManagerUser">
                      <a class="tabs-component-tab-a nav-link"
                         v-bind:class="{active: isTabActive('sms')}" data-toggle="tab" @click="onTabChange"
                         href="#sms">SMS</a>
                    </li>
                    <li class="tabs-component-tab nav-item">
                      <a class="tabs-component-tab-a nav-link"
                         v-bind:class="{active: isTabActive('my-account')}" data-toggle="tab"
                         @click.prevent="onTabChange"
                         href="#my-account">My Account</a>
                    </li>
                    <template v-if="isShopManagerUser">
                      <li class="tabs-component-tab nav-item">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('business-profile')}" @click.prevent="onTabChange"
                           data-toggle="tab" href="#business-profile">Business Profile</a>
                      </li>
                    </template>
                    <template v-if="isShopManagerUser">
                      <li class="tabs-component-tab nav-item">
                        <a class="tabs-component-tab-a nav-link"
                           v-bind:class="{active: isTabActive('billing')}" @click.prevent="onTabChange"
                           data-toggle="tab" href="#billing">Billing</a>
                      </li>
                    </template>

                  </ul>
                  <div class="tab-header rs-btn-group float-sm-right mt-1-sm">
                    <div class="tabs-nav-right-btn">
                      <button @click="updateSettings"
                              v-if="tab!=='#users' && tab!=='#my-account' && tab!=='#business-profile' && isStatusActive"
                              class="btn btn-primary w-min-sm waves-effect waves-light ml-1 my-1 btn-save-hover">Save
                      </button>
                      <a v-if="tab==='#users' && isStatusActive" href="#" @click.prevent="addNewUser"
                         class="btn btn-primary w-min-sm waves-effect waves-light ml-1 font-weight-bold my-1 plus-btn-hover">
                        <i class="ti-plus add-user"></i>
                        New User
                      </a>
                      <a v-if="tab === '#business-profile' && $storage.get('views.advancedSettings.business.defaultView') === 'locations'"
                         href="#" @click.prevent="onClickAddLocation"
                         class="btn btn-primary w-min-sm waves-effect waves-light ml-1 font-weight-bold">
                        <i class="ti-plus add-user"></i>
                        Add Location
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card-block tab-content row no-gutter">
                  <template v-if="isShopManagerUser">
                    <div v-if="isTabActive('integrations')" style="width: 100%" id="integrations"
                         class="tab-pane col-lg-6 col-md-6"
                         v-bind:class="{active: isTabActive('integrations')}" role="tabpanel">
                      <integrations ref="integrations" v-model="settings"></integrations>
                    </div>
                    <div style="padding-left: 15px" id="methodology" class="tab-pane col-lg-6 col-md-6"
                         v-bind:class="{active: isTabActive('methodology')}" role="tabpanel">
                    </div>

                    <the-estimates
                        v-if="isTabActive('estimates')"
                        v-model="settings"></the-estimates>
                    <the-cards
                        v-if="isTabActive('files')"
                        ref="cardsTab"
                        v-model="settings"></the-cards>

                    <the-invoices
                        v-if="isTabActive('invoices')"
                        v-model="settings"></the-invoices>

                    <div id="production" class="tab-pane col-lg-12 col-md-12"
                         v-bind:class="{active: isTabActive('production')}" role="tabpanel">
                      <production v-model="settings" ref="billing"></production>
                    </div>
                    <div id="estimates-booking" class="tab-pane col-lg-12 col-md-12"
                         v-bind:class="{active: isTabActive('estimates-booking')}" role="tabpanel">
                      <estimates-booking v-model="settings" ref="billing"></estimates-booking>
                    </div>
                    <div v-if="isTabActive('users')" style="padding-left: 15px" id="users"
                         class="tab-pane col-lg-12 col-md-12"
                         v-bind:class="{active: isTabActive('users')}" role="tabpanel">
                      <users ref="users" :defaultPSLocId="defaultPSLocId"
                             :isActiveParssearchCode="isActiveParssearchCode"
                             :isSetDefaultPSLoc="isSetDefaultPSLoc"></users>
                    </div>
                    <div v-if="isTabActive('groups')" style="padding-left: 15px" id="groups"
                         class="tab-pane col-lg-12 col-md-12"
                         v-bind:class="{active: isTabActive('groups')}" role="tabpanel">
                      <groups ref="groups"></groups>
                    </div>
                    <div id="sms" class="tab-pane col-lg-12 col-md-12"
                         v-bind:class="{active: isTabActive('sms')}" role="tabpanel">
                      <sms v-model="settings" ref="billing"></sms>
                    </div>
                  </template>
                  <div v-if="isTabActive('my-account')" id="my-account" class="tab-pane col-lg-12 col-md-12"
                       v-bind:class="{active: isTabActive('my-account')}" role="tabpanel">
                    <my-account ref="myAccount" :defaultPSLocId="defaultPSLocId"
                                :isActiveParssearchCode="isActiveParssearchCode"
                                :isSetDefaultPSLoc="isSetDefaultPSLoc"></my-account>
                  </div>
                  <template v-if="isShopManagerUser">
                    <div v-if="isTabActive('business-profile')" id="business-profile"
                         class="tab-pane col-lg-12 col-md-12"
                         v-bind:class="{active: isTabActive('business-profile')}" role="tabpanel">
                      <div class=" ">
                        <business-profile v-model="settings"
                                          :add-location-button-pressed="isAddLocationPressed"></business-profile>
                      </div>
                    </div>
                  </template>
                  <div v-if="isTabActive('billing')" id="billing" class="tab-pane col-lg-12 col-md-12"
                       v-bind:class="{active: isTabActive('billing')}" role="tabpanel">
                    <billing v-model="settings" ref="billing" @reloadVendorSettings="loadVendorSettings"></billing>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.invoices-editor .ql-editor {
  font-size: 13px;
}

.terms-editor {
  resize: none;
  width: 100%;
  height: 205px;
  border: 1px solid #d1d1d1;
  padding: 15px;
  font-size: 13px;
  line-height: 1.6;
  word-wrap: break-word;
}

.orderNameClass {
  width: auto !important;
}

.setting-card {
  border: none !important;
}

#invoices {
  padding-left: 15px !important;
}

.setting-card > .card-header {
  border-color: red;
  border-bottom: none !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.V3 .setting-card .card .card-header strong {
  font-size: 13px;
  line-height: 18px;
  font-weight: bold;
}

.drag-and-drop {
  cursor: pointer;
}

input[readonly] {
  cursor: unset !important;
}

.trashBarrelClass {
  cursor: pointer;
}

.verticalTextTh {
  vertical-align: middle !important;
}


#users i {
  font-size: 20px;
  margin-left: 10px;
}

#users .ti-write {
  color: #142742;
}

#users .ti-trash {
  color: #D0021B;
}

</style>

<style>
.V3 #cards {
  padding: 0px;
}

.V3 #cards .row > div:first-child {
  padding-right: 15px;
}

.V3 #cards .card-header {
  border-radius: 3px;
}

.V3 #cards .card-block {
  padding: 15px;
}

/**
 * Table
 */
/* table global */
.V3 #cards .table .bx-plus-circle.green {
  color: #34DC6E;
}

.V3 #cards .table .form-control,
.V3 #cards .table select.form-control {
  padding-left: 9.5px;
  padding-right: 9.5px;
}

.V3 #cards .table select.form-control {
  padding-right: 25px !important;
}

/* end table global */

.V3 #cards .table.tab-special td {
  width: auto;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 9.75px;
}

.V3 #cards .table.tab-special tr {
  position: relative;
}

.V3 #cards .table .thead-light {
  background-color: rgba(27, 30, 56, 0.12);
}

/* column width */
.V3 #cards .table .twidth-order {
  width: 5%;
  min-width: 100px;
}

.V3 #cards .table .twidth-name {
  width: 20%;
  min-width: 150px;

  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}

.V3 #cards .table .twidth-auto {
  width: 5%;
  min-width: 100px;
}

.V3 #cards .table .twidth-trigger {
  width: auto;
}

.V3 #cards .table .twidth-action {
  min-width: 75px;
  width: 10%
}

/* font style */
.V3 #cards .table .tfont-13-bold {
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: normal;
}

.V3 #cards .table .tfont-12-reguler {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: normal;
}

.V3 #cards .table .tfont-12-bold {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: normal;
}


.V3 #cards .table .trigger-link {
  color: #1C1F39;
}

/* table icons */
.V3 #cards .table .bx {
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
  transition: transform 250ms ease-out;
}

.V3 #cards .table .active .bx-chevron-down {
  transform: rotate(180deg);
}

.V3 #cards .table .actions {
  display: flex;
  justify-content: flex-end;
}

.V3 #cards .table .actions span {
  margin-left: 15px;
}

.V3 #cards .table .actions > *:hover {
  cursor: pointer;
}

.V3 #cards .table .actions .bx-trash {
  color: #FF5E5E;
}

.V3 #cards .table .actions .bx-plus-circle {
  color: #34DC6E;
}

/* is-edit */
.V3 #cards .table .is-edit .actions .bx-pencil {
  color: #5E79FF;
}

/* animation */
.slide-row-enter-active {
  transition: all 250ms ease-out;
}

.slide-row-leave-active {
  transition: all 200ms ease-in;
}

.slide-row-enter,
.slide-row-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

/* Input Status */
.V3 #cards .setings-cards-btn-group {
  display: flex;
}

.V3 #cards .add-status-input-settings {
  vertical-align: middle;
}

.V3 #cards .add-status-button {
  flex: 0 1 83px;
  margin-left: 10px;
  border-radius: 3px;
  border-color: #5E79FF;
  box-sizing: border-box;
  color: white;
  background-color: #5E79FF;
  padding: 10px;
  font-size: 12px;
  line-height: 1em;
  font-weight: 700;
  height: 31px;
}

.V3 #cards .add-status-button:focus {
  /* box-shadow: 0 0px 10px #5E79FF; */
  border-color: #1E2F85;
}

/* status detail */
.V3 #cards .trigger {
  display: flex;
  flex-flow: column nowrap;
}

.V3 #cards .trigger__line {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.V3 #cards .trigger__line .cell {
  min-width: 8px;
  margin: 0 10px;
}

.V3 #cards .trigger__line .cell:first-child {
  margin-left: 0px;
}

.V3 #cards .trigger__option {
  flex: 0 0 101px;
}

.V3 #cards .trigger__actions > * {
  margin-left: 9.5px;
}

.V3 .add-user {
  font-weight: bold !important;
}

/* card */
.V3 .card {
  margin-bottom: 0px;
}

.V3 .card-block {
  padding: 15px;
}

/* this probably can be converted to more general .tab-pane */
.tab-pane--estimates,
.tab-pane--invoices {
  max-width: 1157px;
}


@media screen and (max-width: 768px) {
  .V3 #cards .row > div:first-child {
    padding-right: 0px;
  }
}

.pin-image {
  padding-left: 35px !important;
}

@media screen and (max-width: 991px) {
  .pin-image {
    margin-bottom: 15px;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.pin-checkmark i {
  font-size: 26px;
  color: white !important;
  margin-left: -2px !important;
  margin-top: -2px !important;
}


.V3 .setting-card .ql-toolbar {
  display: none;
}

.V3 .setting-card .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 1px solid rgba(28, 31, 57, 0.25);
}
</style>
<style scoped>
.btn.btn-primary.btn-save-hover{
  transition: none;
  background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
}
.btn.btn-primary.btn-save-hover:hover{
  background: #5E78FF;
}
</style>
