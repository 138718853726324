
let creator = [
    { label: "Fannie Rhodes" },
    { label: "Violet Delgado" },
    { label: "Emily Phillips" },
    { label: "Marie Schmidt" },
];

let date = [
    {
        label: "Date",
        type: "Date",
        iconClass: "bx-calendar",
    },
];

let assignee = [
    { label: "Assignee 1" },
    { label: "Assignee 2" },
    { label: "Assignee 3" },
    { label: "Assignee 4" },
];

let cardProgress = [
    { label: "File Open" },
    { label: "Estimate" },
    { label: "Created" },
    { label: "Parts Pricing" },
    { label: "Estimate Sent" },
];

let notifications = [
    { label: 'Email' },
    { label: 'Electronic' },
    { label: 'Asignee' },
    { label: 'Chat' }
]

let activityType = [
    { label: 'Email' },
    { label: 'External' },
    { label: 'Assignment' },
    { label: 'Status/Change'}
]


let insuranceJobs = [];
let insurerNames = [];
let vehicleMakes = [];
let vehicleModel = [];
let vehicleSeries = [];
let estimateMethodology = [];
let vehicleColour = [];
let vehicleBody = []

let Filter = {
    filters: [], // load default for first time
    updateStatuses(statuses) {
        cardProgress = statuses.map(status => {
            return {
                label: status.name,
                id: status.ce_status_id,
                index: status.order_number
            }
        });
        // console.log('generate statuses', cardProgress, this.list);
        return cardProgress;
    },
    /**
     * Generate Filter by their 'type' / page
     * Base on spec in Words doc
     * @param {String} type
     */
    generate(type) {
        // console.log('Filter Generated, ', type);
        switch (type) {
            case 'nofilter':
            case 'empty': {
                return []
            }
            case 'activities': {
                return [
                    {
                        label: 'Activity Type',
                        iconClass: 'bx-list-check',
                        originalIndex: 0,
                        options: [activityType]
                    },
                    {
                        label: 'Task Name',
                        iconClass: 'bx-task',
                        originalIndex: 1,
                        options: [],
                    },
                    {
                        label: 'Comment',
                        iconClass: 'bx-comment-detail',
                        originalIndex: 2,
                        options: [],
                    }
                ]
            }
            case 'estimates':
                {
                    return [
                        {
                            label: "Creator",
                            iconClass: "bx-pencil",
                            originalIndex: 0,
                            options: [creator, date, cardProgress],
                        },
                        {
                            label: "Assignee",
                            iconClass: "bx-user",
                            originalIndex: 1,
                            options: [assignee, date, cardProgress, notifications],
                        },
                        {
                            label: "Status",
                            iconClass: "bx-purchase-tag",
                            originalIndex: 2,
                            options: [cardProgress, date, assignee],
                        },
                        {
                            label: "Estimate Email",
                            iconClass: "bx-envelope",
                            originalIndex: 3,
                            options: [assignee, date],
                        },
                        {
                            label: "Estimate Electronic",
                            iconClass: "bx-abacus",
                            originalIndex: 4,
                            options: [assignee, date],
                        },
                        {
                            label: "Estimate Tasks",
                            iconClass: "bx-check-circle",
                            originalIndex: 5,
                            options: [assignee, date],
                        },
                        {
                            label: "Vehicles",
                            iconClass: "bx-car",
                            originalIndex: 6,
                            options: [vehicleMakes, vehicleModel, vehicleSeries, estimateMethodology, vehicleColour, vehicleBody],
                        },
                        {
                            label: "Insurance Repair Jobs",
                            iconClass: "bx-shield",
                            originalIndex: 7,
                            options: [insuranceJobs, insurerNames],
                        },
                    ];
                }
            case 'invoices':
            case 'cards':
            default:
                {
                    return [
                        {
                            label: "Creator",
                            iconClass: "bx-pencil",
                            originalIndex: 0,
                            options: [creator, date, cardProgress],
                        },
                        {
                            label: "Assignee",
                            iconClass: "bx-user",
                            originalIndex: 1,
                            options: [assignee, date, cardProgress, notifications],
                        },
                        {
                            label: "Status",
                            iconClass: "bx-purchase-tag",
                            originalIndex: 2,
                            options: [cardProgress, date, assignee],
                        },
                        {
                            label: "Insurance Repair Jobs",
                            iconClass: "bx-shield",
                            originalIndex: 3,
                            options: [insuranceJobs, insurerNames],
                        },
                    ];
                }
        }

    },
    getList(level, category) {
        // console.log(level, category);
        if (level === 0 && category === 0) {
            return [];
        }
        if (level === 0) {
            return this.list;
        } else if (level > 0 ) {
            // console.log(level, category);
            // return this.list[category].options[level].options;
        } else {
            [];
        }
    }
}


export default Filter;
