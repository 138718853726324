<template>
  <div class="mb-0-75 border pt-0-5 pb-0-5 dashboard-row-element" @click="goToEstimate">
    <div class=" email-block-position">
      <div class="d-flex pl-0 dashboard-email-text" style="width: 95%; font-weight: bold">
        <span
            class="text-overflow"
            v-b-tooltip.hover="{customClass: 'ps-tooltip text-overflow-tooltip-top', html: true, title:'From: ' + insurer.insurerName + ' - ' + CMS +  ' - ' + insurer.estimateNumber, placement:'righttop'}"
        > From: <span class="text-overflow-font-weigh-bold">{{ insurer.insurerName }}</span> - {{
            CMS
          }}- {{ insurer.estimateNumber }}</span>
      </div>
      <div class="d-flex p-0 dashboard-email-text">
        <div class="dashboard-email-time">
          <strong style="display: block">Received </strong>
          <strong style="white-space: nowrap">{{ insurer.date | formatDateShort }} {{
              insurer.date | formatTime
            }}</strong>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" v-b-tooltip.hover="{customClass: 'ps-tooltip text-overflow-tooltip-bottom', html: true, title:'Subject: ' + insurer.message, placement:'bottomright'}">
        Message: {{ insurer.message }}
      </div>
    </div>
    <i :class="{'active-email-icon': !insurer.isViewed }"
       class='bx bx-detail theme-color font-20 pull-right email-icon'></i>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'dashboard-insurer-summary',
  props: {
    insurer: {
      type: [Object, null],
      default () {
        return {
          activityId: 0,
          cardId: 0,
          estimateId: 0,
          isViewed: false,
          message: '',
          insurerName: '',
          CMS: '',
          estimateNumber: '',
        }
      }
    },
  },
  mounted() {
    this.$root.$on('bv::tooltip::show', bvEvent => {
      if (bvEvent.target.scrollWidth < bvEvent.target.offsetWidth) {
        bvEvent.preventDefault()
      }
    })
  },
  computed: {
    CMS () {
      let cms = this.insurer.CMS
      if (_.toLower(cms) === 'iag' || _.toLower(cms) === 'orm') {
        return 'ORM'
      } else if (_.toLower(cms) === 'claimlinq') {
        return 'ClaimLinq'
      } else if (_.toLower(cms) === 'estimage' ||  _.toLower(cms) === 'estimage online' ||  _.toLower(cms) === 'estimageonline' ) {
        return 'Estimage'
      }else if (_.toLower(cms) === 'audanet') {
        return 'AudaBridge'
      }
      return _.toUpper(cms)

    }
  },
  methods: {
    goToEstimate () {
      this.$router.push(
          { name: 'EstimatesEdit', params: { estimate_id: this.insurer.estimateId } }
      )
    }
  },

}
</script>

<style scoped>
.text-overflow-tooltip-top{
  width: 300px;
  left: -70px !important;
  top: -25px !important;
}
.text-overflow-tooltip-top >>> .tooltip-inner{
  width: 300px !important;
  max-width: 300px;
  border-radius: 10px 10px 10px 0 !important;
}
.text-overflow-tooltip-bottom{
  width: 300px;
}
.text-overflow-tooltip-bottom >>> .tooltip-inner{
  width: 300px !important;
  max-width: 300px;
  border-radius: 0 10px 10px 10px !important;
}
.text-overflow-tooltip-top.tooltip-hidden,
.text-overflow-tooltip-bottom.tooltip-hidden{
  display: none;
}
.text-overflow-font-weigh-bold {
  font-weight: 700;
}

.email-icon {
  color: #cecece;
  position: absolute;
  top: 10px;
  right: 10px;
}

.active-email-icon {
  color: #5E79FF !important;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75ch;
}

.font-20 {
  font-size: 20px;
}

.dashboard-row-element {
  background-color: white;
  padding: 10px 15px !important;
  position: relative;
}

.wrap-text {
  flex-wrap: wrap;
}

.email-block-position {
  justify-content: space-between;
}

.email-block-position .dashboard-email-text {
  padding-right: 20px;
}

.dashboard-email-time {
  display: flex;
  padding-right: 30px;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.dashboard-email-time strong{
  font-weight: 600;
  color: #414141;
}

@media screen and (max-width: 440px) {
  .email-block-position {
    flex-direction: column;
  }

  .email-block-position .dashboard-email-text {
    width: 100% !important;
  }

  .email-block-position .dashboard-email-time {
    padding-right: 0 !important;
  }
}

@media (min-width: 991px) and (max-width: 1440px) {
  .email-block-position {
    flex-direction: column;
  }

  .email-block-position .dashboard-email-text {
    width: 100% !important;
  }

  .email-block-position .dashboard-email-time {
    padding-right: 0 !important;
  }
}
</style>
