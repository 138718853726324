<script>
import { mapGetters } from 'vuex';
import {VStepper} from 'vue-stepper-component';
import GoodWizard from '../../utility/vueGoodWizard';
import Axios from 'axios';
import _ from "lodash";
import advancedSearchWithNew from '../../utility/advancedSearchWithNew'
import AdvancedSearch from '../../utility/advanced-search'
import AdvancedSearchForLookup from '../../utility/advanced-search-for-lookup';
import Multiselect from 'vue-multiselect'
import DatePicker from '../../utility/date-picker'
import NumberFormatter from '../../utility/number-formatter'
import {appConfig} from "../../../config";

export default {
  name: 'card-options-check-in',
  data: function () {
    return {
      properties: {},
      insurers: [],
      isCheckEmail: true,
      currentNewMake: '',
      currentNewModel: '',
      isNewCustomerInfo: false,
      newCustomerInfo: {
        customer_id: null,
        name: '',
        mobile: '',
        email: '',
        customer_type: 'I',
        abn: '',
        fax: '',
      },
      isSelectedExistingCustomer: false,
      isPrintJobCard: false,
      isPrintKeyTicket: false,
      isAlreadyClickedSave: false,
      callComputed: 0,
      excessPlaceholder: '',
      card: {
        arrivedDate: null,
        aprxProdDays: null,
        pickUpDate: null,
        customer_contact_2: '',
        estimator: null,
        customer: {
          customer_id: null,
          name: '',
          mobile: '',
          phone: '',
          email: '',
          customer_type: 'I',
          abn: '',
          address: '',
          level_unit_lot: '',
          street_name: '',
          suburb: '',
          state: '',
          postcode: '',
          country: '',
          fax: '',
        },
        vehicle: {
          customer_vehicle_id: null,
          dom: '',
          complianceDate: '',
          rego_number: '',
          make: '',
          model: '',
          series: '',
          series_shedule_number: '',
          colour: '',
          colour_variant: '',
          badge: '',
          body: '',
          vin: '',
          odometer: 0,
          transmission: '',
        },
        insurance: {
          id: null,
          insurance_claim_type: null,
          insurer_id: null,
          insurer_name: '',
          insurer_location: '',
          insurer_assessor_id: null,
          assessor_name: '',
          assessor_email: '',
          assessor_phone: '',
          policy_number: '',
          claim_number: '',
          assessment_date: '',
          excess: '',
          contribution: '',
          discount: '',
          market_value: '',
          agreed_value: '',
          accidentDescription: '',
          sumInsured: '',
          pav: '',
          rates: {
            estimate_methodology: 'custom',
            estimate_rates_rr: null,
            estimate_rates_repair: null,
            estimate_rates_paint: null
          }
        },
        insurance_repair: 'true',
        booking_date: '',
        completion_date: '',
        pickUpTime: '',
        vehicleInBoard: '',
        boardIn: {
          id: null,
          updatedDate: null,
          createdDate: null,
        },
        boardDeparture: {
          id: null,
          updatedDate: null,
          createdDate: null,
        },
        card_number: '',
        card_status: '',
        statuses: [],
        state: 1,
        card_id: 0,
        estimate_number: '',
        supplement_numbers: '',
        isTotalLoss: false,
        invoice: {},
        excess_invoice: {},
        estimate_methodology: null,
        vehicleDriverName: '',
        contactNumber1: '',
        contactNumber2: '',
        contactEmail: '',
        plannerBoard: {
          'vehicle': {
            groupId: null,
            id: null,
            name: '',
          },
          'onSite': {
            groupId: null,
            attempt: null,
            isAttendedBooking: false,
            onsiteEstimateBookingDate: null,
            reason: null,
            id: null,
            name: '',
          },
        }
      },
      steps: [
        {
          label: 'Review Contact',
          slot: 'card-options-check-in-step-1',
        },
        {
          label: 'Review Booking',
          slot: 'card-options-check-in-step-2',
        },
        {
          label: 'Finalise',
          slot: 'card-options-check-in-step-3',
          options: {
            nextDisabled: true,
          },
        }
      ],
      step: 1,
    };
  },
  methods: {
    onInsurerSelect: function (i) {
      if (i === null) {
        this.card.insurance.insurer_id = null
        this.card.insurance.insurer_location = ''
        this.card.insurance.custom_paint_times = {}
      } else if (i && i.id === null) {
        this.card.insurance.insurer_id = null
        this.card.insurance.insurer_name = null
        this.card.insurance.insurer_location = ''
        this.card.insurance.custom_paint_times = {}
      } else {
        if (this.card.insurance.insurer_id == i.insurer_id) {
          return
        }
        this.card.insurance.insurer_name = i.insurer_name
        this.card.insurance.insurer_id = i.insurer_id
        this.card.insurance.insurer_location = i.insurer_location
        this.card.insurance.rates = i.rates
        this.card.insurance.rates.estimate_methodology = _.toLower(i.rates.estimate_methodology)
        this.card.insurance.custom_paint_times = i.custom_paint_times
      }
      this.card.insurance.insurer_assessor_id = null
      this.card.insurance.assessor_name = ''
      this.card.insurance.assessor_email = ''
      this.card.insurance.assessor_mobile_phone = ''
      this.card.insurance.assessor_work_phone = ''
    },
    noNewCustomNtarLtarVehicleModel: function () {
      this.$refs.multimodel.isOpen = false;
      this.$refs.multimodel.$el.blur();
      this.currentNewModel = '';
    },
    addNewCustomNtarLtarVehicleModel: function () {
      try {
        if (this.currentNewModel != '') {
          this.card.vehicle.series = '';
          this.card.vehicle.series_shedule_number = 0;
          var arr = {};
          arr[this.currentNewModel] = {t: 2, data: {}};

          var obj = Object.assign({}, this.$store.state.vehicleMakeModels);
          obj[this.card.vehicle.make]['data'] = Object.assign({}, obj[this.card.vehicle.make]['data'], arr);
          this.$store.state.vehicleMakeModels = Object.assign({}, obj);
          this.card.vehicle.model = this.currentNewModel;
          this.$refs.multimodel.isOpen = false;
          this.$refs.multimodel.$el.blur();
          this.currentNewModel = '';
        }
      } catch (err) {
        console.log('Error', err);
      }
    },
    isNoScheduleNumberForModel: function (model) {
      if (!model) {
        return null;
      }
      if (model
          && this.vehicleMakeModels[this.card.vehicle.make]
          && this.vehicleMakeModels[this.card.vehicle.make]['data']
          && this.vehicleMakeModels[this.card.vehicle.make]['data'][model]
          && this.vehicleMakeModels[this.card.vehicle.make]['data'][model].t
          && this.vehicleMakeModels[this.card.vehicle.make]['data'][model].t == 2) {
        return true;
      }
      return false;
    },
    onCustomerVehicleModelNew: function (value) {
      this.currentNewModel = _.toUpper(value);
    },
    onCustomerVehicleModelSelect: function (m) {
      this.card.vehicle.series = m.value.trim();
      if (m.t == 2) {
        this.card.vehicle.series_shedule_number = 0;
      } else {
        this.card.vehicle.series_shedule_number = m.sn;
      }

      this.card.vehicle.model = m.key.trim();
      if (this.computedBodies.length > 1) {
        this.card.vehicle.body = '';
      }
    },
    nameOfVehicleModel({key, value}) {
      if (key) {
        return `${key}`;
      }
      return '';
    },
    isNoScheduleNumberForMake: function (make) {
      if (make && this.vehicleMakeModels[make] && this.vehicleMakeModels[make].t && this.vehicleMakeModels[make].t == 2) {
        return true;
      }
      return false;
    },
    noNewCustomNtarLtarVehicleMake: function () {
      this.currentNewMake = '';
      this.$refs.multimake.isOpen = false;
      this.$refs.multimake.$el.blur();
    },
    addNewCustomNtarLtarVehicleMake: function () {
      try {
        if (this.currentNewMake != '') {
          this.card.vehicle.model = '';
          this.card.vehicle.series = '';
          this.card.vehicle.series_shedule_number = 0;
          var arr = {};
          arr[this.currentNewMake] = {t: 2, data: {}};
          var obj = Object.assign({}, this.$store.state.vehicleMakeModels);
          obj = Object.assign({}, obj, arr);
          this.$store.state.vehicleMakeModels = Object.assign({}, obj);
          this.card.vehicle.make = this.currentNewMake;
          this.selectedVehicleMake = this.currentNewMake;
          this.currentNewMake = '';
          this.$refs.multimake.isOpen = false;
          this.$refs.multimake.$el.blur();
        }
      } catch (e) {
        console.log(e);
      }
    },
    onCustomerVehicleMakeNew: function (value) {
      this.currentNewMake = _.toUpper(value);
    },

    saveCardUpdates() {
      if (this.isAlreadyClickedSave) {
        return
      }
      this.isAlreadyClickedSave = true
      NProgress.start()
      let update = {};
      if (this.isNewVehicleInfo) {
        let card_id = this.card.card_id;
        Axios({
          method: 'post',
          responseType: 'json',
          headers: {'addNewVehicleInfo': true},
          url: `/fe/card/${card_id}`,
          validateStatus: function (status) {
            return status < 500;
          },
          data: {vehicle: this.card.vehicle, customer_id: this.card.customer.customer_id, not_rego: this.isNoNeedVehicleRego}
        })
            .then(response => {
            })
            .catch(error => {
              console.log('error',error)
            });
      } else {
        update.vehicle = this.card.vehicle;
        update.customer_id = this.card.customer.customer_id;
        update.not_rego = this.isNoNeedVehicleRego;
        // this.setUpdateData(update);
      }

      if (this.isNewCustomerInfo) {
        if (this.card.customer.customer_id == null || this.card.customer.customer_id == 0) {
          let card_id = this.$route.params.card_id
          Axios({
            method: 'post',
            responseType: 'json',
            headers: { 'addNewCustomerInfo': true },
            url: `/fe/card/${card_id}`,
            validateStatus: function (status) {
              return status < 500
            },
            data: {
              customer: this.card.customer,
              assignedTo: this.card.assignedTo,
              vehicle: this.card.vehicle
            }
          })
              .then(response => {
                if (response.status === 200) {
                }
              })
              .catch(error => {
              })
        } else {
          if (!this.isNewVehicleInfo) {
            update.selected_customer = true
          }
        }
      } else {
        update.assignedTo = this.card.assignedTo
        if (this.isSelectedExistingCustomer) {
          update.selected_customer = true
        }
      }

      update.vehicleDriverName = this.card.vehicleDriverName
      update.contactNumber1 = this.card.contactNumber1
      update.contactNumber2 = this.card.contactNumber2
      update.contactEmail = this.card.contactEmail
      update.booking_date = this.card.booking_date
      update.arrivedDate = this.card.arrivedDate
      update.completion_date = this.card.completion_date
      update.insurance = this.card.insurance
      update.insurance_repair = this.card.insurance_repair

      if (_.isEmpty(this.card.arrivedDate)) {
        if (this.card.boardIn && this.card.boardIn.createdDate && this.card.boardIn.id) {
          this.removeVehicle(this.card.boardIn.id)
        }
        if (this.card.boardDeparture && this.card.boardDeparture.createdDate && this.card.boardDeparture.id) {
          this.removeVehicle(this.card.boardDeparture.id)
        }
      } else {
        if (!(this.card.boardIn && this.card.boardIn.createdDate && this.card.boardIn.id)) {
          this.addedToVehicleInBoard()
        }
        if (!(this.card.boardDeparture && this.card.boardDeparture.createdDate && this.card.boardDeparture.id)) {
          this.addToDepartureBoard()
        }
      }

      let uid = Math.random().toString(36).substr(2, 9) + Date.now()
      let r = {
        uid: update
      }

      Axios({
        method: 'post',
        responseType: 'json',
        headers: { 'Autosave': true },
        url: `/fe/card/${this.$route.params.card_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: r
      }).then(() => {

        Axios.post(`/fe/card/${this.$route.params.card_id}/check-in`, {notes: this.card.notes}).then((r) => {
          if (r?.data?._status) {
            this.isAlreadyClickedSave = false
            this.$router.push({name: 'CardsEdit', params: {card_id: this.$route.params.card_id, tab: this.$route.params.tab}})

            if (!this.isPrintKeyTicket && this.isPrintJobCard) {
              let card_id = this.$route.params.card_id
              let token = localStorage.getItem('token')

              setTimeout(() => {
                window.open(appConfig.baseAPIURL + `/fe/pdf/jobcard/${card_id}?at=${token}`)
              }, 100)
            }

            if (this.isPrintKeyTicket) {
              setTimeout(() => {
                const routeData = this.$router.resolve(
                    {
                      name: 'QRCode',
                      query: {
                        cardId: this.$route.params.card_id,
                        cardNumber: this.card.card_number,
                        customer: this.card.customer.name,
                        insurer: this.card.insurance.insurer_name,
                        buildDate: this.card.vehicle.dom,
                        make: this.card.vehicle.make,
                        model: this.card.vehicle.model,
                        rego: this.card.vehicle.rego_number,
                        isPrintJobCard: this.isPrintJobCard ? this.isPrintJobCard : false,
                      }
                    }
                );

                window.open(routeData.href, '_blank');
              }, 100)
            }


            NProgress.done()
          }
        })

      })


    },
    addToDepartureBoard () {
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/departure/add-card`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          cardId: this.card.card_id,
        },
      }).then(response => {

      })
    },
    addedToVehicleInBoard () {
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/in/add-card`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          cardId: this.card.card_id,
        },
      }).then(response => {
      })
    },
    removeVehicle (id) {
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/remove`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          boardId: id,
        },
      }).then(response => {
      })
    },

    onCustomerVehicleMakeSelect: function (m) {
      this.card.vehicle.model = '';
      this.card.vehicle.body = '';
      this.card.vehicle.series = '';
      if (m.t == 2) {
        this.card.vehicle.series_shedule_number = 0;
      } else {
        this.card.vehicle.series_shedule_number = '';
      }
      this.selectedVehicleMake = m.key.trim();
      if (m !== null) {
        this.card.vehicle.make = m.key.trim();
      }
      if (!this.isEstimateMethodologyCustom) {
        this.card.vehicle.colour = '';
      }
    },
    changedEmailAddress () {
      this.checkEmail2()
    },
    checkEmail () {
      if (this.card.contactEmail.includes('@')) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.isCheckEmail = re.test(this.card.contactEmail)
      } else {
        this.isCheckEmail = true
      }
    },
    checkEmail2 () {
      if (this.card.contactEmail === '' || !(this.card.contactEmail && this.card.contactEmail.trim())) {
        return
      }
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.isCheckEmail = re.test(this.card.contactEmail)
    },
    autoUpdateContacts: function () {
      return
    },
    onCustomerUpdateName: function (name) {
      this.isSelectedExistingCustomer = true
      this.isNewCustomerInfo = false
      this.card.customer.updateCustomerName = name
      this.card.customer.name = name
    },
    onCustomerVehicleSelect: function (v) {
      if (v === null) {
        let rego = this.card.vehicle.rego_number;
        this.newVehicleInfo.rego_number = rego;
        this.card.vehicle = this.newVehicleInfo;
      } else {
        this.card.vehicle.customer_vehicle_id = v.customer_vehicle_id;

        if (v.rego_number) {
          this.card.vehicle.rego_number = v.rego_number;
        }
        if (v.dom) {
          this.card.vehicle.dom = v.dom;
        }
        if (v.compliancePlate && v.compliancePlate != '') {
          this.card.vehicle.complianceDate = v.compliancePlate;
        }
        if (v.make) {
          this.card.vehicle.make = v.make;
        }
        if (v.model) {
          this.selectedVehicleMake = v.make;
        }
        if (v.series) {
          this.card.vehicle.series = v.series;
        }
        if (v.series_shedule_number != '') {
          this.card.vehicle.series_shedule_number = v.series_shedule_number;
          this.selectedVehicleSheduleNumber = v.series_shedule_number;
        } else if (v.series) {
          this.card.vehicle.series_shedule_number = this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'][v.series]['data'].sn;
          this.selectedVehicleSheduleNumber = this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'][v.series]['data'].sn;
        }
        if (v.color && v.color != '') {
          this.card.vehicle.colour = v.color;
        }

        if (v.colour_variant) {
          this.card.vehicle.colour_variant = v.colour_variant;
        }

        if (v.badge) {
          this.card.vehicle.badge = v.badge;
        }

        if (v.body) {
          this.card.vehicle.body = v.body;
        }

        if (v.vin) {
          this.card.vehicle.vin = v.vin;
        }

        if (v.odometer) {
          this.card.vehicle.odometer = v.odometer;
        }

        if (v.transmission) {
          this.card.vehicle.transmission = v.transmission;
        }
      }
    },
    onCustomerSelect: function (c) {
      if (Number(c?.customer_id) && Number(this.card?.customer?.customer_id) && Number(c?.customer_id) == Number(this.card?.customer?.customer_id)) {
        return
      }
      if (c === null) {
            let name = this.card.customer.name
        if (name && name != '') {
          this.newCustomerInfo.name = name
        }
        this.card.customer = this.newCustomerInfo

      } else {
        let customer = _.filter(this.getterAllCustomers, function (customer) {
          return customer.customer_id == c.customer_id
        })[0]
        this.isSelectedExistingCustomer = true
        this.isNewCustomerInfo = false
        this.card.customer.customer_id = customer.customer_id
        this.card.customer.name = customer.customer_name
        this.card.customer.phone = customer.phone
        this.card.customer.fax = customer.fax
        this.card.customer.address = customer.address
        this.card.customer.level_unit_lot = customer.level_unit_lot
        this.card.customer.street_name = customer.street_name
        this.card.customer.suburb = customer.suburb
        this.card.customer.state = customer.state
        this.card.customer.postcode = customer.postcode
        this.card.customer.country = customer.country
        this.card.customer.mobile = customer.mobile
        this.card.customer.email = customer.email
        this.card.customer.customer_type = customer.customer_type
        this.card.customer.abn = customer.abn
        this.selectedCustomerVehicles = customer.vehicles

      }
      this.checkEmail()
    },
    nameOfVehicleMake({key, value}) {
      if (key) {
        return key;
      }
      return '';
    },
    onClickNumber(v) {
      window.open('tel:' + v);
    },
    goToCard() {
      this.$router.push({name: 'CardsEdit', params: {card_id: this.$route.params.card_id, tab: this.$route.params.tab}})
    },
    clickCancel() {
      this.$router.push({name: 'CardsEdit', params: {card_id: this.$route.params.card_id, tab: this.$route.params.tab}})
      return;
    },
    loadCard() {
      NProgress.start();
      let self = this;
      Axios.get(`/fe/card/${this.$route.params.card_id}`)
          .then(response => {
            self.card = response.data.card
            self.properties = response.data.properties
            self.insurers = response.data.insurers
            self.card.arrivedDate = response.data.arrivedDate
            self.card.booking_date = response.data.booking_date
            self.card.completion_date = response.data.completion_date
            self.card.aprxProdDays = response.data.aprxProdDays
            if (response.data.card.customer.customer_id == 0) {
              self.isNewCustomerInfo = true
            }
            this.checkEmail2()
            NProgress.done();
          })
          .catch(error => {
            toastr.error("Error occurred while loading file info");
            console.log(error);
            NProgress.done();
          });
    },

  },
  computed: {
    ...mapGetters({
      getterBusinessName : 'getBusinessName',
      getterAllCustomers: 'getAllCustomers',
      isStatusActive:'isStatusActive',

      'isTechnicalUser': 'isTechnicalUser',
      'isCustomerUser': 'isCustomerUser',
      'isEstimatorUser': 'isEstimatorUser',
      'isProductionManagerUser': 'isProductionManagerUser',
      'isPDRTechnicianUser': 'isPDRTechnicianUser',
      'isShopManagerUser': 'isShopManagerUser',
      'isPanelTechnicianUser': 'isPanelTechnicianUser',
      'isPaintTechnicianUser': 'isPaintTechnicianUser',
      'isStripperFitterUser': 'isStripperFitterUser',
      'isDetailerUser': 'isDetailerUser',

      isFreePlan: 'isFreePlan',
    }),
    isDisabledNextBtn() {
      this.callComputed
      return this.$refs.cardOptionsCheckIn?.currentStep == 1 && !this.card.arrivedDate
    },
    computedExcessToNil: {
      get () {
        if (this.card.insurance.excess === null || this.card.insurance.excess === '') {
          this.excessPlaceholder = `$0.00`
          this.card.insurance.excess = null
        }
        return !!(this.card.insurance.excess === null || this.card.insurance.excess == '')
      },
      set (v) {
        if (v === true) {
          this.excessPlaceholder = `$0.00`
          this.card.insurance.excess = null
          return false
        } else {
          this.excessPlaceholder = ''
          this.card.insurance.excess = 0
          return true
        }
      },
    },
    computedInsurers () {
      let insurers = this.insurers
      insurers = _.forEach(insurers, function (i) {
        if (i.insurer_name) {
          i.insurer_name = i.insurer_name.trim()
        }
        return i
      })
      insurers = _.sortBy(insurers, [function (insurer) {
        if (insurer.insurer_name) {
          return insurer.insurer_name.trim().toLowerCase()
        }
      }])

      insurers.push({ 'insurer_name': null, id: null })
      return insurers
    },
    computedInsuranceClaimType: {
      get: function () {
        if (!this.isInsuranceRepairYes) {
          return ''
        }
        return this.card.insurance.insurance_claim_type
      },
      set: function (v) {
        this.card.insurance.insurance_claim_type = v
      }
    },
    isInsuranceRepairYes: function () {
      if (this.card.insurance_repair == 'true') {
        return true
      }
      return false
    },
    computedBodies: function () {
      try {
        if (this.card.vehicle.series_shedule_number != '' && this.card.vehicle.make != '') {
          let self = this;
          let arr = _.find(this.$store.state.vehicleScheduleNumbers[this.card.vehicle.make],
              {
                sn: self.card.vehicle.series_shedule_number,
              }
          );
          if (arr) {
            let series = _.toUpper(arr.series);
            let model = _.toUpper(arr.model);
            let bodies = self.vehicleMakeModels[self.card.vehicle.make]['data'][model]['data'][series]['data'].body;
            if (bodies.length == 1) {
              this.card.vehicle.body = bodies[0];
            }
            return bodies;
          }
        }
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    isEstimateMethodologyCustom: function () {
      if (this.card.estimate_methodology !== undefined && this.card.estimate_methodology !== null) {
        if (this.card.estimate_methodology === 'custom') {
          return true;
        } else {
          return false;
        }
      }
      if (this.card.insurance !== undefined && this.card.insurance !== null && this.card.insurance.rates !== undefined && this.card.insurance.rates !== null) {
        if (this.card.insurance.rates.estimate_methodology === 'custom') {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isNoNeedVehicleRego: function () {
      if (this.isInsuranceRepairOther) {
        return true;
      }
      return false;
    },
    isInsuranceRepairOther: function () {
      if (this.card.insurance_repair == 'other' || this.card.insurance_repair == '2') {
        return true;
      }
      return false;
    },
    isNewVehicleInfo: function () {
      if (this.card.vehicle.customer_vehicle_id == 0 || this.card.vehicle.customer_vehicle_id == null) {
        return true;
      }
      return false;
    },
    vehicleModels: {
      get: function () {
        try {
          var _result = [];
          if (!_.isEmpty(this.vehicleMakeModels[this.card.vehicle.make]) && !_.isEmpty(this.vehicleMakeModels[this.card.vehicle.make]['data'])) {
            _.forEach(this.vehicleMakeModels[this.card.vehicle.make]['data'], function (value, key) {
              if (!_.isEmpty(value['data'])) {
                _.forEach(value['data'], function (value2) {
                  if (value['t'] == 2) {
                    _result.push({key: key, value: value2['data'].n, sn: 0, t: 2});
                  } else {
                    _result.push({
                      key: key,
                      value: value2['data'].n.trim(),
                      sn: value2['data'].sn,
                      t: 1
                    });
                  }
                });
              } else {

                _result.push({key: key, value: '', sn: 0, t: 2});

              }
            });
          }
          return _.sortBy(_result, ['key']);
        } catch (erro) {
          console.log('Error', erro);
          return [];
        }
      },
      cache: false,
    },
    customerVehicleModel: {
      get: function () {
        if (this.card.vehicle.series_shedule_number === 0) {
          return {key: this.card.vehicle.model, value: this.card.vehicle.series, sn: this.card.vehicle.series_shedule_number, t: 2};
        } else {
          return {key: this.card.vehicle.model, value: this.card.vehicle.series, sn: this.card.vehicle.series_shedule_number, t: 1};
        }
      },
      set: function (item) {
        this.card.vehicle.model = item.key;
      },
    },
    vehicleMakeModels: {
      get: function () {
        return this.$store.state.vehicleMakeModels;
      },
      set: function (obj) {
      },
    },
    vehicleMakes: function () {
      try {
        var _result = [];
        _.forEach(this.vehicleMakeModels, function (value, key) {
          if (value['t'] == 2) {
            _result.push({key: key, t: 2});
          } else {
            _result.push({key: key, t: 1});
          }
        });
        return _.sortBy(_result, ['key']);
      } catch (erro) {
        console.log(erro);
        return [];
      }
    },
    customerVehicleMake: {
      get: function () {
        if (this.card.vehicle.series_shedule_number === 0) {
          return {key: this.card.vehicle.make, t: 2};
        } else {
          return {key: this.card.vehicle.make, t: 1};
        }
      },
      set: function (item) {
        if (item && item.key) {
          this.card.vehicle.make = item.key;
        } else {
          this.card.vehicle.make = item;
        }
      },
    },
    isActiveLookupAlwaysOn() {
      return !!(this.properties && this.properties.isRegoAlwaysLookup);
    },
    isActiveLookup() {
      return !!(this.properties && !this.isActiveLookupAlwaysOn && this.properties.isRegoLookup);
    },
    isActiveLookupWithCompliance() {
      return !!(this.properties && !this.isActiveLookupAlwaysOn && this.properties.isRegoLookupWithCompliance);
    },
    stateForLookUp() {
      if (this.card.customer.customer_type === 'I') {
        return this.card.customer.state;
      }
      return '';
    },
    selectedCustomerVehicles2: function () {
      try {
        let index = _.findIndex(this.getterAllCustomers, ['customer_id', this.card.customer.customer_id]);
        let vehicles = this.getterAllCustomers[index].vehicles;
        return vehicles;
      } catch (erro) {
        return [];
      }
    },
    showUpperRegoNumber: {
      get: function () {
        return _.toUpper(this.card.vehicle.rego_number);
      },
      set: function (rego) {
        rego = _.trim(rego);
        this.card.vehicle.rego_number = _.toUpper(rego);
      }
    },
    isDisabledFieldsByUserRole() {
      return  this.isPanelTechnicianUser || this.isPaintTechnicianUser || this.isStripperFitterUser || this.isDetailerUser || this.isTechnicalUser || this.isPDRTechnicianUser
    },
    customerVehicles: function () {
      let customersVehicles = [];
      _.forEach(this.getterAllCustomers, function (customer) {
        customersVehicles.push({
          customer_name: customer.customer_name,
          customer_id: customer.customer_id,
        });
      });
      return customersVehicles;
      ;
    },
  },
  mounted: function () {
    this.loadCard();
  },
  components: {
    VStepper,
    GoodWizard,
    advancedSearchWithNew,
    AdvancedSearch,
    Multiselect,
    DatePicker,
    NumberFormatter,
    AdvancedSearchForLookup
  }
};

</script>

<template>
  <div class="card-options-check-in">
    <div class="page-header">
      <h4>Check In Customer - {{card.card_number}} <span v-if="card.customer && card.customer.name">| {{card.customer.name }} </span><span v-if="card.vehicle && card.vehicle.rego_number" style="text-transform: uppercase">| {{card.vehicle.rego_number}} </span></h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="'/'">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/files'">View Files</router-link>
        </li>
        <li class="breadcrumb-item">
          <a href="" @click.prevent="goToCard">Edit File</a>
        </li>
        <li class="breadcrumb-item">Options</li>
        <li class="breadcrumb-item active">Check In Customer</li>
      </ol>
      <h4 class="header-display-name">{{ getterBusinessName }}</h4>
    </div>
    <div>

      <good-wizard
          :steps="steps"
          :currentStep="step"
          ref="cardOptionsCheckIn"
      >
        <div slot="card-options-check-in-step-1" class="card-options-check-in-step-1">
          <div class="wizard-header">
            <div class="wizard-header-name">
              Confirm Customer and Vehicle details to Check-In
            </div>
          </div>
          <div class="d-flex">
            <div class="" style="width: 47%">
              <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Vehicle Owner Name:</label>
                <div class="col-sm-7 col-7">
                  <advanced-search-with-new ref="customer.name"
                                            :searchLabel="'Customer'"
                                            v-model="card.customer.name"
                                            :searchData="customerVehicles"
                                            :searchKey="'customer_name'"
                                            @onSelect="onCustomerSelect"
                                            @onUpdate="onCustomerUpdateName"
                                            :placeholderText="'Customer Name'"
                                            :is-disabled="!isStatusActive || isDisabledFieldsByUserRole"
                                            :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}"
                  >
                  </advanced-search-with-new>
                </div>
              </div>

              <div class="form-group row" v-if="!isTechnicalUser">
                <label for="mobile" class="col-sm-5 col-5 col-form-label">Contact Number 1</label>
                <div class="col-sm-7 col-7">
                  <input type="text" v-model="card.contactNumber1" class="form-control"
                         placeholder="Contact Number 1"
                         style="padding-right: 30px"
                         @change="autoUpdateContacts"
                         :readonly="!isStatusActive"
                         :disabled="isDisabledFieldsByUserRole">
                  <i v-if="card.contactNumber1" @click="onClickNumber(card.contactNumber1)" class='bx bxs-phone-call'></i>
                </div>
              </div>
              <div class="form-group row" v-if="!isTechnicalUser">
                <label for="mobile" class="col-sm-5 col-5 col-form-label">Contact Number 2</label>
                <div class="col-sm-7 col-7">
                  <input type="text" v-model="card.contactNumber2"
                         class="form-control"
                         placeholder="Contact Number 2"
                         style="padding-right: 30px"
                         @change="autoUpdateContacts"
                         :readonly="!isStatusActive"
                         :disabled="isDisabledFieldsByUserRole">
                  <i v-if="card.contactNumber2" @click="onClickNumber(card.contactNumber2)" class='bx bxs-phone-call'></i>
                </div>
              </div>

              <div class="form-group row" v-if="!isTechnicalUser">
                <label class="col-sm-5 col-5 col-form-label">Email Address</label>
                <div class="col-sm-7 col-7">
                  <input type="text" v-model="card.contactEmail" class="form-control"
                         placeholder="Email Address"
                         @change="changedEmailAddress"
                         @blur="checkEmail2"
                         @input="checkEmail"
                         :readonly="!isStatusActive"
                         :disabled="isDisabledFieldsByUserRole">
                  <small class="form-text show-help-for-body"
                         v-if="!isCheckEmail">
                    Please enter a valid email address
                  </small>
                </div>
              </div>

            </div>
            <div class="" style="width: 53%">
              <div class="form-group row">
                <label class="col-sm-2 col-2 col-form-label">Rego:</label>
                <div class="col-sm-10 col-10">
                  <advanced-search-for-lookup ref="rego_number"
                                              v-model="showUpperRegoNumber"
                                              :searchData="selectedCustomerVehicles2"
                                              :searchKey="'search'"
                                              :searchLabel="'Rego Number'"
                                              placeholder="Rego number"
                                              :isNewCard="false"
                                              :isCardEdit="true"
                                              :isEstimateEdit="false"
                                              id="vehicle.rego_number"
                                              :isDisabled="!isStatusActive || isDisabledFieldsByUserRole"
                                              :isActiveLookup="isActiveLookup"
                                              :isActiveLookupAlwaysOn="isActiveLookupAlwaysOn"
                                              :isActiveLookupWithCompliance="isActiveLookupWithCompliance"
                                              :state="stateForLookUp"
                                              @onSelect="onCustomerVehicleSelect"
                                              :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}">
                  </advanced-search-for-lookup>
                </div>
              </div>

              <div class="form-group row">
                <label for="make" class="col-sm-2 col-2 col-form-label">Make</label>
                <div class="col-sm-10 col-10" ref="multi1">
                  <multiselect
                      ref="multimake"
                      id="multimake"
                      v-model="customerVehicleMake"
                      :options="vehicleMakes"
                      :custom-label="nameOfVehicleMake"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      placeholder="Select Make"
                      @select="onCustomerVehicleMakeSelect"
                      @search-change="onCustomerVehicleMakeNew"
                      style="opacity: 1;"
                      :disabled="(!card.vehicle.customer_vehicle_id) || !isStatusActive || isDisabledFieldsByUserRole"
                  >
                    <template slot="noResult" slot-scope="props">
                      <div style="padding:10px; text-align: left; white-space: normal;">
                        <p>You are about to create an unscheduled vehicle.</p>
                        <p>Do you want continue?</p>
                        <button class="btn btn-sm btn-primary clickable" style="padding:5px 10px; margin:0 10px 0 0;" @click="addNewCustomNtarLtarVehicleMake">
                          Yes
                        </button>
                        <button class="btn btn-sm clickable" style="padding:5px 10px;" @click="noNewCustomNtarLtarVehicleMake">No
                        </button>
                      </div>
                    </template>
                    <template slot="singleLabel" slot-scope="props">
                      {{ props.option.key }}
                      <span style="text-align: right; float:right; font-size:0.85rem;" v-if="isNoScheduleNumberForMake(props.option.key)"><span v-if="props.option.key">&nbsp- </span>No schedule number</span>
                    </template>
                    <template slot="option" slot-scope="props">
                      {{props.option.key}}
                      <span style="text-align: right; float:right; font-size:0.85rem;" v-if="props.option.t == 2">no schedule number</span>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label for="model" class="col-sm-2 col-2 col-form-label">Model</label>
                <div class="col-sm-10 col-10 check-in-model-context">
                  <multiselect
                      ref="multimodel"
                      id="multimodel"
                      v-model="customerVehicleModel"
                      :options="vehicleModels"
                      :custom-label="nameOfVehicleModel"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      placeholder="Select Model"
                      @select="onCustomerVehicleModelSelect"
                      @search-change="onCustomerVehicleModelNew"
                      :disabled="(!card.vehicle.customer_vehicle_id) || !isStatusActive || isDisabledFieldsByUserRole"
                      style="opacity: 1"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      {{ props.option.key }}
                      <span style="text-align: right; float:right; font-size:0.85rem;" v-if="isNoScheduleNumberForModel(props.option.key)"><span v-if="props.option.key">&nbsp- </span>No schedule number</span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <span v-if="props.option.value != ''">{{ props.option.key}} - {{ props.option.value}}</span>
                      <span v-else>{{ props.option.key}}</span>
                      <span style="text-align: right; float:right; font-size:0.85rem;" v-if="props.option.t == 2">no schedule number</span>
                    </template>
                    <template slot="noResult" slot-scope="props">
                      <div style="padding:10px; text-align: left;  white-space: normal;">
                        <p>You are about to create an unscheduled vehicle.</p>
                        <p>Do you want continue?</p>
                        <button class="btn btn-sm btn-primary clickable" style="padding:5px 10px;  margin:0 10px 0 0;" @click="addNewCustomNtarLtarVehicleModel">
                          Yes
                        </button>
                        <button class="btn btn-sm clickable" style="padding:5px 10px;" @click="noNewCustomNtarLtarVehicleModel">
                          No
                        </button>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="card-options-check-in-step-2" class="card-options-check-in-step-2 rs-scroll rs-scroll--y">
          <div class="header">
            Review Booking
          </div>
          <div class="d-flex">
            <div class="" style="width: 50%">

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Date Booked (Start)</label>
                <div class="col-sm-8 col-8">
                  <date-picker v-model="card.booking_date"
                               id="date_in"
                               :is-disabled="!isStatusActive || isDisabledFieldsByUserRole"
                               :placeHolderText="'Date Booked (Start)'">
                  </date-picker>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Date Completed</label>
                <div class="col-sm-8 col-8">
                  <date-picker v-model="card.completion_date"
                               :is-disabled="!isStatusActive || isDisabledFieldsByUserRole"
                               id="date_out"
                               :placeHolderText="'Date Completed'">
                  </date-picker>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Date Arrived<span class="indicate" style="color: #FF5E5E">*</span></label>
                <div class="col-sm-8 col-8">
                  <date-picker :is-disabled="!isStatusActive || isDisabledFieldsByUserRole"
                               v-model="card.arrivedDate"
                               @onChange="callComputed++"
                               :placeHolderText="'Date Arrived'">
                  </date-picker>
                </div>
              </div>

              <div class="form-group row" v-if="!isTechnicalUser">
                <label class="col-sm-4 col-4 col-form-label">Email Address</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="card.contactEmail" class="form-control"
                         placeholder="Email Address"
                         @change="changedEmailAddress"
                         @blur="checkEmail2"
                         @input="checkEmail"
                         :readonly="!isStatusActive"
                         :disabled="isDisabledFieldsByUserRole">
                  <small class="form-text show-help-for-body"
                         v-if="!isCheckEmail">
                    Please enter a valid email address
                  </small>
                </div>
              </div>

            </div>
            <div class="" style="width: 50%">
              <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Approx Production Days</label>
                <div class="col-sm-7 col-7">
                  <input
                      :value="card.aprxProdDays ? card.aprxProdDays + ' Days': '0 Days'"
                      :disabled="true"
                      class="form-control"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="card-options-check-in-step-3" class="card-options-check-in-step-3">
          <div class="header">
            Review Booking
          </div>
          <div class="box box-block">
            <div class="form-group row">
              <label class="col-sm-4 col-4 col-form-label">Insurance Repair Job</label>
              <div class="col-sm-8 col-8 d-flex insurer-block-mobile">
                <div class="checkbox-p">
                  <label for="insurance_repair_y" class="form-check-inline radio">
                    <input class="form-check-input" v-model="card.insurance_repair" type="radio" name="insurance_repair"
                           id="insurance_repair_y"
                           value="true"
                           :disabled=" !isStatusActive || isDisabledFieldsByUserRole">
                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                  </label>
                </div>
                <div class="checkbox-p">
                  <label for="insurance_repair_n" class="form-check-inline radio">
                    <input class="form-check-input" v-model="card.insurance_repair" type="radio" name="insurance_repair"
                           id="insurance_repair_n"
                           value="false" hidden
                           :disabled="!isStatusActive || isDisabledFieldsByUserRole">
                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                  </label>
                </div>
                <div class="checkbox-p">
                  <label for="insurance_repair_o" class="form-check-inline radio insurance-repair-other checkbox-p">
                    <input class="form-check-input" v-model="card.insurance_repair" type="radio" name="insurance_repair"
                           id="insurance_repair_o"
                           value="2" hidden
                           :disabled="!isStatusActive || isDisabledFieldsByUserRole">
                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">Other</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group row" v-if="!isTechnicalUser">
              <label for="mobile" class="col-sm-4 col-4 col-form-label">Insurance Claim Type</label>
              <div class="col-sm-8 col-8 d-flex insurer-block-mobile">
                <div class="checkbox-p">
                  <label class="form-check-inline radio">
                    <input class="form-check-input" v-model="computedInsuranceClaimType" type="radio"
                           name="insurance_claim_type"
                           id="insurance_claim_type_insured" value="insured"
                           :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                          >
                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">Insured</span>
                  </label>
                </div>
                <div class="checkbox-p">
                  <label class="form-check-inline radio">
                    <input class="form-check-input" v-model="computedInsuranceClaimType" type="radio"
                           name="insurance_claim_type"
                           id="insurance_claim_type_other_party" value="other_party"
                           :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                           >
                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">Other Party</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="insurance_name" class="col-sm-4 col-4 col-form-label">Insurer Name</label>
              <div class="col-sm-8 col-8 insurer-block-mobile">
                <!--<input type="text" v-model="card.insurance.name" class="form-control" id="insurance_name" placeholder="Insurance company name">-->
                <advanced-search ref="test"
                                 v-model="card.insurance.insurer_name"
                                 :searchData="computedInsurers"
                                 :searchKey="'insurer_name'"
                                 @onSelect="onInsurerSelect"
                                 :searchLabel="'Insurer'"
                                 placeholder="Insurer Name"
                                 :isDisabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                                 :isCanBeEmpty="true"
                                 :sortByName="'insurer_name'"
                                 :maxRecords="computedInsurers.length"
                                 :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}"
                />
              </div>
            </div>
            <div class="form-group row" v-if="!isTechnicalUser">
              <label for="claim_number" class="col-sm-4 col-4 col-form-label">Claim No</label>
              <div class="col-sm-8 col-8 insurer-block-mobile">
                <input type="text" v-model="card.insurance.claim_number" class="form-control" id="claim_number"
                       :readonly="!isStatusActive"
                       placeholder="Insurance claim number" :disabled="!isInsuranceRepairYes || isDisabledFieldsByUserRole">
              </div>
            </div>

            <div class="form-group row" v-if="!isTechnicalUser">
              <label for="excess" class="col-sm-4 col-4 col-form-label">Excess</label>
              <div class="col-sm-8 col-8 insurer-block-mobile">
                <div :class="{'rs-input-group':true}">
                  <number-formatter ref="card.insurance.excess"
                                    v-model="card.insurance.excess"
                                    type="text"
                                    class="form-control"
                                    :placeholder="card.insurance.excess === null ? excessPlaceholder : 'Customer Excess' "
                                    format="$0,0.00"
                                    :isTotalManual="true"
                                    :disabled="!isInsuranceRepairYes || isTechnicalUser || !isStatusActive
                                            || (card.insurance.excess === null) || isDisabledFieldsByUserRole"
                                    />
                </div>
              </div>
            </div>

            <div class="form-group row" v-if="!isTechnicalUser">
              <label for="contribution" class="col-sm-4 col-4 col-form-label">Contribution</label>
              <div class="col-sm-8 col-8 insurer-block-mobile">
                <number-formatter ref="card.insurance.contribution" v-model="card.insurance.contribution" type="text"
                                  class="form-control width50" id="contribution"
                                  placeholder="Customer Contribution"
                                  format="$0,0.00"
                                  :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                                  ></number-formatter>
              </div>
            </div>

            <div class="form-group row" v-if="!isTechnicalUser">
              <label for="discount" class="col-sm-4 col-4 col-form-label">Discount</label>
              <div class="col-sm-8 col-8 insurer-block-mobile">
                <number-formatter ref="discount" v-model="card.insurance.discount" type="text" class="form-control width50"
                                  :class="{'o50':!isInsuranceRepairYes}" id="discount" maxlength="3"
                                  placeholder="Customer discount" format="0%"
                                  :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                                  :percentage="true"
                                  ></number-formatter>
              </div>
            </div>

            <div v-if="!isTechnicalUser" class="form-group row">
              <label class="col-sm-4 col-4 col-form-label">Notes</label>
              <div class="col-sm-8 col-8 insurer-block-mobile">
                          <textarea
                              v-model="card.notes"
                              :disabled="isDisabledFieldsByUserRole"
                              style="height: 150px !important;" class="form-control"
                              name="" cols="30" rows="10">
                          </textarea>
              </div>
            </div>


            <div v-if="!isTechnicalUser" class="form-group row">
              <label class="col-sm-4 col-4 col-form-label">Print Job File:</label>
              <div class="col-sm-8 col-8 insurer-block-mobile">
                <div class="checkbox-p">
                <label class="form-check-inline checkbox d-flex">
                  <input class="form-check-input" type="checkbox" v-model="isPrintJobCard"
                         :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                  >
                  <span class="icon"><i class='bx bx-check'></i></span><span class="text"></span>
                </label>
              </div>
              </div>
            </div>

            <div v-if="!isTechnicalUser && !isFreePlan" class="form-group row">
              <label class="col-sm-4 col-4 col-form-label">Print Key Label:</label>
              <div class="col-sm-8 col-8 insurer-block-mobile">
                <div class="checkbox-p">
                  <label class="form-check-inline checkbox d-flex">
                    <input class="form-check-input" type="checkbox" v-model="isPrintKeyTicket"
                           :disabled="!isStatusActive"
                    >
                    <span class="icon"><i class='bx bx-check' ></i></span><span class="text"></span>
                  </label>
                </div>
              </div>
            </div>

          </div>
        </div>
      </good-wizard>

      <div class="wizard__buttons">
        <div class=" pull-right">
          <button
              v-if="$refs.cardOptionsCheckIn"
              class="btn btn-outline-primary pull-left button-cancel"
              type="button"
              @click="clickCancel"
          >
            Cancel
          </button>
          <button
              v-if="$refs.cardOptionsCheckIn && $refs.cardOptionsCheckIn.currentStep != 0"
              class="btn button-back ml-0"
              type="button"
              @click="$refs.cardOptionsCheckIn.goBack()"
          >
            Back
          </button>
          <a
              v-if="$refs.cardOptionsCheckIn && $refs.cardOptionsCheckIn.currentStep != 2"
              class="btn btn-primary pull-right button-next"
              type="button"
              :class="{'disabled-next-btn': isDisabledNextBtn}"
              @click="(isDisabledNextBtn) || $refs.cardOptionsCheckIn.goNext()"
              :tabindex="4"
          >
            Next
          </a>
          <button
              v-if="$refs.cardOptionsCheckIn && $refs.cardOptionsCheckIn.currentStep == 2"
              class="btn btn-primary pull-right button-card-estimate"
              type="button"
              style=""
              @click="saveCardUpdates"
          >
            Check-In
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.V3 .card-options-check-in .disabled-cards-edit-is-user .fa-angle-down {
  display: none !important;
}

.V3 .new-card {
  max-width: 1028px;
  margin: 0 auto;
}

.V3 .card-options-check-in .wizard__body {
  padding: 15px;
  width: 100%;
  margin: 0 auto;
}

.V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 {
  width: 100%;
  margin: 0 auto;
}
.V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 table.customer-table .customer-sms-checkbox,
.V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 table.customer-table .customer-email-checkbox {
  width: 25px;
  padding-left: 15px;
}

.V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 table.customer-table .customer-sms-checkbox label.checkbox > span.icon,
.V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 table.customer-table .customer-email-checkbox label.checkbox > span.icon {
  margin-right: 0px;

}

.V3 .card-options-check-in .wizard__body .card-options-check-in-step-2 {
  width: 100%;
  padding: 0 5px;
  overflow: auto;
}

.V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 .table th {
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
}

.V3 .card-options-check-in .wizard__body .card-options-check-in-step-2 .table th {
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  vertical-align: top;
}

.V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 .table td,
.V3 .card-options-check-in .wizard__body .card-options-check-in-step-2 .table td {
  color: #1C1F39;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}

.V3 .card-options-check-in .wizard__body .card-options-check-in-step-3 {
  width: 100%;
}

.V3 .card-options-check-in .wizard__buttons {
  width: 60%;
  margin: 0 auto;
  padding-right: 15px;
}

@media screen and (max-width: 991px) {
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 {
    width: 100%;
  }

  .V3 .card-options-check-in .wizard__buttons {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 {
    width: 100%;
  }
  .V3 .wizard-header .wizard-header-name {
    margin-right: 150px;
  }
  .V3 .wizard-header .wizard-subheader-name-text {
    margin-right: 150px;
  }
  .V3 .card-options-check-in .wizard__buttons {
    width: 100% !important;
  }
}

@media screen and (max-width: 460px) {
  .V3 .page-header {
    padding: 5px 0 5px 15px !important;
  }
}
</style>
<style scoped>
.wizard__buttons .pull-right .button-cancel:hover{
  background-color: rgba(28, 31, 57, 0.62) !important;
  color: white;
}
.wizard__buttons .pull-right .button-back{
  color: #5E79FF !important;
}
.V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 .wizard-header-name{
  margin-right: 0;
  font-weight: 800;
}
.V3 .card-options-check-in .wizard__body .card-options-check-in-step-2 {
  margin: 0 auto;
  overflow: inherit;
}
.V3 .card-options-check-in .wizard__body .card-options-check-in-step-3 {
  margin: 0 auto;
}
.disabled-next-btn {
  cursor: not-allowed !important;
  opacity: .65 !important;
}
.bxs-phone-call{
  position: absolute;
  top: 5px;
  right: 25px;
  font-size: 22px;
  color: #1d4898;
  cursor: pointer;
}
.V3 .card-new-body >>> .card-block {
  padding: 0px;
}

.V3 .card-new-body .card-block {
  padding: 0px;
}

.V3 .wizard__body {
  padding: 15px 30px 0px 30px;
}


.V3 .wizard__buttons .pull-left {
  float: left !important;
  margin-right: 10px;
}

.V3 .wizard__buttons .pull-right {
  float: right !important;
  margin-left: 10px;
}

.V3 .wizard__buttons .btn {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  height: 38px;
}

.V3 .wizard__buttons .button-cancel {
  padding: 10px 21px;
  background: #FFFFFF;
  border: 1px solid rgba(28, 31, 57, 0.25);
  color: #1C1F39;
}

.V3 .wizard__buttons .button-card {
  padding: 10px 15px;
  background: #FFFFFF;
  color: #5E79FF;
}

.V3 .wizard__buttons .button-card-estimate {
  padding: 10px 15px;
}

.V3 .wizard__buttons .button-next {
  padding: 10px 15px;
  width: 83px;
}

.V3 .wizard__buttons .button-back {
  padding: 10px 26px;
  background: #FFFFFF;
  border-color: #5E79FF;
  width: 83px;
}

.disabled-checkbox-sms {
  cursor: not-allowed;
  background-color: rgba(27, 30, 56, 0.12) !important;
  border: 1px solid rgba(27, 30, 56, 0.12) !important;
}

@media screen and (max-width: 500px) {
  .V3 .page-header {
    min-height: 100px !important;
  }
  .page-header h4 {
    height: auto;
  }
}
@media screen and (max-width: 1350px) {
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 .check-in-model-context >>> .multiselect__content-wrapper{
    width: 200px;
  }
}
@media screen and (min-width: 850px) {
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-3 {
    width: 68%;
  }
}
@media screen and (min-width: 1150px) {
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 {
    width: 75%;
  }
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-2 {
    width: 80%;
  }
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-3 {
    width: 50%;
  }
}
@media screen and (min-width: 1500px) {
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 {
    width: 65%;
  }
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-2 {
    width: 60%;
  }
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-3 {
    width: 40%;
  }
}
@media screen and (min-width: 1920px) {
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-1 {
    width: 50%;
  }
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-2 {
    width: 50%;
    margin: 0 auto;
    overflow: inherit;
  }
  .V3 .card-options-check-in .wizard__body .card-options-check-in-step-3 {
    width: 30%;
    margin: 0 auto;
  }
}
</style>

