<template>
    <div class="vue-form-wizard xs">
        <!-- Header -->
        <div class="wizard-header mb-0">
            <div class="text-left mb-0 progress-title">
                <span class="font-weight-bold">{{ title }}</span>
                <a v-if="isShowFullActivity" data-toggle="tab" class="float-right clickable" @click.prevent="onFullClicked">
                    Full Activity
                </a>
            </div>
        </div>

        <!-- Navigation -->
        <div class="wizard-navigation">
            <div class="wizard-progress-with-circle">
                <div class="wizard-progress-bar" :style="progress"></div>
            </div>

            <ul role="tablist" class="wizard-nav wizard-nav-pills">
                <li
                        v-for="(status, index) in filteredStatusList"
                        :class="{ active: status.active }"
                        :key="status.id"
                >
                    <div
                            v-if="status.current || !status.active"
                            class="wizard-progress-bar-not-active"
                    ></div>
                    <a
                            v-if="status.active"
                            @click="onChangeCardDetailProgress(status.id, index)"
                            class="status-link"
                    >
                        <div
                                role="tab"
                                tabindex="0"
                                aria-controls="File Open"
                                class="wizard-icon-circle md checked"
                                aria-disabled="true"
                                aria-selected="true"
                        >
                            <div
                                    class="wizard-icon-container"
                                    v-if="status.current"
                            >
                                <i class="wizard-icon ti-check"></i>
                            </div>
                        </div>

                        <span class="status-title active">{{
                            status.title
                        }}</span>
                    </a>
                    <a
                            v-else
                            @click="onChangeCardDetailProgress(status.id, index)"
                            class="status-link"
                    >
                        <div
                                role="tab"
                                tabindex=""
                                class="wizard-icon-circle md"
                        >
                            <i class="wizard-icon"></i>
                        </div>
                        <span class="status-title">{{ status.title }}</span>
                    </a>
                </li>
            </ul>
        </div>

        <!-- Footer -->
        <div class="wizard-card-footer clearfix">
            <span></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'small-form-wizard',
        props: {
            title: {
                type: String,
                default: 'Progress'
            },
            statuses: {
                type: Array,
                default: () => {
                    return [
                        {
                            active: true,
                            current: false,
                            icon: 'ti-check',
                            id: '-1',
                            title: '--'// 'Parts Pricing Requested'
                        },
                        {
                            active: true,
                            current: true,
                            icon: 'ti-check',
                            id: '-2',
                            title: '--'// 'Estimate Sent'
                        },
                        {
                            active: false,
                            current: false,
                            icon: '',
                            id: '-3',
                            title: '--'// 'Estimate Approved'
                        },
                        {
                            active: false,
                            current: false,
                            icon: '',
                            id: '-4',
                            title: '--'// 'Booked In [Test]'
                        }
                    ];
                }
            },
            currentStatusId: {
                type: String | Object
            },
            progress: {
                type: String,
                default: 'width: 0'
            },
            isShowFullActivity: {
                type: Boolean,
                default: true,
            }
        },
        computed: {
            currentIndex() {
                return this.statuses.findIndex(
                    (status) => status.id === this.currentStatusId
                );
            },
            currentStatus() {
                return this.statuses[this.currentIndex];
            },
            mappedStatusList() {
                return this.statuses.map((status, index) => {
                    const current = index === this.currentIndex;
                    return {
                        active: status.active,
                        current,
                        icon: status.icon,
                        id: status.id,
                        title: status.title
                    };
                });
            },
            // basically to always show 4 status
            filteredStatusList() {
                if (this.mappedStatusList.length <= 4) return this.statuses;

                const statusCount = this.mappedStatusList.length;
                const index = this.currentIndex;

                if (index < 3) {
                    // on minimal
                    return this.mappedStatusList.slice(0, 4);
                } else if ((statusCount - index + 1) < 5) {
                    // on maximum
                    return this.mappedStatusList.slice(-4);

                    // everything in between
                } else {
                  return this.mappedStatusList?.slice((index - 1) % 2 == 0 ? index - 1 : index - 2, (index + 2) % 2 == 0 ? index + 2 : index + 3);
                }
            }
        },
        methods: {
            onChangeCardDetailProgress(id, index) {
                this.$emit('change', id, index);
            },
            onFullClicked() {
                console.log('onFullClicked');
                this.$emit('full-clicked');
            }
        }
    };
</script>

<style scoped>
    .progress-title span {
        font-size: 18px;
    }

    .progress-title a {
        margin-top: 6px;
    }

    .wizard-bottom .progress-title span {
        font-size: 18px;
    }

    .wizard-bottom .progress-title a {
        margin-top: 6px;
    }

    .wizard-bottom .wizard-nav-pills li {
        cursor: pointer;
        max-width: initial !important;
    }

    .wizard-bottom .wizard-progress-bar {
        background-color: #5E79FF !important;
        color: #5E79FF !important;
        border-radius: 10px 0 0 10px;
        left: 40px;
        display: block;
        position: relative;
    }

    .wizard-bottom .wizard-progress-bar-not-active {
        width: 100%;
        height: 10px;
        position: absolute;
        left: 50px;
        top: 9px;
    }

    .wizard-bottom .wizard-navigation {
        overflow: hidden !important;
        min-width: 320px !important;
        max-width: 481px !important;
    }


    .vue-form-wizard.xs {
        overflow: hidden !important;
        margin-bottom: 1.5rem;
    }

    .vue-form-wizard.xs {
        padding-bottom: 0px !important;
        overflow: auto;
    }

    .wizard-navigation {
        overflow-x: auto;
    }

    .wizard-navigation {
        overflow: hidden !important;
        min-width: 320px !important;
        max-width: 481px !important;
    }

    .vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
        height: 10px !important;
        top: 19px !important;

        /* prevent progress out  */
        width: 88%;
        overflow: hidden;
    }

    .wizard-progress-bar {
        background-color: #5E79FF !important;
        color: #5E79FF !important;
        border-radius: 10px 0 0 10px;
        left: 40px;
        display: block;
        position: relative;
    }

    .wizard-progress-bar-not-active {
        width: 100%;
        background-color: rgb(234 235 239);
        height: 10px;
        position: absolute;
        left: 50px;
        top: 9px;
    }

    .wizard-nav {
        overflow: hidden;
    }

    .wizard-nav-pills li {
        cursor: pointer;
        max-width: initial !important;
    }

    .wizard-nav-pills a,
    .wizard-nav-pills li {
        max-width: 89px;
    }

    .vue-form-wizard.xs .wizard-nav-pills > li.active > a .wizard-icon {
        font-size: 12px !important;
        color: rgb(94, 121, 255);
    }

    .wizard-icon-circle {
        width: 30px !important;
        height: 30px !important;
        background: transparent !important;
        border: none !important;
    }

    .vue-form-wizard .wizard-icon-circle .wizard-icon-container {
        border-radius: 50%;
        background-color: white;
        border: 1px solid rgb(94, 121, 255);
        flex: unset;
        width: 24px;
        height: 24px;
    }

    .status-title.active {
        color: rgb(20, 39, 66);
    }

    .status-title {
        font-size: 12px;
        font-weight: 600;
    }

    .status-link:hover .status-title {
        color: rgb(20, 39, 66);
    }

    .wizard-nav-pills li:last-child .wizard-progress-bar-not-active {
        left: 0;
        width: calc(50% + 10px);
        border-radius: 0 10px 10px 0;
    }

    .wizard-nav-pills li.active:last-child .wizard-progress-bar-not-active {
        background-color: transparent;
    }

    .wizard-header {
        padding: 0 15px !important;
    }
</style>
